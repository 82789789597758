import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

interface IMonthPicker {
  value: number;
  onChangeValue: Function;
}

const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const MonthPicker: React.FC<IMonthPicker> = ({
  value,
  onChangeValue,
}: IMonthPicker) => {
  const [selected, setSelected] = useState(value);

  const onChange = (value: number) => {
    setSelected(value);
    onChangeValue(value);
  }

  useEffect(() => {
    onChange(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <div className="w-full">
        <Listbox
          value={selected}
          onChange={onChange}
        >
          <div className="relative mt-1">
            <Listbox.Button
              className="relative w-full py-2 pl-3 pr-10 bg-white rounded-md
                        cursor-pointer text-sm text-center
                        border border-gray-300"
            >
              <span className="block truncate">{selected} 월</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute w-full mt-1 overflow-auto text-base bg-white 
                          rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 
                          sm:text-sm"
              >
                {month.map((data, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }) =>
                      `cursor-pointer select-none relative px-3
                      text-center text-gray-900 
                      `
                    }
                    value={data}
                  >
                    {({ selected, active }) => (
                      <div
                        className={`flex flex-row py-3 my-1 px-2 w-full 
                                    justify-center items-center
                                    ${
                                      selected
                                        ? 'font-semibold bg-yellow-100'
                                        : 'font-normal'
                                    }
                                    ${!selected && active && 'bg-gray-100'}
                                  `}
                      >
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-6`}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                        <span className={`block truncate`}>{data} 월</span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </>
  );
};
