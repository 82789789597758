import React, { useEffect, useState } from 'react';
import { Toast, ToastType } from '../../components/toast';
import { SearchIcon } from '@heroicons/react/outline';
import { useLazyQuery } from '@apollo/client';
import { MonthPicker } from '../../components/date-picker/month-picker';
import { YearPicker } from '../../components/date-picker/year-picker';
import { GET_TEACHER_CLASSLOG } from '../../graphql/classlog';
import {
  GetTeacherClasslog,
  GetTeacherClasslogVariables,
} from '../../graphql/__generated__/GetTeacherClasslog';
import { phoneNumberFormat } from '../../utils/phone-format';

interface IClasslogs {
  id: number;
  classDate: number;
  teacherId: number;
}

interface ITeacherClasslog {
  email: string;
  name: string;
  phoneNumber: string;
  classlogs: IClasslogs[];
}

const TITLE = '선생님 활동';
export const PaymentTeacher: React.FC<{}> = () => {
  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const [teacherClasslog, setTeacherClasslog] = useState<ITeacherClasslog[]>(
    []
  );

  const [getTeacherClasslog, { loading }] = useLazyQuery<
    GetTeacherClasslog,
    GetTeacherClasslogVariables
  >(GET_TEACHER_CLASSLOG, {
    fetchPolicy: 'no-cache',
    onCompleted: ({
      getTeacherClasslog: { error, message, classlogs, teachers },
    }) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? `${TITLE} 조회에 실패했습니다.`
          : `${TITLE} 조회에 성공했습니다.`,
        show: true,
      });

      if (error) {
        console.log('[ERROR] getTeacherClasslog :: ', message);
      }

      const teacherClasslogs: ITeacherClasslog[] = [];
      teachers.forEach(({ id: tId, email, name, phoneNumber }) => {
        const newItem = {
          email,
          name,
          phoneNumber,
          classlogs: [],
        } as ITeacherClasslog;
        classlogs.forEach(({ id, classDate, teacherId }) => {
          if (teacherId === tId) {
            const logItem = { id, classDate, teacherId };
            newItem.classlogs.push(logItem);
          }
        });
        teacherClasslogs.push(newItem);
      });

      setTeacherClasslog(teacherClasslogs);
    },
  });

  const now = new Date();
  const [searchDate, setSearchDate] = useState<{ year: number; month: number }>(
    { year: now.getFullYear(), month: now.getMonth() + 1 }
  );

  const onSearch = () => {
    const { year, month } = searchDate;
    const from = +`${year}${('0' + month).substr(-2)}01`;
    const to = +`${year}${('0' + (month + 1)).substr(-2)}01`;

    getTeacherClasslog({
      variables: { input: { from, to } },
    });
  };

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="layer items-center">
        <div className="layer-title flex-row justify-between w-1024px">
          <div className="flex">
            <span className="layer-title-text">선생님 활동 내역</span>
          </div>

          <div className="flex flex-row justify-center">
            <div className="flex flex-row ">
              <div className="flex ml-2 w-72">
                <div className="flex flex-row -mt-1 w-full">
                  <YearPicker
                    value={searchDate.year}
                    onChangeValue={(value: number) => {
                      setSearchDate(prev => ({ ...prev, year: value }));
                    }}
                  />
                  <MonthPicker
                    value={searchDate.month}
                    onChangeValue={(value: number) => {
                      setSearchDate(prev => ({ ...prev, month: value }));
                    }}
                  />
                </div>
              </div>
              <div className="flex">
                <button
                  disabled={loading}
                  className="btn btn-blue ml-2"
                  type="button"
                  onClick={onSearch}
                >
                  <SearchIcon className="h-4 w-4 text-gray-100" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Contents */}
        <div className="pb-12 w-1024px">
          <div className="flex align-middle w-full">
            <div className="flex w-full">
              {teacherClasslog.length > 0 ? (
                <div className="flex flex-col w-full gap-y-3">
                  <div className="flex flex-row items-center w-full mb-2 bg-gray-100 py-2">
                    <div className="flex w-1/4 justify-center text-center">
                      Email
                    </div>
                    <div className="flex w-1/4 justify-center text-center">
                      이름
                    </div>
                    <div className="flex w-1/4 justify-center text-center">
                      휴대폰번호
                    </div>
                    <div className="flex w-1/4 justify-center text-center">
                      활동 횟수
                    </div>
                  </div>
                  {teacherClasslog.map((teacher, idx) => (
                    <div
                      key={idx}
                      className="flex flex-row items-center w-full"
                    >
                      <div className="flex w-1/4 justify-center text-center">
                        {teacher.email}
                      </div>
                      <div className="flex w-1/4 justify-center text-center">
                        {teacher.name}
                      </div>
                      <div className="flex w-1/4 justify-center text-center">
                        {phoneNumberFormat(teacher.phoneNumber)}
                      </div>
                      <div className="flex w-1/4 justify-center text-center">
                        {teacher.classlogs.length}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col w-full items-center py-20">
                  <label className="text-lg">아직 활동내역이 없습니다.</label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
