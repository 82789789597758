import React, { createContext, useState } from 'react';

export interface IAlertContent {
  title: string;
  message: string;
  actionTitle: string;
  action: Function;
  posY: number;
}
interface IAlertContextValue {
  show: Function;
  hide: Function;
}

interface IAlertContext {
  isShown: boolean;
  alert: IAlertContextValue;
  content: IAlertContent;
  setContent: Function;
}

export const AlertContext = createContext<IAlertContext>({
  isShown: false,
  alert: { show: () => {}, hide: () => {} },
  content: {
    title: '',
    message: '',
    actionTitle: '',
    action: () => {},
    posY: 0,
  },
  setContent: () => {},
});

export const AlertProvider: React.FC<{}> = ({ children }) => {
  const [isShown, setIsShow] = useState(false);
  const alert = {
    show: () => setIsShow(true),
    hide: () => setIsShow(false),
  };

  const [content, setContent] = useState<IAlertContent>({
    title: '',
    message: '',
    actionTitle: '',
    action: () => {},
    posY: 0,
  });
  const value = { isShown, alert, content, setContent };
  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};
