import { gql } from '@apollo/client';

export const DELIVERY_CREATE_MUTATION = gql`
  mutation CreateDelivery($input: CreateDeliveryInput!) {
    createDelivery(input: $input) {
      error
      message
      deliveryId
    }
  }
`;

export const DELIVERY_UPDATE_MUTATION = gql`
  mutation UpdateDelivey($input: UpdateDeliveryInput!) {
    updateDelivery(input: $input) {
      error
      message
    }
  }
`;

export const DELIVERY_DELETE_MUTATION = gql`
  mutation DeleteDelivery($input: DeleteDeliveryInput!) {
    deleteDelivery(input: $input) {
      error
      message
    }
  }
`;

export const DELIVERY_QUERY = gql`
  query SearchDelivery($input: SearchDeliveryInput!) {
    searchDelivery(input: $input) {
      error
      message
      hasNext
      total
      deliveries {
        id
        trackingNumber
        company
        shippingDate
        user {
          id
          email
          name
          phoneNumber
          kakaoId
        }
      }
    }
  }
`;
