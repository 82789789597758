import { useState, useEffect } from 'react';

export const useScroll = () => {
  const [scrollHeight, setScrollHeight] = useState(document.body.scrollHeight);
  const [windowHeight, setWindowHeight] = useState(document.body.clientHeight);
  const [scrollY, setScrollY] = useState(0);
  const [remainToBottom, setRemainToBottom] = useState(
    scrollHeight - (windowHeight + scrollY)
  );
  const [isScroll, setIsScroll] = useState(false);

  const listener = () => {
    const offset = document.body.getBoundingClientRect();
    setScrollY(-offset.top);
    setScrollHeight(document.body.scrollHeight);
    setIsScroll(windowHeight < scrollHeight);
    setRemainToBottom(scrollHeight - (windowHeight - offset.top));
  };
  const resizeListener = () => {
    setWindowHeight(document.body.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('scroll', listener);
      window.removeEventListener('resize', resizeListener);
    };
  });

  return {
    remainToBottom,
    scrollY,
    isScroll,
  };
};
