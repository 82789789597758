import React, {useState} from 'react';
import {Modal} from './modal';
import {EnumDay} from '../graphql/__generated__/globalTypes';
import {ClockIcon} from "@heroicons/react/outline";

const HOURS = ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'];
const MINUTES = ['00', '30'];
const DAY_OPTIONS = [{
  label: '월',
  value: EnumDay.Mon,
}, {
  label: '화',
  value: EnumDay.Tue,
}, {
  label: '수',
  value: EnumDay.Wed,
}, {
  label: '목',
  value: EnumDay.Thu,
}, {
  label: '금',
  value: EnumDay.Fri,
}, {
  label: '토',
  value: EnumDay.Sat,
}, {
  label: '일',
  value: EnumDay.Sun,
}]

export interface ScheduleInfo {
  day: EnumDay;
  hour: number;
  minute: number;
}

export interface TeacherListModalProps extends Omit<React.ComponentProps<typeof Modal>, 'title' | 'content' | 'hideButtonArea' | 'buttonTitle' | 'buttonAction'> {
  defaultValue?: ScheduleInfo;
  onSubmit: (scheduleInfo: ScheduleInfo) => void;
}

const TeacherListModal: React.FC<TeacherListModalProps> = ({defaultValue, onSubmit, ...props}) => {
  const [scheduleInfo, setScheduleInfo] = useState(defaultValue || {
    day: EnumDay.Mon,
    hour: 12,
    minute: 0,
  });

  return (
    <Modal {...props} title={'수업시간 변경'} buttonTitle={'변경'} buttonAction={() => {
      onSubmit(scheduleInfo)
    }} content={<div>
      <div className="mt-4">
        <div className="w-full sm:max-w-lg sm:px-10">
          <div className="flex flex-col px-6 items-center">
            <div className="w-full flex flex-row">
              <span className="font-semibold">요일</span>
            </div>
            <div
              className="flex flex-row items-center justify-between w-full
                            mt-4 border border-gray-300 rounded-full p-px"
            >
              {DAY_OPTIONS.map(option => <button
                key={option.value}
                className={`
                        ${
                  scheduleInfo.day === option.value
                    ? 'bg-yellow-400 font-bold '
                    : 'bg-white font-medium '
                }
                        sm:w-12 sm:h-12 w-11 h-11 rounded-full`}
                onClick={() =>
                  setScheduleInfo((prev) => ({
                    ...prev,
                    day: option.value,
                  }))
                }
              >
                {option.label}
              </button>)}
            </div>
          </div>

          <div className="flex flex-col px-6 mt-6">
            <div className="flex flex-row w-full justify-between items-baseline">
              <span className="font-semibold">시간</span>
              <span className="text-xs sr-only">수업 시간은 50분 입니다.</span>
            </div>
            <div className="flex flex-row mt-4 w-full">
              <div
                className="w-full flex flex-row items-center justify-center py-2
                              rounded-full border border-gray-300"
              >
                <ClockIcon className="w-5 h-5 text-gray-500 mr-2"/>
                <div className="">
                  <select
                    className="px-5 py-1 border-0 focus:ring-0 focus:outline-none"
                    value={scheduleInfo.hour}
                    // eslint-disable-next-line jsx-a11y/aria-role
                    role="schedule-hour"
                    onChange={(e) =>
                      setScheduleInfo((prev) => ({
                        ...prev,
                        hour: +e.target.value,
                      }))
                    }
                  >
                    {HOURS.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                </div>
                <span>:</span>
                <div className="">
                  <select
                    className="px-5 py-1 border-0 focus:ring-0 focus:outline-none"
                    value={scheduleInfo.minute}
                    // eslint-disable-next-line jsx-a11y/aria-role
                    role="schedule-minute"
                    onChange={(e) =>
                      setScheduleInfo((prev) => ({
                        ...prev,
                        minute: +e.target.value,
                      }))
                    }
                  >
                    {MINUTES.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}/>
  );
};

export default TeacherListModal;
