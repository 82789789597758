import { gql } from '@apollo/client';

export const SEARCH_REPORT = gql`
  query SearchReports($input: SearchReportsInput!) {
    searchReports(input: $input) {
      error
      message
      hasNext
      total
      reports {
        id
        url
        reportYear
        reportMonth
        babyInfo
        user {
          id
          email
          name
          phoneNumber
          kakaoId
        }
      }
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      error
      message
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      error
      message
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DeleteReport($input: DeleteReportInput!) {
    deleteReport(input: $input) {
      error
      message
    }
  }
`;
