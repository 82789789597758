import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { EnumDay } from '../../graphql/__generated__/globalTypes';
import { ToastType } from '../../components/toast';
import { BanIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_TIMES, UPSERT_TIME } from '../../graphql/time';
import {
  UpsertTime,
  UpsertTimeVariables,
} from '../../graphql/__generated__/UpsertTime';
import {
  GetTimes,
  GetTimesVariables,
} from '../../graphql/__generated__/GetTimes';

interface IModal {
  userId?: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setToast: React.Dispatch<
    React.SetStateAction<{
      type: ToastType;
      title: string;
      message: string;
      show: boolean;
    }>
  >;
}

interface ITimeForm {
  id?: number;
  day: EnumDay;
  startTime: number;
  endTime: number;
  isDeleted: boolean;
}

interface ITimeInfo {
  id?: number;
  day: EnumDay;
  startTime: number;
  endTime: number;
}

export const TeacherTime: React.FC<IModal> = ({
  userId,
  open,
  setOpen,
  setToast,
}: IModal) => {
  const cancelButtonRef = useRef(null);

  const defaultTimeInfo = {
    day: EnumDay.Mon,
    startTime: 900,
    endTime: 1000,
  };
  const [timeInfo, setTimeInfo] = useState<ITimeInfo>({ ...defaultTimeInfo });
  const [error, setError] = useState(false);

  const [times, setTimes] = useState<ITimeForm[]>([]);
  const getTimesOfDay = (day: EnumDay) => {
    return times.filter(time => time.day === day && !time.isDeleted);
  };
  const findTimeIdx = (timeData: ITimeInfo | ITimeForm) => {
    return times.findIndex(
      v =>
        v.day === timeData.day &&
        v.startTime === timeData.startTime &&
        v.endTime === timeData.endTime
    );
  };
  const deleteTime = (data: ITimeInfo | ITimeForm) => {
    let newTimes = [];
    if (data.id) {
      newTimes = times.map(time => {
        if (time.id === data.id) {
          time.isDeleted = true;
        }
        return time;
      });
    } else {
      newTimes = [...times];
      newTimes.splice(findTimeIdx(data), 1);
    }
    setTimes(newTimes);
  };

  const isValidTime = (data: ITimeInfo | ITimeForm) => {
    const { startTime, endTime } = data;
    return startTime < endTime;
  };

  useEffect(() => {
    setError(!isValidTime(timeInfo));
  }, [timeInfo]);

  const reset = () => {
    setTimeInfo({ ...defaultTimeInfo });
    setError(false);
    setTimes([]);
  };

  const [getTimes, { loading }] = useLazyQuery<GetTimes, GetTimesVariables>(
    GET_TIMES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getTimes: { error, message, times } }) => {
        setToast({
          type: error ? ToastType.Fail : ToastType.Success,
          title: '활동 시간',
          message: error
            ? message || '활동 시간 조회에 실패했습니다.'
            : '활동 시간 조회에 성공했습니다.',
          show: true,
        });

        if (error) {
          setTimeout(() => {
            reset();
            setOpen(false);
          }, 500);
        }

        setTimes(
          times.map(time => ({
            id: time.id,
            day: time.day,
            startTime: time.startTime,
            endTime: time.endTime,
            isDeleted: false,
          }))
        );
      },
    }
  );

  useEffect(() => {
    if (userId && open) {
      getTimes({ variables: { input: { id: userId } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, open]);

  const [upsertTime, { loading: upserting }] = useMutation<
    UpsertTime,
    UpsertTimeVariables
  >(UPSERT_TIME, {
    onCompleted: ({ upsertTime: { error, message } }) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: '활동 시간',
        message: error
          ? message || '활동 시간 수정에 실패했습니다.'
          : '활동 시간 수정에 성공했습니다.',
        show: true,
      });

      setTimeout(() => {
        reset();
        setOpen(false);
      }, 500);
    },
  });

  const onSubmit = () => {
    if (!upserting && userId) {
      upsertTime({
        variables: { userId: { id: userId }, input: [...times] },
      });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left 
                        overflow-hidden shadow-xl transform transition-all my-8 
                        max-w-3xl w-full p-6"
            >
              <div className="flex w-full">
                <div className="mt-0 ml-4 text-left w-full">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    활동 시간 입력
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col">
                    <div className="flex flex-col items-center py-5 px-4 ">
                      <div className="flex items-center w-full">
                        <select
                          className="block shadow-sm text-sm rounded-md mr-2
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={timeInfo.day}
                          onChange={e =>
                            setTimeInfo(prev => ({
                              ...prev,
                              day: e.target.value as EnumDay,
                            }))
                          }
                        >
                          <option value={EnumDay.Mon}>월</option>
                          <option value={EnumDay.Tue}>화</option>
                          <option value={EnumDay.Wed}>수</option>
                          <option value={EnumDay.Thu}>목</option>
                          <option value={EnumDay.Fri}>금</option>
                          <option value={EnumDay.Sat}>토</option>
                          <option value={EnumDay.Sun}>일</option>
                        </select>
                        요일
                        <select
                          className="block shadow-sm text-sm rounded-md mx-2
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={Math.floor(timeInfo.startTime / 100)}
                          onChange={e => {
                            const startTime =
                              +e.target.value * 100 +
                              (timeInfo.startTime % 100);
                            setTimeInfo(prev => ({ ...prev, startTime }));
                          }}
                        >
                          <option value={9}>09</option>
                          <option value={10}>10</option>
                          <option value={11}>11</option>
                          <option value={12}>12</option>
                          <option value={13}>13</option>
                          <option value={14}>14</option>
                          <option value={15}>15</option>
                          <option value={16}>16</option>
                          <option value={17}>17</option>
                          <option value={18}>18</option>
                          <option value={19}>19</option>
                          <option value={20}>20</option>
                          <option value={21}>21</option>
                        </select>
                        시
                        <select
                          className="block shadow-sm text-sm rounded-md mx-2
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={timeInfo.startTime % 100}
                          onChange={e => {
                            const startTime =
                              Math.floor(timeInfo.startTime / 100) * 100 +
                              +e.target.value;
                            setTimeInfo(prev => ({ ...prev, startTime }));
                          }}
                        >
                          <option value={0}>00</option>
                          <option value={30}>30</option>
                        </select>
                        분 ~
                        <select
                          className="block shadow-sm text-sm rounded-md mx-2
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={Math.floor(timeInfo.endTime / 100)}
                          onChange={e => {
                            const endTime =
                              +e.target.value * 100 + (timeInfo.endTime % 100);
                            setTimeInfo(prev => ({ ...prev, endTime }));
                          }}
                        >
                          <option value={9}>09</option>
                          <option value={10}>10</option>
                          <option value={11}>11</option>
                          <option value={12}>12</option>
                          <option value={13}>13</option>
                          <option value={14}>14</option>
                          <option value={15}>15</option>
                          <option value={16}>16</option>
                          <option value={17}>17</option>
                          <option value={18}>18</option>
                          <option value={19}>19</option>
                          <option value={20}>20</option>
                          <option value={21}>21</option>
                        </select>
                        시
                        <select
                          className="block shadow-sm text-sm rounded-md mx-2
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={timeInfo.endTime % 100}
                          onChange={e => {
                            const endTime =
                              Math.floor(timeInfo.endTime / 100) * 100 +
                              +e.target.value;
                            setTimeInfo(prev => ({ ...prev, endTime }));
                          }}
                        >
                          <option value={0}>00</option>
                          <option value={30}>30</option>
                        </select>
                        분
                        <div className="ml-6">
                          <button
                            type="button"
                            className={`btn ${error ? 'btn-red' : 'btn-blue'}`}
                            onClick={() => {
                              if (!error) {
                                setTimes(prev => [
                                  ...prev,
                                  { ...timeInfo, isDeleted: false },
                                ]);
                              }
                            }}
                          >
                            {error ? (
                              <BanIcon className="w-4 h-4" />
                            ) : (
                              <PlusIcon className="w-4 h-4" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col items-center py-5 px-4 border rounded space-y-2">
                      {getTimesOfDay(timeInfo.day).map((time, idx) => (
                        <div className="flex items-center w-full" key={idx}>
                          <select
                            className="block shadow-sm text-sm rounded-md mx-2
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            value={Math.floor(time.startTime / 100)}
                            onChange={e => {
                              const newTimes = [...times];
                              const timeIdx = findTimeIdx(time);
                              if (timeIdx > -1) {
                                const prev = newTimes[timeIdx].startTime;
                                const startTime =
                                  +e.target.value * 100 +
                                  (time.startTime % 100);
                                if (startTime < time.endTime) {
                                  newTimes[timeIdx].startTime = startTime;
                                  setTimes(newTimes);
                                } else {
                                  time.startTime = prev;
                                }
                              }
                            }}
                          >
                            <option value={9}>09</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                          </select>
                          시
                          <select
                            className="block shadow-sm text-sm rounded-md mx-2
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            value={time.startTime % 100}
                            onChange={e => {
                              const newTimes = [...times];
                              const timeIdx = findTimeIdx(time);
                              if (timeIdx > -1) {
                                const prev = newTimes[timeIdx].startTime;
                                const startTime =
                                  Math.floor(time.startTime / 100) * 100 +
                                  +e.target.value;
                                if (startTime < time.endTime) {
                                  newTimes[timeIdx].startTime = startTime;
                                  setTimes(newTimes);
                                } else {
                                  time.startTime = prev;
                                }
                              }
                            }}
                          >
                            <option value={0}>00</option>
                            <option value={30}>30</option>
                          </select>
                          분 ~
                          <select
                            className="block shadow-sm text-sm rounded-md mx-2
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            value={Math.floor(time.endTime / 100)}
                            onChange={e => {
                              const newTimes = [...times];
                              const timeIdx = findTimeIdx(time);
                              if (timeIdx > -1) {
                                const prev = newTimes[timeIdx].endTime;
                                const endTime =
                                  +e.target.value * 100 + (time.endTime % 100);
                                if (endTime > time.startTime) {
                                  newTimes[timeIdx].endTime = endTime;
                                  setTimes(newTimes);
                                } else {
                                  time.endTime = prev;
                                }
                              }
                            }}
                          >
                            <option value={9}>09</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                          </select>
                          시
                          <select
                            className="block shadow-sm text-sm rounded-md mx-2
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            value={time.endTime % 100}
                            onChange={e => {
                              const newTimes = [...times];
                              const timeIdx = findTimeIdx(time);
                              if (timeIdx > -1) {
                                const prev = newTimes[timeIdx].endTime;
                                const endTime =
                                  Math.floor(time.endTime / 100) * 100 +
                                  +e.target.value;
                                if (endTime > time.startTime) {
                                  newTimes[timeIdx].endTime = endTime;
                                  setTimes(newTimes);
                                } else {
                                  time.endTime = prev;
                                }
                              }
                            }}
                          >
                            <option value={0}>00</option>
                            <option value={30}>30</option>
                          </select>
                          분
                          <div className="ml-6">
                            <button
                              type="button"
                              className={`btn btn-white`}
                              onClick={() => deleteTime(time)}
                            >
                              <TrashIcon className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 flex flex-row-reverse">
                <button
                  type="button"
                  disabled={upserting || loading}
                  className="btn btn-purple"
                  onClick={onSubmit}
                >
                  저장
                </button>
                <button
                  disabled={upserting}
                  type="button"
                  className="btn btn-white mx-4"
                  onClick={() => {
                    reset();
                    setOpen(false);
                  }}
                  ref={cancelButtonRef}
                >
                  취소
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
