import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CalendarIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router';
import { Toast, ToastType } from '../../components/toast';
import { DELIVERY_CREATE_MUTATION } from '../../graphql/delivery';
import {
  CreateDelivery,
  CreateDeliveryVariables,
} from '../../graphql/__generated__/CreateDelivery';
import { DeliveryCompany } from '../../graphql/__generated__/globalTypes';
import { phoneNumberFormat } from '../../utils/phone-format';
import { DatePicker } from '../../components/date-picker';
import dayjs from 'dayjs';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { SearchParentsComponent } from '../../components/search-user/search-parents';

interface IDeliveryInput {
  id?: number;
  userId: number;
  trackingNumber: string;
  company: DeliveryCompany;
  shippingDate: string;
}

interface IUser {
  id: number;
  email: string;
  name: string;
  phoneNumber: string;
  kakaoId: string | null;
}

const TITLE = '택배정보';
export const DeliveryCreate: React.FC<{}> = () => {
  const history = useHistory();

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const [targetUser, setTargetUser] = useState<IUser>({
    id: 0,
    name: '',
    email: '',
    phoneNumber: '',
    kakaoId: '',
  });
  const [delivery, setDelivery] = useState<IDeliveryInput>({
    userId: 0,
    trackingNumber: '',
    company: DeliveryCompany.CJ,
    shippingDate: Date.now().toString().substr(0, 8) + '00000',
  });
  const [errors, setErrors] = useState({
    userId: false,
    trackingNumber: false,
    company: false,
    shippingDate: false,
  });

  useEffect(() => {
    const dateString = delivery.shippingDate;
    setErrors(prev => {
      return {
        ...prev,
        shippingDate: dateString.length < 1,
      };
    });
  }, [delivery.shippingDate]);

  const [createDelivery, { loading: creating }] = useMutation<
    CreateDelivery,
    CreateDeliveryVariables
  >(DELIVERY_CREATE_MUTATION, {
    onCompleted: ({
      createDelivery: { error, message, deliveryId },
    }: CreateDelivery) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 생성에 실패했습니다.`
          : `${TITLE} 생성에 성공했습니다.`,
        show: true,
      });

      if (!error) {
        setTimeout(() => {
          history.push('/classes/delivery');
        }, 700);
      }
    },
  });

  const onSubmit = () => {
    if (!Object.values(errors).includes(true)) {
      createDelivery({
        variables: { input: { ...delivery, userId: targetUser.id } },
      });
    }
  };

  // Calendar
  const [showCalendar, setShowCalendar] = useState(false);
  const refCalendar = useRef(null);
  useOnClickOutside(refCalendar, () => setShowCalendar(false));

  return (
    <>
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="layer">
        <div className="layer-title flex-row justify-between">
          <span className="layer-title-text">택배 배송</span>
        </div>

        <div className="py-4 border-t border-gray-300 flex flex-row justify-between divide-x">
          <div className="p-4 flex flex-col flex-1 w-full">
            {/* Search User Component */}
            <SearchParentsComponent
              handleClickUser={(user: IUser) => setTargetUser(user)}
              handleError={(message: string) => {
                setToast({
                  type: ToastType.Fail,
                  title: '사용자 목록 조회',
                  message: message || '사용자 목록 조회에 실패했습니다.',
                  show: true,
                });
              }}
            />
          </div>

          <div className="p-4 flex flex-col flex-1 w-full">
            <div className="w-full border-b border-gray-300 pb-2">
              <span className="text-xl font-semibold">택배 배송</span>
            </div>
            <div className="flex flex-col py-3 divide-y gap-3">
              {/* user info */}
              <div className="flex flex-col">
                <div className="flex py-1">
                  <span className="text-lg font-semibold">사용자 정보</span>
                </div>
                <div className="flex flex-col py-1">
                  <div className="w-full flex flex-row justify-start items-center">
                    {targetUser.email}{' '}
                    {targetUser.name && (
                      <span className="rounded-full bg-blue-100 text-blue-800 py-px px-3 text-sm ml-1">
                        {targetUser.name}
                      </span>
                    )}
                  </div>
                  <div className="w-full flex flex-row justify-start items-center mt-1">
                    {phoneNumberFormat(targetUser.phoneNumber)}{' '}
                    {targetUser.kakaoId && (
                      <span className="rounded-full bg-green-100 text-green-800 py-px px-3 text-sm ml-1">
                        {targetUser.kakaoId}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* delivery info */}
              <div className="flex flex-col w-full">
                <div className="flex py-1">
                  <span className="text-lg font-semibold">배송 정보</span>
                </div>

                <div className="grid grid-cols-5 items-center mb-2 w-full">
                  <div className="flex items-center pl-4 col-span-2">
                    <label className="block text-sm font-medium text-gray-700">
                      운송장번호
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1 col-span-3">
                    <input
                      className={`flex-1 block rounded-md text-sm shadow-sm w-full resize-none
                        border px-2 py-2
                        ${
                          errors.trackingNumber
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                      type="text"
                      value={delivery.trackingNumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;

                        setErrors(prev => {
                          return { ...prev, trackingNumber: value.length < 12 };
                        });

                        setDelivery(prev => {
                          return { ...prev, trackingNumber: value };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-5 items-center mb-2 w-full">
                  <div className="flex items-center pl-4 col-span-2">
                    <label className="block text-sm font-medium text-gray-700">
                      택배회사
                    </label>
                  </div>
                  <div className="flex flex-1 items-center col-span-3">
                    <div className="text-sm font-medium text-gray-700">
                      <select
                        className="block w-full shadow-sm text-sm rounded-md 
                                border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        value={delivery.company}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          const value = e.target.value;
                          setDelivery(prev => {
                            return {
                              ...prev,
                              company: value as DeliveryCompany,
                            };
                          });
                        }}
                      >
                        <option value={DeliveryCompany.CJ}>CJ대한통운</option>
                        <option value={DeliveryCompany.POST}>우체국택배</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-5 items-center mb-2 w-full">
                  <div className="flex items-center pl-4 col-span-2">
                    <label className="block text-sm font-medium text-gray-700">
                      날짜
                    </label>
                  </div>
                  <div
                    ref={refCalendar}
                    className="relative mt-0 flex flex-col flex-1 col-span-3"
                  >
                    <div
                      className="flex flex-row group"
                      onClick={() => setShowCalendar(true)}
                    >
                      <input
                        className={`flex-1 block rounded-md text-sm shadow-sm w-full resize-none
                        border px-2 py-2
                        ${
                          errors.shippingDate
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                        type="text"
                        readOnly={true}
                        value={dayjs(+delivery.shippingDate).format(
                          'YYYY-MM-DD'
                        )}
                      />
                      <div className="relative mt-0 flex cursor-pointer">
                        <CalendarIcon className="w-7 h-auto ml-2" />
                      </div>
                    </div>
                    <div
                      className={`${
                        showCalendar ? 'visible' : 'invisible'
                      } absolute z-10 mt-12 w-full flex justify-end`}
                    >
                      <DatePicker
                        className="p-2"
                        setClose={() => setShowCalendar(false)}
                        setDate={(value: string) => {
                          setDelivery(prev => ({
                            ...prev,
                            shippingDate: value.substr(0, 8) + '00000',
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* buttons */}
              <div className="flex mt-5 pt-5 justify-end">
                <div>
                  <button
                    type="button"
                    disabled={creating}
                    className="py-2 px-4 border rounded-md shadow-sm text-sm font-medium
                            bg-white border-gray-300 text-gray-700
                            hover:bg-gray-300 hover:ring-2 hover:ring-offset-2 hover:ring-gray-300"
                    onClick={() => history.push('/classes/delivery')}
                  >
                    취소
                  </button>
                  <button
                    type="button"
                    disabled={
                      creating ||
                      Object.values(errors).includes(true) ||
                      Object.values(delivery).includes('') ||
                      targetUser.id === 0
                    }
                    className="ml-3 py-2 px-4 rounded-md shadow-sm text-sm font-medium 
                              bg-purple-500 hover:bg-purple-700 text-purple-50
                              hover:ring-2 hover:ring-offset-2 hover:ring-purple-500
                              disabled:ring-0 disabled:bg-purple-300 disabled:cursor-default"
                    onClick={onSubmit}
                  >
                    저장
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
