/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Toast, ToastType } from '../../components/toast';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import {
  SearchQnas,
  SearchQnasVariables,
} from '../../graphql/__generated__/SearchQnas';
import {
  QNA_UPDATE_MUTATION,
  QNA_QUERY,
  QNA_CREATE_MUTATION,
  QNA_DELETE_MUTATION,
} from '../../graphql/info';
import {
  UpdateQna,
  UpdateQnaVariables,
} from '../../graphql/__generated__/UpdateQna';
import {
  CreateQna,
  CreateQnaVariables,
} from '../../graphql/__generated__/CreateQna';
import {
  DeleteQna,
  DeleteQnaVariables,
} from '../../graphql/__generated__/DeleteQna';
import { Modal } from '../../components/modal';
import { QnaType } from '../../graphql/__generated__/globalTypes';

interface IQnaInput {
  id?: number;
  order: number;
  question: string;
  answer: string;
  isHidden: boolean;
  type: QnaType;
}

interface IQnaDetailParams {
  id: string;
}

const TITLE = '이용안내';

export const QnaDetail: React.FC<{}> = () => {
  const history = useHistory();
  const { id } = useParams<IQnaDetailParams>();

  useEffect(() => {
    if (id) {
      getQna({ variables: { input: { id: +id } } });
    }
  }, []);

  const [origin, setOrigin] = useState<IQnaInput>({
    order: 0,
    question: '',
    answer: '',
    isHidden: false,
    type: QnaType.Parents,
  });
  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: '',
    message: '',
    show: false,
  });

  const [getQna, { loading }] = useLazyQuery<SearchQnas, SearchQnasVariables>(
    QNA_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ searchQnas: { error, message, qnas } }: SearchQnas) => {
        const qnaData = qnas ? qnas[0] : null;
        const fail = error || !qnaData;

        setToast({
          type: fail ? ToastType.Fail : ToastType.Success,
          title: TITLE,
          message: fail
            ? message || `${TITLE} 조회에 실패했습니다.`
            : `${TITLE} 조회에 성공했습니다.`,
          show: true,
        });

        if (qnaData) {
          const { id, order, question, answer, isHidden, type } = qnaData;
          setOrigin({ id, order, question, answer, isHidden, type });
        } else {
          setTimeout(() => {
            history.push('/info/qna');
          }, 500);
        }
      },
    }
  );

  const [updateQna, { loading: updating }] = useMutation<
    UpdateQna,
    UpdateQnaVariables
  >(QNA_UPDATE_MUTATION, {
    onCompleted: ({ updateQna: { error, message } }: UpdateQna) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 수정에 실패했습니다.`
          : `${TITLE} 수정에 성공했습니다.`,
        show: true,
      });

      if (!error) {
        setTimeout(() => {
          history.push('/info/qna');
        }, 500);
      }
    },
  });

  const [createQna, { loading: creating }] = useMutation<
    CreateQna,
    CreateQnaVariables
  >(QNA_CREATE_MUTATION, {
    onCompleted: ({ createQna: { error, message } }: CreateQna) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 생성에 실패했습니다.`
          : `${TITLE} 생성에 성공했습니다.`,
        show: true,
      });

      if (!error) {
        setTimeout(() => {
          history.push('/info/qna');
        }, 500);
      }
    },
  });

  const [deleteQna, { loading: deleting }] = useMutation<
    DeleteQna,
    DeleteQnaVariables
  >(QNA_DELETE_MUTATION, {
    onCompleted: ({ deleteQna: { error, message } }: DeleteQna) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 삭제에 실패했습니다.`
          : `${TITLE} 삭제에 성공했습니다.`,
        show: true,
      });

      if (!error) {
        setTimeout(() => {
          history.push('/info/qna');
        }, 500);
      }
    },
  });

  const onSubmit = () => {
    if (id) {
      updateQna({ variables: { input: { ...origin, id: +id } } });
    } else {
      createQna({ variables: { input: { ...origin } } });
    }
  };

  const [errors, setErrors] = useState({
    order: false,
    question: false,
    answer: false,
    isHidden: false,
  });

  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col px-8 py-4">
      <Modal
        open={open}
        setOpen={setOpen}
        title="Qna 삭제"
        content="삭제 할까요?"
        buttonTitle="삭제"
        buttonAction={() => deleteQna({ variables: { input: { id: +id } } })}
      />
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />
      <div className="mt-2">
        <div className="py-2 px-8 flex flex-col justify-center mx-auto max-w-3xl">
          <form className="space-y-5 divide-y divide-gray-200 flex flex-col">
            <div className="space-y-5 divide-y divide-gray-200 flex flex-col px-6 ">
              <div className="flex flex-col">
                <div className="flex flex-row items-center">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    이용안내
                  </h3>
                </div>

                <div className="mt-5 space-y-5 flex flex-col">
                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-20 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        정렬
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <div className="w-20">
                        <input
                          className={`flex-1 block rounded-md text-sm shadow-sm w-full
                        ${
                          errors.order
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                          value={origin.order}
                          type="number"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setOrigin(prev => {
                              const order = +e.target.value;

                              setErrors(prev => {
                                return { ...prev, order: order < 0 };
                              });

                              return { ...prev, order };
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex items-center w-20 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        상태
                      </label>
                    </div>
                    <div className="flex flex-1 items-center px-4">
                      <div className="text-sm font-medium text-gray-700">
                        <select
                          className="block w-full shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={'' + origin.isHidden}
                          onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            const value = e.target.value;
                            setOrigin(prev => {
                              return { ...prev, isHidden: value === 'true' };
                            });
                          }}
                        >
                          <option value="true">감추기</option>
                          <option value="false">표시</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex items-center w-20 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        타입
                      </label>
                    </div>
                    <div className="flex flex-1 items-center px-4">
                      <div className="text-sm font-medium text-gray-700">
                        <select
                          className="block w-full shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={'' + origin.type}
                          onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            const type = e.target.value as QnaType;
                            setOrigin(prev => ({ ...prev, type }));
                          }}
                        >
                          <option value={QnaType.Parents}>부모님</option>
                          <option value={QnaType.Teacher}>선생님</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        질문
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <textarea
                        className={`flex-1 block rounded-md text-sm shadow-sm w-full resize-none
                        ${
                          errors.question
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                        value={origin.question}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const value = e.target.value;

                          setErrors(prev => {
                            return { ...prev, question: value.length < 1 };
                          });

                          setOrigin(prev => {
                            return { ...prev, question: value };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        답변
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <textarea
                        className={`flex-1 block rounded-md text-sm shadow-sm w-full resize-none
                        h-56
                        ${
                          errors.answer
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                        value={origin.answer}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const value = e.target.value;

                          setErrors(prev => {
                            return { ...prev, answer: value.length < 1 };
                          });

                          setOrigin(prev => {
                            return { ...prev, answer: value };
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex border-t mt-5 pt-5 px-6 justify-between">
              <div>
                {id && (
                  <button
                    type="button"
                    disabled={loading || updating || creating || deleting}
                    className="py-2 px-4 border rounded-md shadow-sm text-sm font-medium
                  bg-red-500 hover:bg-red-700 text-red-50
                  hover:ring-2 hover:ring-offset-2 hover:ring-red-500
                  disabled:ring-0 disabled:bg-red-300 disabled:cursor-default"
                    onClick={() => setOpen(true)}
                  >
                    삭제
                  </button>
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="py-2 px-4 border rounded-md shadow-sm text-sm font-medium
                bg-white border-gray-300 text-gray-700
                hover:bg-gray-300 hover:ring-2 hover:ring-offset-2 hover:ring-gray-300"
                  onClick={() => history.push('/info/qna')}
                >
                  취소
                </button>
                <button
                  type="button"
                  disabled={
                    loading ||
                    updating ||
                    creating ||
                    deleting ||
                    Object.values(errors).includes(true) ||
                    Object.values(origin).includes('')
                  }
                  className="ml-3 py-2 px-4 rounded-md shadow-sm text-sm font-medium 
                bg-purple-500 hover:bg-purple-700 text-purple-50
                hover:ring-2 hover:ring-offset-2 hover:ring-purple-500
                disabled:ring-0 disabled:bg-purple-300 disabled:cursor-default"
                  onClick={onSubmit}
                >
                  저장
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
