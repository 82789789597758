import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { SEARCH_APPLICATIONS } from '../../graphql/application';
import { useParams } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/solid';
import { DELETE_CLASSLOG, UPDATE_CLASSLOG } from '../../graphql/classlog';
import {
  DeleteClasslog,
  DeleteClasslogVariables,
} from '../../graphql/__generated__/DeleteClasslog';
import {
  SearchApplications,
  SearchApplicationsVariables,
  SearchApplications_searchApplications_applications,
  SearchApplications_searchApplications_applications_classlogs,
} from '../../graphql/__generated__/SearchApplications';
import {
  UpdateClasslog,
  UpdateClasslogVariables,
} from '../../graphql/__generated__/UpdateClasslog';
import { Toast, ToastType } from '../../components/toast';

interface IParams {
  id: string;
}

const TITLE = '수업일지';

export const ApplicationClasslog: React.FC<{}> = () => {
  const history = useHistory();
  const { id } = useParams<IParams>();

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const [application, setApplication] =
    useState<SearchApplications_searchApplications_applications | null>(null);
  const [classlogs, setClasslogs] = useState<
    SearchApplications_searchApplications_applications_classlogs[]
  >([]);

  const { loading: fetchingApplication } = useQuery<
    SearchApplications,
    SearchApplicationsVariables
  >(SEARCH_APPLICATIONS, {
    fetchPolicy: 'no-cache',
    variables: { input: { id: +id } },
    onCompleted: ({ searchApplications: { error, message, applications } }) => {
      if (error || applications.length !== 1) {
        console.log('[ERROR] searchApplications :: ', message);
        history.goBack();
      }
      setApplication(applications[0]);
      setClasslogs(applications[0].classlogs || []);
    },
  });

  const [deleteClasslog, { loading: deleting }] = useMutation<
    DeleteClasslog,
    DeleteClasslogVariables
  >(DELETE_CLASSLOG, {
    onCompleted: ({ deleteClasslog: { error, message, id } }) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error ? '삭제에 실패했습니다.' : '삭제에 성공했습니다.',
        show: true,
      });

      if (error) {
        console.log('[ERROR] deleteClasslog :: ', message);
      } else {
        const newClasslogs = [...classlogs];
        const idx = newClasslogs.findIndex(v => v.id === id);
        newClasslogs.splice(idx, 1);
        setClasslogs(newClasslogs);
      }
    },
  });

  const [updateClasslog, { loading: updating }] = useMutation<
    UpdateClasslog,
    UpdateClasslogVariables
  >(UPDATE_CLASSLOG, {
    onCompleted: ({ updateClasslog: { error, message } }) => {
      if (error) {
        console.log('[ERROR] updateClasslog :: ', message);
      }

      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error ? '수정에 실패했습니다.' : '수정에 성공했습니다.',
        show: true,
      });
    },
  });

  return (
    <>
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="layer items-center">
        <div className="layer-title flex-row justify-center w-1024px">
          <div className="space-y-5 divide-y divide-gray-200 flex flex-col items-center">
            <div className="space-y-5 divide-y divide-gray-200 flex flex-col px-6 w-800px">
              <div className="flex flex-col w-full">
                <div className="flex items-baseline justify-between">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    수업 일지
                  </h3>

                  <div className="flex">
                    <button
                      type="button"
                      className="btn btn-gray"
                      onClick={() => history.push(`/classes/application/${id}`)}
                    >
                      수업(신청서) 확인
                    </button>
                  </div>
                </div>

                <div className="mt-5 space-y-5 flex flex-col border-t border-gray-200 py-5">
                  {classlogs.map((log, idx) => (
                    <div
                      key={idx}
                      className="flex flex-col w-full border border-gray-300 rounded p-4"
                    >
                      <div className="flex gap-x-2">
                        <label className="font-semibold text-black">
                          {log.classDate.toString().substr(0, 4)}년
                        </label>
                        <label className="font-semibold text-black">
                          {log.classDate.toString().substr(4, 2)}월
                        </label>
                        <label className="font-semibold text-black">
                          {log.classDate.toString().substr(6, 2)}일
                        </label>
                      </div>

                      <div className="flex w-full flex-1 gap-x-7 justify-start mt-10">
                        {(() => {
                          const urls: string[] = [];
                          const { photo1, photo2, photo3, photo4, photo5 } =
                            log;
                          if (photo1) urls.push(photo1);
                          if (photo2) urls.push(photo2);
                          if (photo3) urls.push(photo3);
                          if (photo4) urls.push(photo4);
                          if (photo5) urls.push(photo5);

                          return urls.map((url, urlIdx) => (
                            <>
                              {url && (
                                <div
                                  key={url}
                                  className="flex relative items-center w-1/6"
                                >
                                  <img
                                    alt={`${urlIdx}`}
                                    src={url}
                                    className="w-22 h-22 rounded"
                                  />
                                  <div className="absolute top-0 right-0">
                                    <button
                                      type="button"
                                      className="flex bg-white rounded-full w-6 h-6 
                                      justify-center items-center"
                                      onClick={() => {
                                        const newUrls = [
                                          ...urls,
                                          '',
                                          '',
                                          '',
                                          '',
                                          '',
                                        ];
                                        newUrls.splice(urlIdx, 1);

                                        const newClasslogs = [...classlogs];
                                        const target = newClasslogs[idx];
                                        const targetUrls = newUrls.slice(0, 5);
                                        target.photo1 = targetUrls[0];
                                        target.photo2 = targetUrls[1];
                                        target.photo3 = targetUrls[2];
                                        target.photo4 = targetUrls[3];
                                        target.photo5 = targetUrls[4];
                                        setClasslogs(newClasslogs);
                                      }}
                                    >
                                      <TrashIcon className="w-4 h-4 text-gray-700 flex" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </>
                          ));
                        })()}
                      </div>

                      <div className="flex w-full mt-10">
                        <textarea
                          className="flex rounded-md text-sm w-full p-2 h-80 resize-none
                        border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={log.content}
                          onChange={e => {
                            const newClasslogs = [...classlogs];
                            const target = newClasslogs[idx];
                            target.content = e.target.value;
                            setClasslogs(newClasslogs);
                          }}
                        />
                      </div>

                      <div className="flex justify-between mt-6">
                        <button
                          disabled={
                            // loading ||
                            fetchingApplication || deleting || updating
                          }
                          type="button"
                          className="btn btn-red"
                          onClick={() =>
                            deleteClasslog({
                              variables: {
                                input: {
                                  id: log.id,
                                  teacherId: application?.teacherId,
                                },
                              },
                            })
                          }
                        >
                          삭제
                        </button>

                        <button
                          disabled={
                            // loading ||
                            fetchingApplication || deleting || updating
                          }
                          type="button"
                          className="btn btn-purple"
                          onClick={() => {
                            const {
                              id,
                              photo1,
                              photo2,
                              photo3,
                              photo4,
                              photo5,
                              content,
                              classDate,
                            } = log;
                            updateClasslog({
                              variables: {
                                input: {
                                  applicationId: application?.id,
                                  teacherId: application?.teacherId,
                                  id,
                                  photo1,
                                  photo2,
                                  photo3,
                                  photo4,
                                  photo5,
                                  content,
                                  classDate,
                                },
                              },
                            });
                          }}
                        >
                          수정
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
