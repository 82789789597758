import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header } from '../components/header';
import { Application } from '../pages/classes/application';
import { Delivery } from '../pages/classes/delivery';
import { DeliveryCreate } from '../pages/classes/delivery-create';
import { DeliveryDetail } from '../pages/classes/delivery-detail';
import { ReportCreate } from '../pages/classes/report-create';
import { ReportDetail } from '../pages/classes/report-detail';
import { Report } from '../pages/classes/report';
import { Home } from '../pages/home';
import { Notice } from '../pages/infos/notice';
import { Qna } from '../pages/infos/qna';
import { Review } from '../pages/infos/review';
import { Terms } from '../pages/infos/terms';
import { Payment } from '../pages/payments/payment';
import { UserDetail } from '../pages/users/user-detail';
import { UserList } from '../pages/users/user-list';
import { Privacy } from '../pages/infos/privacy';
import { QnaDetail } from '../pages/infos/qna-detail';
import { ReviewDetail } from '../pages/infos/review-detail';
import { ApplicationDetail } from '../pages/classes/application-detail';
import { ApplicationClasslog } from '../pages/classes/application-classlog';
import { PaymentTeacher } from '../pages/payments/payment-teacher';

export const AuthRouter: React.FC<{}> = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/users/parents">
          <UserList />
        </Route>
        <Route path="/users/teacher">
          <UserList />
        </Route>
        <Route path="/users/admin">
          <UserList />
        </Route>
        <Route path="/users/:id">
          <UserDetail />
        </Route>

        <Route path="/classes/report/create" exact>
          <ReportCreate />
        </Route>
        <Route path="/classes/report/:id">
          <ReportDetail />
        </Route>
        <Route path="/classes/report">
          <Report />
        </Route>

        <Route path="/classes/application/:id/classlog">
          <ApplicationClasslog />
        </Route>
        <Route path="/classes/application/:id">
          <ApplicationDetail />
        </Route>
        <Route path="/classes/application">
          <Application />
        </Route>
        <Route path="/classes/create-delivery">
          <DeliveryCreate />
        </Route>
        <Route path="/classes/delivery/:id">
          <DeliveryDetail />
        </Route>
        <Route path="/classes/delivery">
          <Delivery />
        </Route>

        <Route path="/payments/teacher" exact>
          <PaymentTeacher />
        </Route>
        <Route path="/payments" exact>
          <Payment />
        </Route>

        <Route path="/info/qna/create">
          <QnaDetail />
        </Route>
        <Route path="/info/qna/:id">
          <QnaDetail />
        </Route>
        <Route path="/info/qna">
          <Qna />
        </Route>
        <Route path="/info/create-review">
          <ReviewDetail />
        </Route>
        <Route path="/info/review/:id">
          <ReviewDetail />
        </Route>
        <Route path="/info/review">
          <Review />
        </Route>
        <Route path="/info/terms">
          <Terms />
        </Route>
        <Route path="/info/privacy">
          <Privacy />
        </Route>
        <Route path="/info/notice">
          <Notice />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};
