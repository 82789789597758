import React, { useRef, useState } from 'react';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import { uploadPolicyFile } from '../../utils/upload-file';
import { Toast, ToastType } from '../../components/toast';
import { useMutation, useQuery } from '@apollo/client';
import { POLICY_MUTATION, POLICY_QUERY } from '../../graphql/info';
import {
  GetPolicy,
  GetPolicyVariables,
} from '../../graphql/__generated__/GetPolicy';
import { PolicyType } from '../../graphql/__generated__/globalTypes';
import {
  UpsertPolicy,
  UpsertPolicyVariables,
} from '../../graphql/__generated__/UpsertPolicy';

interface IPolicyInput {
  id?: number;
  type: PolicyType;
  title: string;
  content: string;
}

const POLICY_TITLE = '이용약관';

export const Terms: React.FC<{}> = () => {
  const editor = useRef<Editor | null>(null);
  const [origin, setOrigin] = useState<IPolicyInput>({
    type: PolicyType.Terms,
    title: POLICY_TITLE,
    content: '',
  });
  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: '',
    message: '',
    show: false,
  });

  const { loading } = useQuery<GetPolicy, GetPolicyVariables>(POLICY_QUERY, {
    variables: { input: { type: PolicyType.Terms } },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getPolicy: { error, message, policy } }: GetPolicy) => {
      if (error) {
        setToast({
          type: ToastType.Fail,
          title: POLICY_TITLE,
          message: message || `${POLICY_TITLE} 조회에 실패했습니다.`,
          show: true,
        });
      } else {
        if (policy?.length) {
          const policyData = policy[0];
          const { id, type, title, content } = policyData;
          setOrigin({ id, type, title, content });
          editor.current?.getInstance().setMarkdown(policyData.content, false);
        }
      }
    },
  });

  const onCompleted = ({ upsertPolicy: { error, message } }: UpsertPolicy) => {
    setToast({
      type: error ? ToastType.Fail : ToastType.Success,
      title: POLICY_TITLE,
      message: error
        ? message || `${POLICY_TITLE} 수정에 실패했습니다.`
        : `${POLICY_TITLE} 수정에 성공했습니다.`,
      show: true,
    });
    setOrigin(prev => {
      return {
        ...prev,
        content: editor.current?.getInstance().getMarkdown() || '',
      };
    });
  };

  const [upsertPolicy, { loading: upserting }] = useMutation<
    UpsertPolicy,
    UpsertPolicyVariables
  >(POLICY_MUTATION, { onCompleted });

  const addImageBlobHook = async (
    blob: File | Blob,
    callback: (url: string, altText: string) => void
  ) => {
    try {
      const { url } = await uploadPolicyFile(blob);
      callback(url, 'image');
    } catch (e) {
      setToast({
        type: ToastType.Fail,
        title: '이미지 업로드',
        message: '이미지 업로드에 실패했습니다.',
        show: true,
      });
    }
  };

  return (
    <div className="flex flex-col px-8 py-4 mx-auto">
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />
      <div className="max-w-7xl min-w-320 mt-4 mb-4 px-6 flex flex-row items-center justify-between">
        <div>
          <label className="font-medium text-xl">이용 약관</label>
        </div>
        <div className="flex flex-row">
          <button
            type="button"
            disabled={loading || upserting}
            className="btn btn-gray shadow-sm text-sm font-medium"
            onClick={() => {
              editor.current?.getInstance().setMarkdown(origin.content, false);
            }}
          >
            Reset
          </button>
          <button
            type="button"
            disabled={loading || upserting}
            className="btn btn-purple ml-3 shadow-sm text-sm font-medium"
            onClick={() => {
              const content = editor.current?.getInstance().getMarkdown() || '';
              const input = { ...origin, content };
              upsertPolicy({ variables: { input } });
            }}
          >
            Save
          </button>
        </div>
      </div>
      <div className="max-w-7xl min-w-320">
        <Editor
          ref={editor}
          initialValue={''}
          previewStyle="vertical"
          height="600px"
          initialEditType="markdown"
          useCommandShortcut={true}
          hooks={{ addImageBlobHook }}
        />
      </div>
    </div>
  );
};
