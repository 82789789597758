import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { UserRole } from '../../graphql/__generated__/globalTypes';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CREATE_USER_WITHOUT_AUTH } from '../../graphql/users';
import { ToastType } from '../../components/toast';
import {
  CreateUserWithoutAuth,
  CreateUserWithoutAuthVariables,
} from '../../graphql/__generated__/CreateUserWithoutAuth';

interface IModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setToast: React.Dispatch<
    React.SetStateAction<{
      type: ToastType;
      title: string;
      message: string;
      show: boolean;
    }>
  >;
}

interface IUserForm {
  email: string;
  password: string;
  phoneNumber: string;
  name: string;
  role: UserRole;
}

const regexEmail =
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;

export const UserCreate: React.FC<IModal> = ({
  open,
  setOpen,
  setToast,
}: IModal) => {
  const cancelButtonRef = useRef(null);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<IUserForm>({
    mode: 'onChange',
    defaultValues: {
      role: UserRole.Parents,
    },
  });

  const [createUser, { loading: creating }] = useMutation<
    CreateUserWithoutAuth,
    CreateUserWithoutAuthVariables
  >(CREATE_USER_WITHOUT_AUTH, {
    onCompleted: ({ createUserWithoutAuth: { error, message } }) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: '사용자 생성',
        message: error
          ? message || '사용자 생성에 실패했습니다.'
          : '사용자 생성에 성공했습니다.',
        show: true,
      });

      setTimeout(() => {
        reset();
        setOpen(false);
      }, 500);
    },
  });

  const onSubmit = () => {
    createUser({ variables: { input: { ...getValues() } } });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left 
                        overflow-hidden shadow-xl transform transition-all my-8 
                        max-w-lg w-full p-6"
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex w-full">
                  <div className="mt-0 ml-4 text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      사용자 생성
                    </Dialog.Title>
                    <div className="mt-2 flex flex-col">
                      <div className="flex flex-row items-center pt-5 px-4">
                        <div className="flex items-center w-32">
                          <label className="block text-sm font-medium text-gray-700">
                            email
                          </label>
                        </div>
                        <div className="mt-0 flex flex-1 w-full">
                          <input
                            className={`flex-1 block rounded-md text-sm shadow-sm w-full
                            ${
                              Object.keys(errors).includes('email')
                                ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                            }
                          `}
                            {...register('email', {
                              required: true,
                              pattern: regexEmail,
                            })}
                            type="text"
                            placeholder="이메일 입력"
                            autoCapitalize="off"
                            required
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center pt-5 px-4">
                        <div className="flex items-center w-32">
                          <label className="block text-sm font-medium text-gray-700">
                            비밀번호
                          </label>
                        </div>
                        <div className="mt-0 flex flex-1 w-full">
                          <input
                            className={`flex-1 block rounded-md text-sm shadow-sm w-full
                            ${
                              Object.keys(errors).includes('password')
                                ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                            }
                          `}
                            {...register('password', { required: true })}
                            type="password"
                            placeholder="비밀번호 입력"
                            autoCapitalize="off"
                            required
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center pt-5 px-4">
                        <div className="flex items-center w-32">
                          <label className="block text-sm font-medium text-gray-700">
                            이름
                          </label>
                        </div>
                        <div className="mt-0 flex flex-1 w-full">
                          <input
                            className={`flex-1 block rounded-md text-sm shadow-sm w-full
                        ${
                          Object.keys(errors).includes('name')
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                            {...register('name', { maxLength: 6 })}
                            type="text"
                            placeholder="이름 입력"
                            maxLength={6}
                            required
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center pt-5 px-4">
                        <div className="flex items-center w-32">
                          <label className="block text-sm font-medium text-gray-700">
                            휴대폰 번호
                          </label>
                        </div>
                        <div className="mt-0 flex flex-1 w-full">
                          <input
                            className={`flex-1 block rounded-md text-sm shadow-sm w-full
                        ${
                          Object.keys(errors).includes('phoneNumber')
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                            {...register('phoneNumber', {
                              required: true,
                              pattern: /^\d{10,}$/,
                            })}
                            type="text"
                            placeholder="휴대폰 번호"
                            required
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center pt-5 px-4">
                        <div className="flex items-center w-32">
                          <label className="block text-sm font-medium text-gray-700">
                            사용자 유형
                          </label>
                        </div>
                        <div className="mt-0 flex flex-1 w-full">
                          <select
                            className="block w-full shadow-sm text-sm rounded-md
                                  border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            {...register('role', { required: true })}
                          >
                            <option value={UserRole.Parents}>
                              {UserRole.Parents}
                            </option>
                            <option value={UserRole.Teacher}>
                              {UserRole.Teacher}
                            </option>
                            <option value={UserRole.Admin}>
                              {UserRole.Admin}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex flex-row-reverse">
                  <button
                    type="submit"
                    disabled={creating}
                    className="btn btn-purple"
                  >
                    사용자 생성
                  </button>
                  <button
                    disabled={creating}
                    type="button"
                    className="btn btn-white mx-4"
                    onClick={() => {
                      reset();
                      setOpen(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
