import { gql } from '@apollo/client';
import { APPLICATION_INFO, CLASSLOG_INFO, PAYMENT_INFO } from './fragments';

export const SEARCH_APPLICATIONS = gql`
  query SearchApplications($input: SearchApplicationsInput!) {
    searchApplications(input: $input) {
      error
      message
      hasNext
      total
      applications {
        ...ApplicationInfo
        totalCount
        currentCount
        parentsInfo
        teacherInfo
        cancelReason
        note
        payment {
          ...PaymentInfo
        }
        classlogs {
          ...ClasslogInfo
        }
      }
    }
  }
  ${APPLICATION_INFO}
  ${PAYMENT_INFO}
  ${CLASSLOG_INFO}
`;

export const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      error
      message
      application {
        ...ApplicationInfo
        totalCount
        currentCount
        parentsInfo
        teacherInfo
        cancelReason
        note
        payment {
          ...PaymentInfo
        }
        classlogs {
          ...ClasslogInfo
        }
      }
    }
  }
  ${APPLICATION_INFO}
  ${PAYMENT_INFO}
  ${CLASSLOG_INFO}
`;
