import gql from 'graphql-tag';
import { TEACHER_INFO, USER_INFO } from './fragments';

export const LOGIN_MUTATION = gql`
  mutation LoginAdmin($input: AdminLoginInput!) {
    adminLogin(input: $input) {
      error
      message
      token
      user {
        id
        email
        role
      }
    }
  }
`;

export const USER_LIST_QUERY = gql`
  query SearchUsers($input: SearchUsersInput!) {
    searchUsers(input: $input) {
      error
      message
      hasNext
      total
      users {
        ...UserInfo
        kakaoId
        teacher {
          id
          photoUrl
          rank
        }
        babies {
          id
          name
          birthYear
        }
      }
    }
  }
  ${USER_INFO}
`;

export const USER_QUERY = gql`
  query FindUser($input: FindUserInput!) {
    findUser(input: $input) {
      error
      message
      user {
        ...UserInfo
        kakaoId
        reportSubscription
        address
        addressDetail
        zoneNo
        babies {
          id
          name
          birthYear
          birthMonth
          birthDate
          sex
          answer
          infos
          isDeleted
        }
        teacher {
          ...TeacherInfo
        }
      }
    }
  }
  ${USER_INFO}
  ${TEACHER_INFO}
`;

export const USER_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      error
      message
      user {
        ...UserInfo
        kakaoId
        reportSubscription
        address
        addressDetail
        zoneNo
      }
    }
  }
  ${USER_INFO}
`;

export const ADMIN_MUTATION = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      error
      message
    }
  }
`;

export const CREATE_USER_WITHOUT_AUTH = gql`
  mutation CreateUserWithoutAuth($input: CreateUserInput!) {
    createUserWithoutAuth(input: $input) {
      error
      message
    }
  }
`;
