import axios from 'axios';
import { getToken } from './storage';

const TARGET_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : `https://api.${process.env.REACT_APP_WEBSITE}`;

export const uploadPolicyFile = async (file: File | Blob) => {
  try {
    const fileData = new FormData();
    fileData.append('file', file);

    const { data } = await axios.post(
      `${TARGET_URL}/uploads/policy`,
      fileData,
      {
        headers: {
          'x-jwt': getToken(),
        },
      }
    );
    return data;
  } catch (e) {
    console.log('[ERROR] Upload Policy File :: ', e);
    throw e;
  }
};

interface IUploadFileWithUserId {
  file: File | Blob;
  userId: number;
}

export const uploadReportFile = async ({
  file,
  userId,
}: IUploadFileWithUserId) => {
  try {
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('userId', `${userId}`);

    const { data } = await axios.post(
      `${TARGET_URL}/uploads/report`,
      fileData,
      {
        headers: {
          'x-jwt': getToken(),
        },
      }
    );
    return data;
  } catch (e) {
    console.log('[ERROR] Upload Report File :: ', e);
    throw e;
  }
};

export const uploadTeacherPhoto = async ({
  file,
  userId,
}: IUploadFileWithUserId) => {
  try {
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('userId', `${userId}`);

    const { data } = await axios.post(
      `${TARGET_URL}/uploads/profile-image`,
      fileData,
      {
        headers: {
          'x-jwt': getToken(),
        },
      }
    );
    return data;
  } catch (e) {
    console.log('[ERROR] Upload Teacher Photo :: ', e);
    throw e;
  }
};
