import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router';
import {Toast, ToastType} from '../../components/toast';
import {useMutation, useQuery} from '@apollo/client';
import {SEARCH_APPLICATIONS, UPDATE_APPLICATION,} from '../../graphql/application';
import {
  SearchApplications,
  SearchApplications_searchApplications_applications,
  SearchApplicationsVariables,
} from '../../graphql/__generated__/SearchApplications';
import {ApplicationStatus, EnumDay,} from '../../graphql/__generated__/globalTypes';
import dayjs from 'dayjs';
import {
  UpdateApplication,
  UpdateApplication_updateApplication_application,
  UpdateApplicationVariables,
} from '../../graphql/__generated__/UpdateApplication';
import {useParams} from 'react-router-dom';
import {DocumentTextIcon, PencilIcon, PlusIcon} from '@heroicons/react/solid';
import {Modal} from '../../components/modal';
import {CANCEL_PAYMENT} from '../../graphql/payment';
import {CancelPayment, CancelPaymentVariables,} from '../../graphql/__generated__/CancelPayment';
import {phoneNumberFormat} from '../../utils/phone-format';
import TeacherListModal, {TeacherInfo} from "../../components/teacher-list-modal";
import ScheduleModal, {ScheduleInfo} from "../../components/schedule-modal";

interface IParams {
  id: string;
}

const getObj = (data: string | undefined) => {
  return JSON.parse(data || '{}');
};

const TITLE = '신청서';
export const ApplicationDetail: React.FC<{}> = () => {
  const history = useHistory();
  const {id} = useParams<IParams>();

  const [application, setApplication] = useState<| SearchApplications_searchApplications_applications
    | UpdateApplication_updateApplication_application>();
  const [teacherInfo, setTeacherInfo] = useState<TeacherInfo>();
  const [scheduleInfo, setScheduleInfo] = useState<ScheduleInfo>();
  const [status, setStatus] = useState<ApplicationStatus>();
  const [cancelReason, setCancelReason] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [report, setReport] = useState<boolean>(false);
  const [cancelAmount, setCancelAmount] = useState<string>('0');

  const [isOpenTeacherListModal, setIsOpenTeacherListModal] = useState(false);
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const {loading} = useQuery<SearchApplications, SearchApplicationsVariables>(
    SEARCH_APPLICATIONS,
    {
      fetchPolicy: 'no-cache',
      variables: {input: {id: +id}},
      onCompleted: ({
                      searchApplications: {error, message, applications},
                    }) => {
        const failed = error || applications.length !== 1;

        setToast({
          type: failed ? ToastType.Fail : ToastType.Success,
          title: TITLE,
          message: failed
            ? `${TITLE} 검색에 실패했습니다.`
            : `${TITLE} 검색에 성공했습니다.`,
          show: true,
        });

        if (failed) {
          console.log('[ERROR] searchApplications :: ', message);
          history.goBack();
        }

        setStatus(applications[0].status);
        setCancelReason(applications[0].cancelReason);
        setNote(applications[0].note);
        setReport(applications[0].report);
        setApplication(applications[0]);

        const paidAmount = applications[0].payment?.paidAmount;
        setCancelAmount(paidAmount ? paidAmount.toLocaleString() : '0');
      },
    }
  );

  const [updateApplication, {loading: updating}] = useMutation<UpdateApplication,
    UpdateApplicationVariables>(UPDATE_APPLICATION, {
    onCompleted: ({updateApplication: {error, message, application}}) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? `${TITLE} 수정에 실패했습니다.`
          : `${TITLE} 수정에 성공했습니다.`,
        show: true,
      });

      if (error) {
        console.log('[ERROR] updateApplication :: ', message);
      }

      if (application) {
        setStatus(application.status);
        setCancelReason(application.cancelReason);
        setNote(application.note);
        setApplication(application);
      }
    },
  });
  const selectedTeacherInfo = useMemo(() => {
    if (teacherInfo) {
      return teacherInfo
    }

    return getObj(application?.teacherInfo)
  }, [application?.teacherInfo, teacherInfo]);
  const selectedScheduleInfo: ScheduleInfo | undefined = useMemo(() => {
    if (scheduleInfo) {
      return scheduleInfo
    }

    if (!application?.startDay || !application?.startTime) {
      return undefined
    }

    return {
      day: application?.startDay,
      hour: Math.floor(application?.startTime / 100),
      minute: application?.startTime % 100
    }
  }, [application?.startDay, application?.startTime, scheduleInfo]);

  const onSubmit = async () => {
    const input = {
      id: +id,
      status,
      cancelReason,
      report,
      note,
      description: application?.description,
      teacherId: teacherInfo?.id,
      startDay: scheduleInfo?.day,
      startTime: scheduleInfo?.hour !== undefined && scheduleInfo?.minute !== undefined ?
        Number(`${scheduleInfo.hour}${`0${scheduleInfo.minute}`.substr(-2)}`) :
        undefined
    };
    await updateApplication({variables: {input: {...input}}});
  };

  const [cancelPayment, {loading: cancelling}] = useMutation<CancelPayment,
    CancelPaymentVariables>(CANCEL_PAYMENT, {
    onCompleted: ({cancelPayment: {error, message}}) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? `결제 취소에 실패했습니다. (${message})`
          : '결제 취소에 성공했습니다.',
        show: true,
      });

      if (error) {
        console.log('[ERROR] cancelPayment :: ', message);
      } else {
        setStatus(ApplicationStatus.CancelSelf);
        if (application && application.payment) {
          setApplication({
            ...application,
            payment: {
              ...application.payment,
              status: 'cancelled',
              cancelAmount: +cancelAmount.replace(/\D/g, ''),
            },
          });
        }
      }
    },
  });

  const [open, setOpen] = useState(false);
  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        title="결제 취소"
        content="결제를 취소 할까요?"
        buttonTitle="결제 취소"
        buttonAction={() => {
          const {impUid, merchantUid} = application?.payment || {};
          if (
            application?.status &&
            [ApplicationStatus.Waiting, ApplicationStatus.Proceeding].includes(
              application?.status
            ) &&
            impUid &&
            merchantUid
          ) {
            cancelPayment({
              variables: {
                input: {
                  impUid,
                  merchantUid,
                  cancelAmount: +cancelAmount.replace(/\D/g, ''),
                },
              },
            });
          }
        }}
      />
      <TeacherListModal open={isOpenTeacherListModal} setOpen={setIsOpenTeacherListModal}
                        onSelectTeacher={(teacherInfo) => {
                          setTeacherInfo(teacherInfo)
                        }}/>
      {application &&
        <ScheduleModal open={isOpenScheduleModal} defaultValue={selectedScheduleInfo} setOpen={setIsOpenScheduleModal}
                       onSubmit={(data) => {
                         setScheduleInfo(data)
                       }}/>}

      <Toast {...toast} hide={() => setToast({...toast, show: false})}/>

      <div className="layer items-center">
        <div className="layer-title flex-row justify-center w-1024px">
          <div className="space-y-5 divide-y divide-gray-200 flex flex-col items-center">
            <div className="space-y-5 divide-y divide-gray-200 flex flex-col px-6 w-800px">
              <div className="flex flex-col w-full">
                <div className="flex items-baseline">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    신청서 정보
                  </h3>
                </div>

                <div className="mt-5 space-y-5 flex flex-col">
                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        부모님 정보
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <div
                        className="flex-1 block rounded-md text-sm w-full
                        border-gray-100 bg-gray-200 text-gray-900 px-3 py-2"
                      >
                        {getObj(application?.parentsInfo).email}
                      </div>
                    </div>
                    <div className="mt-0 flex ml-2">
                      <div
                        className="flex-1 block rounded-md text-sm w-full
                        border-gray-100 bg-gray-200 text-gray-900 px-4 py-2"
                      >
                        {phoneNumberFormat(
                          getObj(application?.parentsInfo).phoneNumber || ''
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-end px-4 w-24">
                      <div className="text-sm text-gray-700 font-light">
                        {getObj(application?.parentsInfo).name}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        아이 이름 / 나이
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1 flex-col">
                      {(() => {
                        const babyObj = getObj(application?.babyInfo);
                        return application?.babyIds.map(id => (
                          <div
                            key={id}
                            className={`flex-1 block rounded-md text-sm w-full
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500
                        `}
                          >
                            {babyObj[id].name} {babyObj[id].age}세
                          </div>
                        ));
                      })()}
                    </div>
                    <div className="flex items-center justify-end px-4 w-52"></div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        주소
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <div
                        className={`flex-1 block rounded-md text-sm w-full
                        border-gray-300 focus:ring-indigo-500 focus:border-indigo-500
                        `}
                      >
                        {application?.address}
                      </div>
                      <div
                        className={`flex-1 block rounded-md text-sm w-full
                        border-gray-300 focus:ring-indigo-500 focus:border-indigo-500
                        `}
                      >
                        {application?.addressDetail}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        선생님 정보
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <div
                        className="flex-1 block rounded-md text-sm w-full
                        border-gray-100 bg-gray-200 text-gray-900 px-3 py-2"
                      >
                        {selectedTeacherInfo.email}
                      </div>
                    </div>
                    <div className="mt-0 flex ml-2">
                      <div
                        className="flex-1 block rounded-md text-sm w-full
                        border-gray-100 bg-gray-200 text-gray-900 px-4 py-2"
                      >
                        {phoneNumberFormat(
                          selectedTeacherInfo.phoneNumber || ''
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-end px-4 w-24">
                      <div className="text-sm text-gray-700 font-light">
                        {selectedTeacherInfo.name}
                      </div>
                    </div>
                    <div className="flex items-center justify-end px-4 flex-none">
                      <button
                        disabled={loading || !id || updating || cancelling}
                        className="flex items-center text-sm py-1 px-2 bg-blue-500 text-white rounded"
                        onClick={() => {
                          setIsOpenTeacherListModal(true)
                        }}
                      >
                        <PencilIcon className="h-4 text-white mr-1"/>{' '}
                        변경
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        수업 시간
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <div
                        className={`flex flex-1 rounded-md text-sm w-full
                        border-gray-300 focus:ring-indigo-500 focus:border-indigo-500
                        `}
                      >
                        <div className="flex items-center">
                          매주{' '}
                          <span className="ml-2">
                            {(() => {
                              switch (selectedScheduleInfo?.day) {
                                case EnumDay.Mon:
                                  return '월요일';
                                case EnumDay.Tue:
                                  return '화요일';
                                case EnumDay.Wed:
                                  return '수요일';
                                case EnumDay.Thu:
                                  return '목요일';
                                case EnumDay.Fri:
                                  return '금요일';
                                case EnumDay.Sat:
                                  return '토요일';
                                case EnumDay.Sun:
                                  return '일요일';
                                default:
                                  return null
                              }
                            })()}
                          </span>
                          {selectedScheduleInfo?.hour !== undefined && selectedScheduleInfo?.minute !== undefined && (
                            <span className="ml-2">
                              {selectedScheduleInfo.hour}:{`0${selectedScheduleInfo.minute}`.substr(-2)}
                            </span>
                          )}
                        </div>
                        <div className="flex items-center ml-4">
                          {application?.startAt && (
                            <label className="block text-sm text-gray-700">
                              {application.startAt.length > 1 && (
                                <span>
                                  ({' '}
                                  {dayjs(+application.startAt).format(
                                    'YYYY-MM-DD'
                                  )}{' '}
                                  부터 )
                                </span>
                              )}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-end px-4 flex-none">
                        <button
                          disabled={loading || !id || updating || cancelling}
                          className="flex items-center text-sm py-1 px-2 bg-blue-500 text-white rounded"
                          onClick={() => {
                            setIsOpenScheduleModal(true)
                          }}
                        >
                          <PencilIcon className="h-4 text-white mr-1"/>{' '}
                          변경
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex w-full">
                      <div className="flex items-center w-40 pl-4">
                        <label className="block text-sm font-medium text-gray-700">
                          수업 횟수
                        </label>
                      </div>
                      <div className="flex items-center justify-between ">
                        <span className="text-base font-normal">
                          {(application?.classlogs || []).length} /{' '}
                          {application?.totalCount}
                        </span>

                        <div className="flex ml-6">
                          <button
                            disabled={loading || !id || updating || cancelling}
                            className="flex items-center text-sm py-1 px-2 bg-blue-500 text-white rounded"
                            onClick={() =>
                              history.push(
                                `/classes/application/${id}/classlog`
                              )
                            }
                          >
                            <DocumentTextIcon className="w-4 h-4 text-white mr-1"/>{' '}
                            수업일지 확인
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex w-full">
                      <div className="flex items-center w-40 pl-4">
                        <label className="block text-sm font-medium text-gray-700">
                          발달보고서
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 
                                  text-indigo-600 hover:ring-indigo-500"
                          checked={report}
                          onChange={() => setReport(prev => !prev)}
                        />
                      </div>
                      <button
                        disabled={!application?.report}
                        className="ml-4 p-1 bg-blue-500 disabled:bg-blue-200 rounded flex"
                        onClick={() =>
                          history.push('/classes/report/create', {
                            parentsId: application?.parentsId,
                            babyId: application?.babyIds[0],
                          })
                        }
                      >
                        <PlusIcon className="w-4 h-4 text-white"/>
                        <span className="text-white text-xs">
                          발달보고서 작성
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        수업 요청 사항
                      </label>
                    </div>
                    <div className="mt-0 flex flex-col flex-1">
                      <div
                        className={`flex-1 block rounded-md text-sm w-full mt-2
                        border-gray-300 focus:ring-0 outline-none focus:outline-none focus:border-gray-300
                        `}
                      >
                        <textarea
                          className={`flex-1 block rounded-md text-sm w-full p-2 h-28
                        border-gray-300 focus:ring-0 outline-none focus:outline-none focus:border-gray-300
                        `}
                          value={application?.description}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        상태
                      </label>
                    </div>

                    <div className="mt-0 flex flex-1">
                      <div className="text-sm font-medium text-gray-700">
                        <select
                          className="block w-full text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={status}
                          onChange={e => {
                            const status = e.target.value;
                            if (status) {
                              setStatus(status as ApplicationStatus);
                            } else {
                              setStatus(undefined);
                            }
                          }}
                        >
                          <option value={ApplicationStatus.Writing}>
                            작성중
                          </option>
                          <option value={ApplicationStatus.Payment}>
                            결제 대기
                          </option>
                          <option value={ApplicationStatus.Waiting}>
                            신청 완료
                          </option>
                          <option value={ApplicationStatus.Proceeding}>
                            진행중
                          </option>
                          <option value={ApplicationStatus.CancelSelf}>
                            본인 취소
                          </option>
                          <option value={ApplicationStatus.CancelTeacher}>
                            선생님 취소
                          </option>
                          <option value={ApplicationStatus.Done}>
                            수업 완료
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${
                      status === ApplicationStatus.CancelSelf ||
                      application?.payment?.status === 'cancelled'
                        ? 'flex'
                        : 'hidden'
                    } flex-row items-center border-t border-gray-200 pt-5`}
                  >
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        결제금액
                      </label>
                    </div>

                    <div className="mt-0 flex flex-1">
                      <div className="flex flex-1 text-sm font-medium text-gray-700 items-center">
                        <span className="flex w-full ml-2">
                          {application?.payment?.paidAmount.toLocaleString()}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        환불금액
                      </label>
                    </div>

                    <div className="mt-0 flex flex-1">
                      <div className="flex flex-1 text-sm font-medium text-gray-700 items-center">
                        <span className="flex w-full ml-2">
                          {application?.payment?.cancelAmount.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${
                      (status === ApplicationStatus.Waiting ||
                        status === ApplicationStatus.Proceeding) &&
                      application?.payment?.id &&
                      application?.payment?.paidAmount &&
                      application?.payment?.status === 'paid'
                        ? 'flex'
                        : 'hidden'
                    } flex-row items-center border-t border-gray-200 pt-5`}
                  >
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        결제 취소(환불)
                      </label>
                    </div>

                    <div className="mt-0 flex flex-1">
                      <div className="flex flex-1 text-sm font-medium text-gray-700 items-center">
                        <input
                          className="flex w-full py-3 px-2 rounded-md outline-none text-right
                          border border-gray-300 ring-0
                          focus:border-indigo-500 focus:ring-indigo-500 focus:ring-1
                          "
                          value={cancelAmount}
                          onChange={e => {
                            const value = +e.target.value.replace(/\D/g, '');
                            setCancelAmount(value.toLocaleString());
                          }}
                        />{' '}
                        <span className="flex w-full ml-2">
                          / {application?.payment?.paidAmount.toLocaleString()}
                        </span>
                      </div>
                    </div>

                    {application?.payment?.impUid &&
                      <div className="mr-4">
                            <span className="rounded-md bg-blue-400 text-sm text-white px-2 py-1">
                              {application.payment.impUid}
                            </span>
                      </div>
                    }

                    <div className="flex items-center justify-end px-4 ">
                      <div className="text-sm text-gray-700 font-light">
                        <button
                          type="button"
                          disabled={
                            loading ||
                            !id ||
                            updating ||
                            cancelling ||
                            !(
                              status &&
                              [
                                ApplicationStatus.Waiting,
                                ApplicationStatus.Proceeding,
                              ].includes(status)
                            )
                          }
                          className="btn btn-red"
                          onClick={() => setOpen(true)}
                        >
                          환불
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        취소 사유
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <textarea
                        className={`flex-1 block rounded-md text-sm w-full
                        border-gray-300 focus:ring-indigo-500 focus:border-indigo-500
                        `}
                        value={cancelReason}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          setCancelReason(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        노트
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <textarea
                        className={`flex-1 block rounded-md text-sm w-full h-28
                        border-gray-300 focus:ring-indigo-500 focus:border-indigo-500
                        `}
                        value={note}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          setNote(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex border-t mt-5 pt-5 px-6 justify-end w-full max-w-4xl mb-20">
              <button
                type="button"
                disabled={loading || updating || cancelling}
                className="btn btn-white text-sm font-medium"
                onClick={() => history.push('/classes/application')}
              >
                취소
              </button>
              <button
                type="button"
                disabled={loading || !id || updating || cancelling}
                className="btn btn-purple ml-3 text-sm font-medium"
                onClick={onSubmit}
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
