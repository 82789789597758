import { ExportParents_exportParents_users } from '../graphql/__generated__/ExportParents';
import { EnumSex, TeacherRank } from '../graphql/__generated__/globalTypes';
import xlsx from 'xlsx';
import { ExportTeacher_exportTeacher_users } from '../graphql/__generated__/ExportTeacher';
import dayjs from 'dayjs';

interface IParentDataToExcel {
  users: ExportParents_exportParents_users[];
}

interface ITeacherDataToExcel {
  users: ExportTeacher_exportTeacher_users[];
}

export const parentDataToExcel = ({ users }: IParentDataToExcel) => {
  const workSheetColumnNames = [
    '이름',
    '이메일',
    '휴대폰번호',
    '아이 이름',
    '아이 생년월일',
    '아이 성별',
    '카카오톡',
    '주소',
    '상세주소',
    '우편번호',
  ];
  const data: string[][] = [];
  users.forEach(user => {
    const userData1 = [user.name, user.email, user.phoneNumber];
    const userData2 = [
      user.kakaoId || '',
      user.address,
      user.addressDetail,
      user.zoneNo,
    ];

    if (user.babies.length === 0) {
      data.push([...userData1, '', '', '', ...userData2]);
    } else {
      user.babies.forEach(baby => {
        if (!baby.isDeleted) {
          data.push([
            ...userData1,
            baby.name,
            `${baby.birthYear}-${baby.birthMonth}-${baby.birthDate}`,
            baby.sex === EnumSex.Female ? '여아' : '남아',
            ...userData2,
          ]);
        }
      });
    }
  });

  const workBook = xlsx.utils.book_new();
  const workSheetData = [workSheetColumnNames, ...data];
  const workSheet = xlsx.utils.aoa_to_sheet(workSheetData);
  const workSheetName = 'Parents';
  xlsx.utils.book_append_sheet(workBook, workSheet, workSheetName);
  xlsx.writeFile(workBook, `parents_${new Date().getTime()}.xlsx`);
};

export const teacherDataToExcel = ({ users }: ITeacherDataToExcel) => {
  const workSheetColumnNames = [
    '이름',
    '이메일',
    '휴대폰번호',
    '카카오톡',
    '성별',
    '생년월일',
    '대학교',
    '전공',
    '등급',
    '가입일',
  ];
  const data: string[][] = [];
  users.forEach(user => {
    const userData = [
      user.name,
      user.email,
      user.phoneNumber,
      user.kakaoId || '',
    ];

    const teacher = user.teacher;
    if (teacher) {
      const teacherData = [
        teacher.sex === EnumSex.Female ? '여' : '남',
        `${teacher.birthYear}-${teacher.birthMonth}-${teacher.birthDate}`,
        teacher.universityName,
        teacher.universityMajor,
        (() => {
          switch (teacher.rank) {
            case TeacherRank.Sprout:
              return '새싹';
            case TeacherRank.Tree:
              return '나무';
            case TeacherRank.Fruit:
              return '열매';
            case TeacherRank.Premium:
              return '프리미엄';
          }
        })(),
        dayjs(+teacher.createdAt).format('DD/MM/YYYY HH:mm:ss'),
      ];
      data.push([...userData, ...teacherData]);
    } else {
      data.push([...userData, '', '', '', '', '', '']);
    }
  });

  const workBook = xlsx.utils.book_new();
  const workSheetData = [workSheetColumnNames, ...data];
  const workSheet = xlsx.utils.aoa_to_sheet(workSheetData);
  const workSheetName = 'Teacher';
  xlsx.utils.book_append_sheet(workBook, workSheet, workSheetName);
  xlsx.writeFile(workBook, `teacher_${new Date().getTime()}.xlsx`);
};
