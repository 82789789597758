export const getItem = (name: string) => {
  return sessionStorage.getItem(name);
};

export const setItem = (name: string, item: string) => {
  sessionStorage.setItem(name, item);
};

export const removeItem = (name: string) => {
  sessionStorage.removeItem(name);
};

const TOKEN_SESSION_KEY = 'x-ck-admin-token';
export const getToken = () => {
  return sessionStorage.getItem(TOKEN_SESSION_KEY);
};

export const setToken = (item: string) => {
  sessionStorage.setItem(TOKEN_SESSION_KEY, item);
};

export const removeToken = () => {
  sessionStorage.removeItem(TOKEN_SESSION_KEY);
};
