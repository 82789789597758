import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { isLoggedInVar } from '../../graphql/apollo';
import { Button } from '../../components/button';
import { Toast, ToastType } from '../../components/toast';
import { setToken } from '../../utils/storage';
import { LOGIN_MUTATION } from '../../graphql/users';
import {
  LoginAdmin,
  LoginAdminVariables,
} from '../../graphql/__generated__/LoginAdmin';

interface IForm {
  email: string;
  password: string;
}

export const Login: React.FC<{}> = () => {
  const history = useHistory();
  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: '로그인',
    message: '',
    show: false,
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<IForm>({ mode: 'onChange' });

  const onCompleted = ({
    adminLogin: { error, message, token },
  }: LoginAdmin) => {
    if (error) {
      setToast({ ...toast, message: message || '', show: true });
    } else {
      if (token) {
        setToken(token);
        isLoggedInVar(true);
        history.push('/');
      }
    }
  };
  const [loginMutation, { loading }] = useMutation<
    LoginAdmin,
    LoginAdminVariables
  >(LOGIN_MUTATION, { onCompleted });

  const onSubmit = () => {
    if (!loading) {
      loginMutation({ variables: { input: { ...getValues() } } });
    }
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />
      <div className="flex flex-col w-full max-w-md min-w-min justify-center items-center">
        <span className="text-5xl font-bold mb-5 text-gray-900">
          아티키즈 관리자
        </span>

        <form
          className="w-full flex flex-col px-5 py-7 border border-purple-300 rounded-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col w-full h-20">
            <span className="text-sm text-gray-600 font-light mb-1">email</span>
            <input
              className={`w-full py-3 px-2 rounded-md
              border-0 focus:border-0 ring-2 hover:ring-2 focus:ring-2
              ${
                Object.keys(errors).includes('email')
                  ? 'ring-red-300 hover:ring-red-500 focus:ring-red-500'
                  : 'ring-purple-300 hover:ring-purple-500 focus:ring-purple-500'
              }
              `}
              {...register('email', { required: true })}
              type="email"
              required
            />
          </div>
          <div className="flex flex-col w-full h-20">
            <span className="text-sm text-gray-600 font-light mb-1">
              password
            </span>
            <input
              className={`w-full py-3 px-2 rounded-md
              border-0 focus:border-0 ring-2 hover:ring-2 focus:ring-2
              ${
                Object.keys(errors).includes('password')
                  ? 'ring-red-300 hover:ring-red-500 focus:ring-red-500'
                  : 'ring-purple-300 hover:ring-purple-500 focus:ring-purple-500'
              }
              `}
              {...register('password', { required: true })}
              type="password"
              required
            />
          </div>
          <Button title="Log In" disabled={!isValid} loading={loading} />
        </form>
      </div>
    </div>
  );
};
