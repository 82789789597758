import React, {useState} from 'react';
import {Modal} from './modal';
import {useQuery} from "@apollo/client";
import {phoneNumberFormat} from "../utils/phone-format";
import {SpinnerSvg} from "./svg/spinner";
import {
  GetTeachers,
  GetTeachers_getTeachers_users,
  GetTeachersVariables
} from "../graphql/__generated__/GetTeachers";
import {GET_TEACHERS} from "../graphql/teacher";

interface IWhere {
  page: number;
  email?: string;
  name?: string;
}

export interface TeacherInfo {
  id: number;
  name: string;
  email: string;
  phoneNumber: string
}

export interface TeacherListModalProps extends Omit<React.ComponentProps<typeof Modal>, 'title' | 'content' | 'hideButtonArea' | 'buttonTitle' | 'buttonAction'> {
  onSelectTeacher: (teacherInfo: TeacherInfo) => void;
}

const TeacherListModal: React.FC<TeacherListModalProps> = ({onSelectTeacher, ...props}) => {
  const [users, setUsers] = useState<GetTeachers_getTeachers_users[]>([]);
  const [where, setWhere] = useState<IWhere>({page: 1});
  const [next, setNext] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const {loading} = useQuery<GetTeachers, GetTeachersVariables>(GET_TEACHERS, {
    fetchPolicy: 'no-cache',
    variables: {input: {...where, startTime: 900, endTime: 2130}},
    onCompleted: ({
                    getTeachers: {error, message, users, total, hasNext},
                  }: GetTeachers) => {
      setNext(Boolean(hasNext));
      setTotalCount(total || 0);

      if (error) {
        console.log('[ERROR] getTeachers :: ', message);
      }

      if (where.page === 1) {
        setUsers(users);
      } else {
        setUsers((prev) => [...prev, ...users]);
      }
    },
  });

  return (
    <Modal {...props} hideButtonArea={true} title={'선생님 변경'} content={<div>
      <div className="flex flex-col justify-between w-full border-b border-gray-300 pb-2">
        <div className="mb-2">
          <span className="text-xl font-semibold">선생님 목록</span>
          <span className="text-sm font-light"> ({totalCount})</span>
        </div>
      </div>
      <div className="flex flex-col max-h-80 overflow-y-auto">
        <div className="shadow border-b border-gray-200 rounded-lg">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500
                    uppercase tracking-wider"
              >
                Email(이름)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500
                    uppercase tracking-wider"
              >
                휴대폰 번호
              </th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {users.map(user => (
              <tr key={user.id} className="relative hover:bg-blue-400 hover:bg-opacity-10">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div>
                      <div className="flex text-sm font-medium text-gray-900">
                        {user.email}
                      </div>
                      <div className="flex text-sm items-center font-light mt-1">
                        <label className="mr-2">{user.name}</label>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {phoneNumberFormat(user.phoneNumber)}
                  </div>
                </td>
                <td>
                  <button className="absolute inset-0 opacity-0" onClick={() => {
                    if (!user.teacher?.id) {
                      return
                    }

                    onSelectTeacher({
                      id: user.id,
                      name: user.name,
                      email: user.email,
                      phoneNumber: user.phoneNumber,
                    })
                    props.setOpen(false)
                  }}>
                    선택
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>

          {loading && next && (
            <div className="flex items-center justify-center py-6 px-10 border-t border-gray-100">
              <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-500"/>
            </div>
          )}
          {next && !loading && <div className="flex justify-center p-2" onClick={() => {
            setWhere(prev => {
              return {...prev, page: prev.page + 1};
            });
          }}>
            <button className="btn btn-white">더보기</button>
          </div>}
        </div>
      </div>
    </div>}/>
  );
};

export default TeacherListModal;
