import { useQuery } from '@apollo/client';
import { Switch } from '@headlessui/react';
import { PlusSmIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Toast, ToastType } from '../../components/toast';
import { QNA_QUERY } from '../../graphql/info';
import { QnaType } from '../../graphql/__generated__/globalTypes';
import {
  SearchQnas,
  SearchQnasVariables,
  SearchQnas_searchQnas_qnas,
} from '../../graphql/__generated__/SearchQnas';

export const Qna: React.FC<{}> = () => {
  const history = useHistory();

  const [qnas, setQnas] = useState<SearchQnas_searchQnas_qnas[] | null>(null);

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: '이용안내',
    message: '',
    show: false,
  });

  const [isParents, setIsParents] = useState(true);

  const { loading } = useQuery<SearchQnas, SearchQnasVariables>(QNA_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      input: { type: isParents ? QnaType.Parents : QnaType.Teacher },
    },
    onCompleted: ({
      searchQnas: { error, message, qnas: list },
    }: SearchQnas) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: '이용안내',
        message: error
          ? message || '이용안내 조회에 실패했습니다.'
          : '이용안내 조회에 성공했습니다.',
        show: true,
      });
      setQnas(list);
    },
  });

  return (
    <div className="flex flex-col px-8 py-4">
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />
      <div className="mt-4 mb-4 px-6 flex flex-row items-center">
        <div>
          <label className="font-medium text-xl">이용안내</label>
        </div>

        <div className="flex items-center ml-10">
          <label className="">선생님</label>
          <Switch
            checked={isParents}
            onChange={setIsParents}
            className={`
                      ${isParents ? 'bg-indigo-600' : 'bg-green-600'}
                      relative inline-flex flex-shrink-0 h-6 w-11 mx-2
                      border-2 border-transparent rounded-full cursor-pointer 
                      transition-colors ease-in-out duration-200 
                      focus:outline-none 
                    `}
          >
            <span
              className={`
                        ${isParents ? 'translate-x-5' : 'translate-x-0'}
                        pointer-events-none relative inline-block h-5 w-5 rounded-full 
                        bg-white shadow transform ring-0 transition ease-in-out duration-200
                      `}
            >
              <span
                className={`
                          ${
                            isParents
                              ? 'opacity-0 ease-out duration-100'
                              : 'opacity-100 ease-in duration-200'
                          }
                          absolute inset-0 h-full w-full flex items-center justify-center 
                          transition-opacity
                        `}
                aria-hidden="true"
              >
                <svg
                  className="h-3 w-3 text-indigo-600"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
              <span
                className={`
                          ${
                            isParents
                              ? 'opacity-100 ease-in duration-200'
                              : 'opacity-0 ease-out duration-100'
                          }
                          absolute inset-0 h-full w-full flex items-center justify-center 
                          transition-opacity
                        `}
                aria-hidden="true"
              >
                <svg
                  className="h-3 w-3 text-indigo-600"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
          <label className="">부모님</label>
        </div>
      </div>

      <div className="my-2">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <button
              type="button"
              disabled={loading}
              className="btn btn-purple flex-row ml-3 shadow-sm text-sm font-medium"
              onClick={() => history.push('/info/qna/create')}
            >
              <PlusSmIcon
                className="-ml-1.5 mr-1 h-5 w-5 text-purple-50"
                aria-hidden="true"
              />
              <span className="text-sm">이용안내 생성</span>
            </button>
          </div>
        </div>
      </div>

      <div className="">
        <ul className="divide-y divide-gray-50">
          {qnas?.map(item => (
            <li key={item.id} className="px-4 py-2">
              <div
                className="cursor-pointer rounded-md py-4 px-4
                ring-1 ring-gray-200
                hover:ring-2 hover:ring-purple-500 hover:ring-offset-2
              hover:bg-purple-100 truncate"
                onClick={() => history.push(`/info/qna/${item.id}`)}
              >
                <div className="flex">
                  <label
                    className={`text-xs font-light py-px px-2 rounded-full mr-3 pt-0.5
                    ${item.isHidden ? 'bg-red-300' : 'bg-green-300'}
                    `}
                  >
                    {item.isHidden ? '감추기' : '표시'}
                  </label>
                  <label
                    className="text-xs font-light border py-px px-2 rounded-full mr-3
                    border-gray-500 text-gray-500"
                  >
                    정렬 {item.order}
                  </label>
                  <label className="text-sm font-normal text-gray-800 truncate">
                    {item.question}
                  </label>
                </div>
                <div className="flex mt-4 pt-4 border-t border-gray-300">
                  <label
                    className="text-sm font-normal text-gray-800 truncate
                    whitespace-pre-wrap break-words"
                  >
                    {item.answer}
                  </label>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
