import React from 'react';
import { SpinnerSvg } from './svg/spinner';

interface IButton {
  title: string;
  disabled?: boolean;
  loading?: boolean;
}

export const Button: React.FC<IButton> = ({
  title,
  disabled = false,
  loading = false,
}: IButton) => {
  return (
    <button type="submit" disabled={disabled} className="btn btn-purple mt-4">
      {loading ? (
        <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-white" />
      ) : (
        title
      )}
    </button>
  );
};
