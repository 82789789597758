export const getFirstDate = (target: Date) => {
  return new Date(target.getFullYear(), target.getMonth(), 1);
};

export const getLastDate = (target: Date) => {
  const lastDate = new Date(
    target.getFullYear(),
    target.getMonth(),
    0
  ).getDate();
  return new Date(target.getFullYear(), target.getMonth(), lastDate);
};

export const getNextMonth = (target: Date) => {
  return new Date(target.getFullYear(), target.getMonth() + 1, 1);
};
