/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationClassType {
  Group = "Group",
  Single = "Single",
}

export enum ApplicationStatus {
  CancelSelf = "CancelSelf",
  CancelTeacher = "CancelTeacher",
  Done = "Done",
  Payment = "Payment",
  Proceeding = "Proceeding",
  Waiting = "Waiting",
  Writing = "Writing",
}

export enum DeliveryCompany {
  CJ = "CJ",
  POST = "POST",
}

export enum EnumDay {
  Fri = "Fri",
  Mon = "Mon",
  Sat = "Sat",
  Sun = "Sun",
  Thu = "Thu",
  Tue = "Tue",
  Wed = "Wed",
}

export enum EnumSex {
  Female = "Female",
  Male = "Male",
}

export enum PolicyType {
  Privacy = "Privacy",
  Terms = "Terms",
}

export enum QnaType {
  Parents = "Parents",
  Teacher = "Teacher",
}

export enum TeacherRank {
  Fruit = "Fruit",
  Premium = "Premium",
  Sprout = "Sprout",
  Tree = "Tree",
}

export enum UniversityStatus {
  Certification = "Certification",
  College = "College",
  Expected = "Expected",
  Graduation = "Graduation",
}

export enum UniversityType {
  Domestic = "Domestic",
  Overseas = "Overseas",
}

export enum UserRole {
  Admin = "Admin",
  Parents = "Parents",
  Teacher = "Teacher",
}

export interface AdminLoginInput {
  email: string;
  password: string;
}

export interface CancelPaymentInput {
  merchantUid: string;
  impUid: string;
  cancelAmount: number;
}

export interface ChangePasswordInput {
  password: string;
  newPassword: string;
}

export interface CreateDeliveryInput {
  shippingDate: string;
  trackingNumber: string;
  company: DeliveryCompany;
  userId: number;
}

export interface CreateQnaInput {
  order?: number | null;
  question?: string | null;
  answer?: string | null;
  isHidden?: boolean | null;
  type?: QnaType | null;
}

export interface CreateReportInput {
  url: string;
  reportYear: number;
  reportMonth: number;
  babyInfo: string;
  userId: number;
}

export interface CreateReviewInput {
  order: number;
  title: string;
  contents: string;
  isHidden: boolean;
}

export interface CreateUserInput {
  email: string;
  password: string;
  role: UserRole;
  name: string;
  phoneNumber: string;
}

export interface DeleteClasslogInput {
  id: number;
  teacherId?: number | null;
}

export interface DeleteDeliveryInput {
  id: number;
}

export interface DeleteQnaInput {
  id: number;
}

export interface DeleteReportInput {
  id: number;
}

export interface DeleteReviewInput {
  id: number;
}

export interface FindTeacherInput {
  id: number;
}

export interface FindUserInput {
  id?: number | null;
  email?: string | null;
}

export interface GetClasslogsInput {
  from: number;
  to: number;
  userId?: number | null;
}

export interface GetPolicyInput {
  type?: PolicyType | null;
}

export interface GetTeacherClasslogInput {
  from: number;
  to: number;
}

export interface GetTeachersInput {
  page?: number | null;
  sex?: EnumSex[] | null;
  rank?: TeacherRank[] | null;
  area?: string[] | null;
  days?: EnumDay[] | null;
  startTime?: number | null;
  endTime?: number | null;
  classTime?: number | null;
}

export interface GetTimesInput {
  id: number;
}

export interface SearchApplicationsInput {
  id?: number | null;
  status?: ApplicationStatus | null;
  reportType?: string | null;
  email?: string | null;
  page?: number | null;
  hoistActiveItems?: boolean | null;
}

export interface SearchBabiesInput {
  id?: number | null;
  isDeleted?: boolean | null;
}

export interface SearchDeliveryInput {
  id?: number | null;
  trackingNumber?: string | null;
  email?: string | null;
  page?: number | null;
}

export interface SearchPaymentsInput {
  from: number;
  to: number;
}

export interface SearchQnasInput {
  id?: number | null;
  isHidden?: boolean | null;
  type?: QnaType | null;
}

export interface SearchReportsInput {
  id?: number | null;
  reportYear?: number | null;
  reportMonth?: number | null;
  email?: string | null;
  page?: number | null;
}

export interface SearchReviewsInput {
  id?: number | null;
  isHidden?: boolean | null;
}

export interface SearchUsersInput {
  email?: string | null;
  role?: UserRole | null;
  name?: string | null;
  phoneNumber?: string | null;
  kakaoId?: string | null;
  isDeleted?: boolean | null;
  rank?: TeacherRank | null;
  page?: number | null;
}

export interface UpdateApplicationInput {
  id?: number | null;
  status?: ApplicationStatus | null;
  totalCount?: number | null;
  teacherId?: number | null;
  startDay?: EnumDay | null;
  startTime?: number | null;
  startAt?: string | null;
  address?: string | null;
  addressDetail?: string | null;
  zoneNo?: string | null;
  description?: string | null;
  cancelReason?: string | null;
  note?: string | null;
  report?: boolean | null;
}

export interface UpdateClasslogInput {
  id?: number | null;
  photo1?: string | null;
  photo2?: string | null;
  photo3?: string | null;
  photo4?: string | null;
  photo5?: string | null;
  title?: string | null;
  content?: string | null;
  classDate?: number | null;
  applicationId?: number | null;
  teacherId?: number | null;
}

export interface UpdateDeliveryInput {
  id?: number | null;
  shippingDate?: string | null;
  trackingNumber?: string | null;
  company?: DeliveryCompany | null;
  userId?: number | null;
}

export interface UpdateQnaInput {
  id: number;
  order: number;
  question: string;
  answer: string;
  isHidden: boolean;
  type: QnaType;
}

export interface UpdateReportInput {
  id?: number | null;
  url?: string | null;
  reportYear?: number | null;
  reportMonth?: number | null;
  userId: number;
}

export interface UpdateReviewInput {
  id?: number | null;
  order?: number | null;
  title?: string | null;
  contents?: string | null;
  isHidden?: boolean | null;
}

export interface UpdateTeacherInput {
  rank?: TeacherRank | null;
  photoUrl?: string | null;
  sex?: EnumSex | null;
  birthYear?: number | null;
  birthMonth?: number | null;
  birthDate?: number | null;
  universityType?: UniversityType | null;
  universityStatus?: UniversityStatus | null;
  universityName?: string | null;
  universityMajor?: string | null;
  answer?: string | null;
  certificate?: string | null;
  simpleMessage?: string | null;
  description?: string | null;
  introduction?: string | null;
  activeArea1?: string | null;
  activeArea2?: string | null;
  activeArea3?: string | null;
  tendency?: string | null;
  note?: string | null;
  userId: number;
}

export interface UpdateUserInput {
  id?: number | null;
  role?: UserRole | null;
  name?: string | null;
  phoneNumber?: string | null;
  kakaoId?: string | null;
  isVerified?: boolean | null;
  isDeleted?: boolean | null;
  reportSubscription?: boolean | null;
  address?: string | null;
  addressDetail?: string | null;
  zoneNo?: string | null;
}

export interface UpsertPolicyInput {
  type: PolicyType;
  title: string;
  content: string;
  id?: number | null;
}

export interface UpsertTimeInput {
  id?: number | null;
  day?: EnumDay | null;
  startTime?: number | null;
  endTime?: number | null;
  isDeleted?: boolean | null;
}

export interface UpsertTimeTargetInput {
  id: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
