import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import React from 'react';
import Calendar, { Detail } from 'react-calendar';
import './calendar.css';

interface IDatePicker {
  selectedDate?: string;
  className?: string;
  setDate: Function;
  setClose: Function;
}

interface INavigationLabel {
  date: Date;
  label: string;
  locale: string;
  view: Detail;
}

export const DatePicker: React.FC<IDatePicker> = ({
  selectedDate,
  className,
  setDate,
  setClose,
}) => {
  const defaultDate = selectedDate ? new Date(+selectedDate) : undefined;

  return (
    <Calendar
      value={defaultDate}
      calendarType="US"
      locale="ko"
      formatDay={(_, date: Date) => {
        return date.getDate().toString();
      }}
      formatYear={(_, date: Date) => {
        return date.getFullYear().toString();
      }}
      navigationLabel={({ date, label, locale, view }: INavigationLabel) => {
        switch (view) {
          case 'month':
            return (
              <div className="flex flex-col">
                <div className="text-xs">{date.getFullYear().toString()}</div>
                <div className="text-2xl">
                  {(date.getMonth() + 1).toString()}
                </div>
              </div>
            );
          default:
            return label.replaceAll(/[년]/gi, '');
        }
      }}
      nextLabel={
        <div className="flex justify-end pr-3">
          <ChevronRightIcon className="w-6 h-auto" />
        </div>
      }
      prevLabel={
        <div className="pl-3">
          <ChevronLeftIcon className="w-6 h-auto" />
        </div>
      }
      next2Label={null}
      prev2Label={null}
      onChange={(value: Date, _) => {
        setDate(value.getTime().toString());
        setClose();
      }}
      className={`bg-white rounded-xl border border-gray-600 shadow-sm text-sm ${className}`}
    />
  );
};
