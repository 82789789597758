import { useQuery } from '@apollo/client';
import { PlusIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastType, Toast } from '../../components/toast';
import { REVIEW_QUERY } from '../../graphql/info';
import {
  SearchReviews,
  SearchReviewsVariables,
  SearchReviews_searchReviews_reviews,
} from '../../graphql/__generated__/SearchReviews';

export const Review: React.FC<{}> = () => {
  const history = useHistory();

  const [reviews, setReviews] = useState<
    SearchReviews_searchReviews_reviews[] | null
  >(null);

  const { loading } = useQuery<SearchReviews, SearchReviewsVariables>(
    REVIEW_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { input: {} },
      onCompleted: ({
        searchReviews: { error, message, reviews: list },
      }: SearchReviews) => {
        setToast({
          type: error ? ToastType.Fail : ToastType.Success,
          title: '후기관리',
          message: error
            ? message || '후기관리 조회에 실패했습니다.'
            : '후기관리 조회에 성공했습니다.',
          show: true,
        });
        setReviews(list);
      },
    }
  );

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: '후기관리',
    message: '',
    show: false,
  });

  return (
    <div className="flex flex-col px-8 py-4">
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="mt-4 mb-4 px-6 flex flex-row items-center justify-between">
        <div>
          <label className="font-medium text-xl">후기</label>
        </div>
        <div className="flex flex-row">
          <button
            disabled={loading}
            className="btn btn-purple"
            onClick={() => history.push('/info/create-review')}
          >
            <PlusIcon className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="">
        <ul className="divide-y divide-gray-50">
          {reviews?.map(item => (
            <li key={item.id} className="px-4 py-2">
              <div
                className="cursor-pointer rounded-md py-4 px-4
                ring-1 ring-gray-200
                hover:ring-2 hover:ring-purple-500 hover:ring-offset-2
              hover:bg-purple-100 truncate"
                onClick={() => history.push(`/info/review/${item.id}`)}
              >
                <div className="flex">
                  <label
                    className={`text-xs font-light py-px px-2 rounded-full mr-3
                    ${item.isHidden ? 'bg-red-300' : 'bg-green-300'}
                    `}
                  >
                    {item.isHidden ? 'hidden' : 'visible'}
                  </label>
                  <label
                    className="text-xs font-light border py-px px-2 rounded-full mr-3
                    border-gray-500 text-gray-500"
                  >
                    정렬 {item.order}
                  </label>
                  <label className="text-sm font-normal text-gray-800 truncate">
                    {item.title}
                  </label>
                </div>
                <div className="flex mt-4 pt-4 border-t border-gray-300">
                  <label
                    className="text-sm font-normal text-gray-800 truncate
                    whitespace-pre-wrap break-words"
                  >
                    {item.contents}
                  </label>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
