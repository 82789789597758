import {atomWithHash} from "jotai/utils";
import {ApplicationStatus} from "../graphql/__generated__/globalTypes";

export type ApplicationReportType = 'all' | 'basic' | 'plus';

export interface ApplicationWhere {
  email?: string;
  status?: ApplicationStatus;
  reportType?: ApplicationReportType;
  hoistActiveItems: boolean;
}

const isPrimitive = (value: any) => {
  if (value === null) {
    return true;
  }

  return !(typeof value == 'object' || typeof value == 'function');
};

const atomWithHashOptions = {
  serialize: (value: any) => {
    if (value === undefined) {
      return '';
    }

    if (isPrimitive(value)) {
      return value;
    }

    return JSON.stringify(value);
  },
  deserialize: (value: string) => {
    if (!value) {
      return undefined;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },
};

export const whereAtom = atomWithHash<ApplicationWhere>(
  'where',
  {hoistActiveItems: false},
  atomWithHashOptions,
);