import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Toast, ToastType} from '../../components/toast';
import {
  PencilIcon,
  SearchIcon,
  DocumentReportIcon,
} from '@heroicons/react/outline';
import {SpinnerSvg} from '../../components/svg/spinner';
import {useLazyQuery} from '@apollo/client';
import {useScroll} from '../../hooks/useScroll';
import {ApplicationStatus} from '../../graphql/__generated__/globalTypes';
import {SEARCH_APPLICATIONS} from '../../graphql/application';
import {
  SearchApplications,
  SearchApplicationsVariables,
  SearchApplications_searchApplications_applications,
} from '../../graphql/__generated__/SearchApplications';
import {useAtom} from "jotai";
import {ApplicationReportType, ApplicationWhere, whereAtom} from "../../stores/application";

type searchType = 'email' | 'status';

interface ISearch {
  type?: searchType;
  search?: string | ApplicationStatus;
}

const TITLE = '신청서';

export const Application: React.FC<{}> = () => {
  const history = useHistory();

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const [applications, setApplications] = useState<SearchApplications_searchApplications_applications[]>([]);

  const [where, setWhere] = useAtom(whereAtom)
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const [searchApplications, {loading}] = useLazyQuery<SearchApplications,
    SearchApplicationsVariables>(SEARCH_APPLICATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({
                    searchApplications: {error, message, total, hasNext, applications},
                  }) => {
      setHasNext(Boolean(hasNext));
      setTotalCount(total || 0);

      if (error) {
        console.log('[ERROR] searchApplications :: ', message);
      }

      setApplications(prev =>
        page === 1 ? applications : [...prev, ...applications]
      );
    },
  });

  const {isScroll, remainToBottom} = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !loading && hasNext && isScroll) {
      setPage(prev => prev + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainToBottom]);

  const [searchObj, setSearchObj] = useState<ISearch>({type: 'status'});

  const onSearch = () => {
    const whereForSearch: ApplicationWhere = {hoistActiveItems: where.hoistActiveItems};
    const {type, search} = searchObj;
    if (type === 'email' && search) {
      whereForSearch[type] = search;
    }
    if (type === 'status' && search) {
      const [searchType, reportType] = search.split('|');
      whereForSearch[type] = searchType as ApplicationStatus;
      if (reportType) {
        whereForSearch['reportType'] = reportType as ApplicationReportType;
      }
    }
    setPage(1)
    setWhere(whereForSearch);
  };

  useEffect(() => {
    searchApplications({variables: {input: {...where, page}}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where, page]);

  return (
    <>
      <Toast {...toast} hide={() => setToast({...toast, show: false})}/>

      <div className="layer items-center">
        <div className="layer-title flex-row justify-between w-1024px">
          <div className="flex items-baseline">
            <span className="layer-title-text">신청서</span>
            <span className="text-sm">({totalCount})</span>
          </div>

          <div className="flex flex-row flex-1 w-full justify-end">
            <div className="flex flex-row ">
              <div className="flex flex-row col-span-1">
                <label className="flex items-center text-sm">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300
                                  text-indigo-600 hover:ring-indigo-500 mr-1"
                    checked={where.hoistActiveItems}
                    onChange={(e) => {
                      const value = e.target.checked

                      setWhere(prev => ({
                        ...prev,
                        hoistActiveItems: value,
                      }))
                      setPage(1)
                    }}
                  />신청완료/진행중 상태 우선 정렬</label>
              </div>
              <div className="flex flex-row col-span-1 ml-4">
                <select
                  className="block shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={e => {
                    const type = e.target.value as searchType;
                    setSearchObj({type});
                  }}
                >
                  <option value="status">상태</option>
                  <option value="email">Email</option>
                </select>
              </div>
              <div className="flex ml-1 w-72">
                {searchObj.type === 'email' ? (
                  <input
                    className="block rounded-md text-sm shadow-sm w-full
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                    type="text"
                    onChange={e => {
                      const email = e.target.value;
                      setSearchObj(prev => ({...prev, search: email}));
                    }}
                  />
                ) : (
                  <div className="flex flex-row w-full">
                    <select
                      className="block shadow-sm text-sm rounded-md w-full
                                border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={e => {
                        const status = e.target.value;
                        setSearchObj(prev => ({...prev, search: status}));
                      }}
                    >
                      <option value={''}>전체</option>
                      <option value={ApplicationStatus.Waiting + '|all'}>
                        신청 완료
                      </option>
                      <option value={ApplicationStatus.Waiting + '|plus'}>
                        신청 완료 - 플러스
                      </option>
                      <option value={ApplicationStatus.Waiting + '|basic'}>
                        신청 완료 - 베이직
                      </option>
                      <option value={ApplicationStatus.Writing}>작성중</option>
                      <option value={ApplicationStatus.Payment}>
                        결제 대기
                      </option>
                      <option value={ApplicationStatus.Proceeding}>
                        진행중
                      </option>
                      <option value={ApplicationStatus.CancelSelf}>
                        본인 취소
                      </option>
                      <option value={ApplicationStatus.CancelTeacher}>
                        선생님 취소
                      </option>
                      <option value={ApplicationStatus.Done}>수업 완료</option>
                    </select>
                  </div>
                )}
              </div>
              <div className="flex">
                <button
                  className="btn btn-blue ml-2"
                  type="button"
                  onClick={onSearch}
                >
                  <SearchIcon className="h-4 w-4 text-gray-100"/>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Contents */}
        <div className="pb-4 w-1024px">
          <div className="py-2 align-middle w-full">
            <div className="shadow border-b border-gray-200 rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500
                                uppercase tracking-wider"
                  >
                    Email(이름)
                  </th>

                  <th
                    scope="col"
                    className="relative px-6 py-3 text-left text-xs font-medium text-gray-500
                                uppercase tracking-wider"
                  >
                    <span className="sr-only">Action</span>
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500
                                uppercase tracking-wider"
                  >
                    진행 상태
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500
                                uppercase tracking-wider"
                  >
                    아기 이름
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500
                                uppercase tracking-wider"
                  >
                    선생님 이름
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {applications.map(application => (
                  <tr key={application.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4 flex flex-row items-center">
                          <div className="flex text-sm font-medium text-gray-900">
                            {JSON.parse(application.parentsInfo).email}
                          </div>
                          <div className="flex text-sm items-center font-light ml-1">
                            <label
                              className={`rounded-full py-px px-3 text-sm ml-1 border
                                ${(() => {
                                switch (application.status) {
                                  case ApplicationStatus.Waiting:
                                    return 'bg-blue-100 text-blue-800 border-blue-100';
                                  case ApplicationStatus.Proceeding:
                                    return 'bg-purple-100 text-purple-800 border-blue-100';
                                  default:
                                    return 'bg-white text-gray-700 border-gray-500';
                                }
                              })()}
                                `}
                            >
                              {JSON.parse(application.parentsInfo).name}
                            </label>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="flex flex-row py-4 text-right text-sm font-medium">
                      <button
                        className="btn btn-blue flex-row p-2 font-medium ml-2"
                        onClick={() =>
                          history.push(
                            `/classes/application/${application.id}`
                          )
                        }
                      >
                        <PencilIcon className="h-5 w-5"/>
                      </button>

                      {application.status === ApplicationStatus.Proceeding &&
                      application.report ? (
                        <button
                          className="btn btn-purple flex-row p-2 font-medium ml-2"
                          onClick={() =>
                            history.push('/classes/report/create', {
                              parentsId: application?.parentsId,
                              babyId: application?.babyIds[0],
                            })
                          }
                        >
                          <DocumentReportIcon className="h-5 w-5"/>
                        </button>
                      ) : (
                        <div className="w-52px ml-2"></div>
                      )}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {(() => {
                          switch (application.status) {
                            case ApplicationStatus.Writing:
                              return '작성중';
                            case ApplicationStatus.Payment:
                              return '결제 대기';
                            case ApplicationStatus.Waiting:
                              return '신청 완료';
                            case ApplicationStatus.Proceeding:
                              return '진행중';
                            case ApplicationStatus.CancelTeacher:
                              return '선생님 취소';
                            case ApplicationStatus.CancelSelf:
                              return '본인 취소';
                            case ApplicationStatus.Done:
                              return '수업 완료';
                          }
                        })()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 tracking-tight">
                        {(() => {
                          const babies = JSON.parse(application.babyInfo);
                          const names = application.babyIds.map(
                            id => babies[id].name
                          );
                          return names.join(', ');
                        })()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 tracking-tight">
                        {JSON.parse(application.teacherInfo || '{}')?.name}
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>

              {loading && hasNext && (
                <div className="flex items-center justify-center py-6 px-10 border-t border-gray-100">
                  <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-500"/>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
