/* eslint-disable react-hooks/exhaustive-deps */
import {useQuery} from '@apollo/client';
import {SearchIcon, UserAddIcon, UserIcon} from '@heroicons/react/outline';
import {PencilIcon} from '@heroicons/react/solid';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {SpinnerSvg} from '../../components/svg/spinner';
import dayjs from 'dayjs';
import {TeacherRank, UserRole} from '../../graphql/__generated__/globalTypes';
import {
  SearchUsers,
  SearchUsersVariables,
  SearchUsers_searchUsers_users,
} from '../../graphql/__generated__/SearchUsers';
import {useScroll} from '../../hooks/useScroll';
import {USER_LIST_QUERY} from '../../graphql/users';
import {useForm} from 'react-hook-form';
import {Toast, ToastType} from '../../components/toast';
import {phoneNumberFormat} from '../../utils/phone-format';
import {useLocation} from 'react-router-dom';
import {UserCreate} from './user-create';

interface IWhere {
  page: number;
  email?: string;
  name?: string;
  phoneNumber?: string;
  rank?: TeacherRank;
}

type searchType = 'email' | 'name' | 'phoneNumber' | 'rank';

interface IForm {
  type: searchType;
  search?: string | TeacherRank;
}

export const UserList: React.FC<{}> = () => {
  const history = useHistory();

  const location = useLocation();
  const path = location.pathname.split('/');
  const pathRole = path[path.length - 1];
  const targetRole = (() => {
    switch (pathRole) {
      case 'parents':
        return UserRole.Parents;
      case 'teacher':
        return UserRole.Teacher;
      case 'admin':
        return UserRole.Admin;
    }
  })();

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: '사용자 목록 조회',
    message: '',
    show: false,
  });

  const [users, setUsers] = useState<SearchUsers_searchUsers_users[]>([]);
  const [where, setWhere] = useState<IWhere>({page: 1});
  const [next, setNext] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const {loading} = useQuery<SearchUsers, SearchUsersVariables>(
    USER_LIST_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        input: {...where, role: targetRole},
      },
      onCompleted: ({
                      searchUsers: {error, message, hasNext, total, users: searchedUsers},
                    }: SearchUsers) => {
        setNext(Boolean(hasNext));
        setTotalCount(total || 0);

        switch (true) {
          case where.page === 1:
            setToast({
              type: error ? ToastType.Fail : ToastType.Success,
              title: '사용자 목록 조회',
              message: error
                ? message || '사용자 목록 조회에 실패했습니다.'
                : '사용자 목록 조회에 성공했습니다.',
              show: true,
            });
            setUsers(searchedUsers);
            break;
          default:
            setUsers(prev => [...prev, ...searchedUsers]);
            break;
        }
      },
    }
  );

  const {isScroll, remainToBottom} = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !loading && next && isScroll) {
      setWhere(prev => {
        return {...prev, page: prev.page + 1};
      });
    }
  }, [remainToBottom]);

  const {register, getValues, handleSubmit, watch} = useForm<IForm>();
  const onSearch = () => {
    const {type, search} = getValues();
    const where: IWhere = {page: 1};
    if (search && type !== 'rank') where[type] = search;
    if (type === 'rank' && search !== '') {
      where['rank'] = search as TeacherRank;
    } else {
      delete where.rank;
    }
    setWhere(where);
  };

  const [openCreatePage, setOpenCreatePage] = useState(false);

  return (
    <>
      <Toast {...toast} hide={() => setToast({...toast, show: false})}/>
      <UserCreate
        open={openCreatePage}
        setOpen={setOpenCreatePage}
        setToast={setToast}
      />

      <div className="flex flex-col px-8 py-4">
        <div className="-my-2 overflow-x-auto -mx-8">
          <div className="py-2 align-middle flex flex-col items-center min-w-full px-8">
            <div className="mt-4 mb-4 px-6 flex flex-row items-center justify-between w-1024px">
              <div className="flex flex-row items-baseline">
                <label className="font-medium text-xl">
                  {(() => {
                    switch (true) {
                      case path.includes('parents'):
                        return '부모님';
                      case path.includes('teacher'):
                        return '선생님';
                      case path.includes('admin'):
                        return '관리자';
                    }
                  })()}{' '}
                  목록
                </label>
                <label className="ml-2 font-normal text-sm">
                  ({totalCount})
                </label>

                <div className="flex mx-2">
                  <button
                    className="btn btn-blue"
                    onClick={() => setOpenCreatePage(true)}
                  >
                    <UserAddIcon className="w-4 h-4"/>
                  </button>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <form
                  className="flex flex-row"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <div className="flex ">
                    <select
                      className="block w-full shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      {...register('type')}
                    >
                      <option value="email">Email</option>
                      <option value="name">이름</option>
                      <option value="phoneNumber">휴대폰번호</option>
                      {pathRole === 'teacher' && (
                        <option value="rank">등급</option>
                      )}
                    </select>
                  </div>
                  <div className="flex ml-1 w-64">
                    {watch('type') === 'rank' ? (
                      <div className="flex flex-1">
                        <select
                          className="block w-full shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          {...register('search')}
                        >
                          <option value={''}>전체</option>
                          <option value={TeacherRank.Sprout}>새싹</option>
                          <option value={TeacherRank.Tree}>나무</option>
                          <option value={TeacherRank.Fruit}>열매</option>
                          <option value={TeacherRank.Premium}>프리미엄</option>
                        </select>
                      </div>
                    ) : (
                      <input
                        className="flex-1 block rounded-md text-sm shadow-sm w-full
                    border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        type="text"
                        {...register('search')}
                      />
                    )}
                  </div>
                  <div className="flex">
                    <button className="btn btn-blue ml-2 p-2 ">
                      <SearchIcon className="h-6 w-6 text-gray-100"/>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="shadow border-b border-gray-200 rounded-lg">
              <table className="w-1024px divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500
                    uppercase tracking-wider"
                  >
                    Email(이름)
                  </th>
                  <th
                    scope="col"
                    className="relative px-6 py-3 text-left text-xs font-medium text-gray-500
                    uppercase tracking-wider"
                  >
                    <span className="sr-only">Action</span>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500
                    uppercase tracking-wider"
                  >
                    타입
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500
                    uppercase tracking-wider"
                  >
                    등급
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500
                    uppercase tracking-wider"
                  >
                    휴대폰 번호
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500
                    uppercase tracking-wider"
                  >
                    가입일(탈퇴일)
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {users.map(user => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center">
                          {user.teacher?.photoUrl ? (
                            <img
                              className="h-10 w-10 rounded-full"
                              src={user.teacher?.photoUrl}
                              alt=""
                            />
                          ) : (
                            <UserIcon className="h-8 w-8 rounded-full text-gray-500"/>
                          )}
                        </div>
                        <div className="ml-4">
                          <div className="flex text-sm font-medium text-gray-900">
                            {user.email}
                          </div>
                          <div className="flex text-sm items-center font-light mt-1">
                            <label className="mr-2">{user.name}</label>
                            <div
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                            ${
                                user.isDeleted
                                  ? 'bg-pink-100 text-pink-800'
                                  : 'bg-green-100 text-green-800'
                              }`}
                            >
                              {user.isDeleted ? '삭제됨' : '활동중'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="py-4 text-right text-sm font-medium">
                      <button
                        className="btn btn-purple flex-row p-2 font-medium "
                        onClick={() => history.push(`/users/${user.id}`)}
                      >
                        <PencilIcon className="h-5 w-5"/>
                      </button>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {(() => {
                        switch (user.role) {
                          case UserRole.Parents:
                            return '부모님';
                          case UserRole.Teacher:
                            return '선생님';
                          case UserRole.Admin:
                            return '관리자';
                        }
                      })()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {(() => {
                        switch (user.teacher?.rank) {
                          case TeacherRank.Sprout:
                            return '새싹';
                          case TeacherRank.Tree:
                            return '나무';
                          case TeacherRank.Fruit:
                          case TeacherRank.Premium:
                            return '열매';
                          default:
                            return '-';
                        }
                      })()}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {phoneNumberFormat(user.phoneNumber)}
                      </div>
                      <div
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                          ${
                          user.isVerified
                            ? 'bg-green-100 text-green-800'
                            : 'bg-pink-100 text-pink-800'
                        }`}
                      >
                        {user.isVerified ? '인증' : '미인증'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {dayjs(+user.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                      </div>
                      <div className="text-sm text-gray-500">
                        {user.isDeleted
                          ? dayjs(+user.deletedAt).format(
                            'DD/MM/YYYY HH:mm:ss'
                          )
                          : '-'}
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>

              {loading && next && (
                <div className="flex items-center justify-center py-6 px-10 border-t border-gray-100">
                  <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-500"/>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
