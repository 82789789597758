import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/solid';
import { SearchIcon } from '@heroicons/react/outline';
import { Toast, ToastType } from '../../components/toast';
import { useQuery } from '@apollo/client';
import {
  SearchDelivery,
  SearchDeliveryVariables,
  SearchDelivery_searchDelivery_deliveries,
} from '../../graphql/__generated__/SearchDelivery';
import { DELIVERY_QUERY } from '../../graphql/delivery';
import { phoneNumberFormat } from '../../utils/phone-format';
import { DeliveryCompany } from '../../graphql/__generated__/globalTypes';
import dayjs from 'dayjs';
import { useScroll } from '../../hooks/useScroll';
import { useForm } from 'react-hook-form';

interface IWhere {
  page: number;
  trackingNumber?: string;
  email?: string;
}

type searchType = 'email' | 'trackingNumber';
interface IForm {
  type: searchType;
  search?: string;
}

const TITLE = '택배정보';
export const Delivery: React.FC<{}> = () => {
  const history = useHistory();

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const [deliveries, setDeliveries] = useState<
    SearchDelivery_searchDelivery_deliveries[]
  >([]);
  const [where, setWhere] = useState<IWhere>({ page: 1 });
  const [next, setNext] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const { loading } = useQuery<SearchDelivery, SearchDeliveryVariables>(
    DELIVERY_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { input: { ...where } },
      onCompleted: ({
        searchDelivery: { error, message, deliveries: list, hasNext, total },
      }: SearchDelivery) => {
        setNext(Boolean(hasNext));
        setTotalCount(total || 0);

        switch (true) {
          case where.page === 1:
            setToast({
              type: error ? ToastType.Fail : ToastType.Success,
              title: TITLE,
              message: error
                ? message || `${TITLE} 조회에 실패했습니다.`
                : `${TITLE} 조회에 성공했습니다.`,
              show: true,
            });
            setDeliveries(list);
            break;
          default:
            setDeliveries(prev => [...prev, ...list]);
            break;
        }
      },
    }
  );

  const { isScroll, remainToBottom } = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !loading && next && isScroll) {
      setWhere(prev => {
        return { ...prev, page: prev.page + 1 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainToBottom]);

  const { register, getValues, handleSubmit } = useForm<IForm>();
  const onSearch = () => {
    const { type, search } = getValues();
    const where: IWhere = { page: 1 };
    if (search) where[type] = search;
    setWhere(where);
  };

  return (
    <>
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="layer">
        <div className="layer-title flex-row justify-between">
          <div className="flex items-center">
            <span className="layer-title-text">택배 배송</span>
            <span className="text-sm">({totalCount})</span>
          </div>
          <div className="flex flex-row flex-1 w-full justify-center">
            <form className="flex flex-row " onSubmit={handleSubmit(onSearch)}>
              <div className="flex flex-row col-span-1">
                <select
                  className="block shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  {...register('type')}
                >
                  <option value="email">Email</option>
                  <option value="trackingNumber">운송장번호</option>
                </select>
              </div>
              <div className="flex ml-1 w-60">
                <input
                  className="block rounded-md text-sm shadow-sm w-full
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  type="text"
                  {...register('search')}
                />
              </div>
              <div className="flex">
                <button className="btn btn-blue ml-2">
                  <SearchIcon className="h-4 w-4 text-gray-100" />
                </button>
              </div>
            </form>
          </div>
          <div className="flex">
            <button
              className="btn btn-purple"
              onClick={() => history.push('/classes/create-delivery')}
            >
              <PlusIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        <div className="py-4 border-t border-gray-300">
          <div className="flex flex-col">
            {deliveries?.map(
              ({
                id,
                trackingNumber,
                company,
                shippingDate,
                user: { email, name, phoneNumber, kakaoId },
              }) => (
                <div
                  key={id}
                  className="flex flex-col border rounded-md p-4 my-2 cursor-pointer
                          hover:ring-2 hover:ring-offset-2 hover:ring-purple-500"
                  onClick={() => history.push(`/classes/delivery/${id}`)}
                >
                  <div className="grid grid-cols-6 grid-rows-1">
                    <>
                      <div className="">Email(이름)</div>
                      <div className="col-span-2">
                        {email}
                        <span className="rounded-full bg-blue-100 text-blue-800 py-px px-3 text-sm ml-1">
                          {name}
                        </span>
                      </div>
                    </>
                    <>
                      <div className="">운송장번호</div>
                      <div className="col-span-2">{trackingNumber}</div>
                    </>
                  </div>
                  <div className="grid grid-cols-6 grid-rows-1 mt-4">
                    <>
                      <div className="">휴대폰번호(카카오톡 ID)</div>
                      <div className="col-span-2">
                        {phoneNumberFormat(phoneNumber)}
                        {` `}
                        {kakaoId && (
                          <span className="rounded-full bg-green-100 text-green-800 py-px px-3 text-sm ml-1">
                            {kakaoId}
                          </span>
                        )}
                      </div>
                    </>

                    <>
                      <div className="">택배회사(날짜)</div>
                      <div className="col-span-2">
                        {company === DeliveryCompany.CJ
                          ? 'CJ대한통운'
                          : '우체국택배'}
                        ({dayjs(+shippingDate).format('YYYY-MM-DD')})
                      </div>
                    </>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};
