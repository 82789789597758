import React, { useEffect, useState } from 'react';
import { Toast, ToastType } from '../../components/toast';
import { SearchIcon } from '@heroicons/react/outline';
import { useLazyQuery } from '@apollo/client';
import { MonthPicker } from '../../components/date-picker/month-picker';
import { YearPicker } from '../../components/date-picker/year-picker';
import { SEARCH_PAYMENTS } from '../../graphql/payment';
import {
  SearchPayments,
  SearchPaymentsVariables,
  SearchPayments_searchPayments_payments,
} from '../../graphql/__generated__/SearchPayments';
import { getNextMonth } from '../../utils/get-date';
import dayjs from 'dayjs';
import { ReceiptTaxIcon } from '@heroicons/react/solid';

const TITLE = '결제내역';
export const Payment: React.FC<{}> = () => {
  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const [payments, setPayments] = useState<
    SearchPayments_searchPayments_payments[]
  >([]);

  const [searchPayments] = useLazyQuery<
    SearchPayments,
    SearchPaymentsVariables
  >(SEARCH_PAYMENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ searchPayments: { error, message, payments } }) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 조회에 실패했습니다.`
          : `${TITLE} 조회에 성공했습니다.`,
        show: true,
      });
      setPayments(payments);
    },
  });

  const now = new Date();
  const [searchDate, setSearchDate] = useState<{ year: number; month: number }>(
    { year: now.getFullYear(), month: now.getMonth() + 1 }
  );

  const onSearch = () => {
    const { year, month } = searchDate;
    const from = new Date(year, month - 1, 1);
    const to = getNextMonth(from);

    searchPayments({
      variables: { input: { from: from.getTime(), to: to.getTime() } },
    });
  };

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="layer items-center">
        <div className="layer-title flex-row justify-between w-1024px">
          <div className="flex">
            <span className="layer-title-text">결제 내역</span>
          </div>

          <div className="flex flex-row justify-center">
            <div className="flex flex-row ">
              <div className="flex ml-2 w-72">
                <div className="flex flex-row -mt-1 w-full">
                  <YearPicker
                    value={searchDate.year}
                    onChangeValue={(value: number) => {
                      setSearchDate(prev => ({ ...prev, year: value }));
                    }}
                  />
                  <MonthPicker
                    value={searchDate.month}
                    onChangeValue={(value: number) => {
                      setSearchDate(prev => ({ ...prev, month: value }));
                    }}
                  />
                </div>
              </div>
              <div className="flex">
                <button
                  className="btn btn-blue ml-2"
                  type="button"
                  onClick={onSearch}
                >
                  <SearchIcon className="h-4 w-4 text-gray-100" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Contents */}
        <div className="pb-12 w-1024px">
          <div className="flex align-middle w-full">
            <div className="flex w-full">
              {payments.length > 0 ? (
                <div className="flex flex-col w-full gap-y-3">
                  <div className="flex flex-row w-full">
                    <div className="flex w-2/12"></div>
                    <div className="flex w-2/12 text-lg justify-center text-center">
                      총 합
                    </div>
                    <div className="flex w-2/12 text-lg font-semibold justify-center text-center">
                      {(() => {
                        return payments
                          .reduce((prev, curr) => {
                            return prev + (curr.paidAmount - curr.cancelAmount);
                          }, 0)
                          .toLocaleString();
                      })()}{' '}
                      원
                    </div>
                    <div className="flex w-2/12"></div>
                    <div className="flex w-2/12"></div>
                  </div>

                  <div className="flex flex-row items-center w-full mb-2 bg-gray-100 py-2">
                    <div className="flex w-2/12 justify-center text-center">
                      날짜
                    </div>
                    <div className="flex w-2/12 justify-center text-center">
                      결제 방법
                    </div>
                    <div className="flex w-2/12 justify-center text-center">
                      합계
                    </div>
                    <div className="flex w-2/12 justify-center text-center">
                      결제 금액
                    </div>
                    <div className="flex w-2/12 justify-center text-center">
                      취소 금액
                    </div>
                    <div className="flex w-2/12 justify-center text-center">
                      영수증
                    </div>
                  </div>
                  {payments.map((payment, idx) => (
                    <div
                      key={idx}
                      className="flex flex-row items-center w-full"
                    >
                      <div className="flex w-2/12 justify-center text-center">
                        {dayjs(+payment.paidAt * 1000).format('YYYY-MM-DD')}
                      </div>
                      <div className="flex w-2/12 justify-center text-center">
                        {(() => {
                          switch (payment.pgProvider) {
                            case 'uplus':
                              return '카드';
                            case 'kakaopay':
                              return '카카오페이';
                            default:
                              return '네이버페이';
                          }
                        })()}
                      </div>
                      <div className="flex w-2/12 justify-center text-center">
                        {(
                          payment.paidAmount - payment.cancelAmount
                        ).toLocaleString()}{' '}
                        원
                      </div>
                      <div className="flex w-2/12 justify-center text-center">
                        {payment.paidAmount.toLocaleString()} 원
                      </div>
                      <div className="flex w-2/12 justify-center text-center">
                        {payment.cancelAmount.toLocaleString()} 원
                      </div>
                      <div className="flex w-2/12 justify-center text-center">
                        <button
                          type="button"
                          className="btn btn-white"
                          onClick={() =>
                            window.open(`${payment.receiptUrl}`, '_blank')
                          }
                        >
                          <ReceiptTaxIcon className="w-5 h-5" />
                        </button>
                      </div>
                      {/* <div className="flex w-2/12 justify-center text-center">
                        {payment.impUid}
                      </div> */}
                      <div className="flex sr-only">{payment.merchantUid}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col w-full items-center py-20">
                  <label className="text-lg">아직 결제내역이 없습니다.</label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
