import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Toast, ToastType } from '../../components/toast';
import {
  REVIEW_CREATE_MUTATION,
  REVIEW_DELETE_MUTATION,
  REVIEW_QUERY,
  REVIEW_UPDATE_MUTATION,
} from '../../graphql/info';
import {
  CreateReview,
  CreateReviewVariables,
} from '../../graphql/__generated__/CreateReview';
import {
  DeleteReview,
  DeleteReviewVariables,
} from '../../graphql/__generated__/DeleteReview';
import {
  SearchReviews,
  SearchReviewsVariables,
} from '../../graphql/__generated__/SearchReviews';
import {
  UpdateReview,
  UpdateReviewVariables,
} from '../../graphql/__generated__/UpdateReview';
import { Modal } from '../../components/modal';

interface IReviewInput {
  id?: number;
  order: number;
  title: string;
  contents: string;
  isHidden: boolean;
}

interface IReviewDetailParams {
  id?: string;
}

const TITLE = '후기관리';

export const ReviewDetail: React.FC<{}> = () => {
  const history = useHistory();

  const { id } = useParams<IReviewDetailParams>();
  useEffect(() => {
    if (id) {
      getReview({ variables: { input: { id: +id } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const [review, setReview] = useState<IReviewInput>({
    order: 0,
    title: '',
    contents: '',
    isHidden: false,
  });
  const [errors, setErrors] = useState({
    order: false,
    title: false,
    contents: false,
    isHidden: false,
  });

  const [getReview, { loading }] = useLazyQuery<
    SearchReviews,
    SearchReviewsVariables
  >(REVIEW_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: ({
      searchReviews: { error, message, reviews },
    }: SearchReviews) => {
      const data = reviews ? reviews[0] : null;
      const fail = error || !data;

      setToast({
        type: fail ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: fail
          ? message || `${TITLE} 조회에 실패했습니다.`
          : `${TITLE} 조회에 성공했습니다.`,
        show: true,
      });

      if (data) {
        const { id, order, title, contents, isHidden } = data;
        setReview({ id, order, title, contents, isHidden });
      } else {
        setTimeout(() => {
          history.goBack();
        }, 700);
      }
    },
  });

  const [createReview, { loading: creating }] = useMutation<
    CreateReview,
    CreateReviewVariables
  >(REVIEW_CREATE_MUTATION, {
    onCompleted: ({ createReview: { error, message } }: CreateReview) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 생성에 실패했습니다.`
          : `${TITLE} 생성에 성공했습니다.`,
        show: true,
      });

      setTimeout(() => {
        history.push('/info/review');
      }, 700);
    },
  });

  const [updateReview, { loading: updating }] = useMutation<
    UpdateReview,
    UpdateReviewVariables
  >(REVIEW_UPDATE_MUTATION, {
    onCompleted: ({ updateReview: { error, message } }: UpdateReview) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 수정에 실패했습니다.`
          : `${TITLE} 수정에 성공했습니다.`,
        show: true,
      });
    },
  });

  const [deleteReview, { loading: deleting }] = useMutation<
    DeleteReview,
    DeleteReviewVariables
  >(REVIEW_DELETE_MUTATION, {
    onCompleted: ({ deleteReview: { error, message } }: DeleteReview) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 삭제에 실패했습니다.`
          : `${TITLE} 삭제에 성공했습니다.`,
        show: true,
      });

      if (!error) {
        setTimeout(() => {
          history.push('/info/review');
        }, 700);
      }
    },
  });

  const onSubmit = () => {
    if (id) {
      updateReview({ variables: { input: { ...review, id: +id } } });
    } else {
      createReview({
        variables: { input: { ...review } },
      });
    }
  };

  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col px-8 py-4">
      <Modal
        open={open}
        setOpen={setOpen}
        title="Delete Review"
        content="Do you want to delete the Review?"
        buttonTitle="Delete"
        buttonAction={() =>
          deleteReview({ variables: { input: { id: id ? +id : 0 } } })
        }
      />

      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="mt-4 mb-4 px-6 flex flex-row items-center justify-between">
        <div className="py-2 px-8 flex flex-col justify-center min-w-800 mx-auto max-w-3xl">
          <form className="space-y-5 divide-y divide-gray-200 flex flex-col">
            <div className="space-y-5 divide-y divide-gray-200 flex flex-col px-6 ">
              <div className="flex flex-col">
                <div className="flex flex-row items-center">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    후기
                  </h3>
                </div>

                <div className="mt-5 space-y-5 flex flex-col">
                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        정렬
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <div className="w-20">
                        <input
                          className={`flex-1 block rounded-md text-sm shadow-sm w-full
                        ${
                          errors.order
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                          value={review.order}
                          type="number"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setReview(prev => {
                              const order = +e.target.value;

                              setErrors(prev => {
                                return { ...prev, order: order < 0 };
                              });

                              return { ...prev, order };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center w-20 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        상태
                      </label>
                    </div>
                    <div className="flex flex-1 items-center px-4">
                      <div className="text-sm font-medium text-gray-700">
                        <select
                          className="block w-full shadow-sm text-sm rounded-md 
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          value={'' + review.isHidden}
                          onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            const value = e.target.value;
                            setReview(prev => {
                              return { ...prev, isHidden: value === 'true' };
                            });
                          }}
                        >
                          <option value="true">hidden</option>
                          <option value="false">visible</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        제목
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <input
                        className={`flex-1 block rounded-md text-sm shadow-sm w-full resize-none
                        border px-2 py-2
                        ${
                          errors.title
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                        type="text"
                        value={review.title}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value;

                          setErrors(prev => {
                            return { ...prev, title: value.length < 1 };
                          });

                          setReview(prev => {
                            return { ...prev, title: value };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        내용
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <textarea
                        className={`flex-1 block rounded-md text-sm shadow-sm w-full resize-none
                        h-56
                        ${
                          errors.contents
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                        value={review.contents}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const value = e.target.value;

                          setErrors(prev => {
                            return { ...prev, contents: value.length < 1 };
                          });

                          setReview(prev => {
                            return { ...prev, contents: value };
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex border-t mt-5 pt-5 px-6 justify-between">
              <div>
                {id && (
                  <button
                    type="button"
                    disabled={creating || loading || updating || deleting}
                    className="py-2 px-4 border rounded-md shadow-sm text-sm font-medium
                    bg-red-500 hover:bg-red-700 text-red-50
                    hover:ring-2 hover:ring-offset-2 hover:ring-red-500
                    disabled:ring-0 disabled:bg-red-300 disabled:cursor-default"
                    onClick={() => setOpen(true)}
                  >
                    삭제
                  </button>
                )}
              </div>
              <div>
                <button
                  type="button"
                  disabled={creating || updating || deleting}
                  className="py-2 px-4 border rounded-md shadow-sm text-sm font-medium
                  bg-white border-gray-300 text-gray-700
                  hover:bg-gray-300 hover:ring-2 hover:ring-offset-2 hover:ring-gray-300"
                  onClick={() => history.push('/info/review')}
                >
                  취소
                </button>
                <button
                  type="button"
                  disabled={
                    creating ||
                    loading ||
                    updating ||
                    deleting ||
                    Object.values(errors).includes(true) ||
                    Object.values(review).includes('')
                  }
                  className="ml-3 py-2 px-4 rounded-md shadow-sm text-sm font-medium 
                bg-purple-500 hover:bg-purple-700 text-purple-50
                hover:ring-2 hover:ring-offset-2 hover:ring-purple-500
                disabled:ring-0 disabled:bg-purple-300 disabled:cursor-default"
                  onClick={onSubmit}
                >
                  저장
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
