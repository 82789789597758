import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { SpinnerSvg } from '../../components/svg/spinner';
import { Toast, ToastType } from '../../components/toast';
import {
  FindUser,
  FindUserVariables,
  FindUser_findUser_user,
} from '../../graphql/__generated__/FindUser';
import { USER_QUERY } from '../../graphql/users';
import { UserBasic } from './user-basic';
import { UserParents } from './user-parents';
import { UserTeacher } from './user-teacher';
import { UserRole } from '../../graphql/__generated__/globalTypes';

interface IUserDetailParams {
  id: string;
}

export const UserDetail: React.FC<{}> = () => {
  const { id } = useParams<IUserDetailParams>();
  const history = useHistory();

  const [user, setUser] = useState<FindUser_findUser_user>();

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: '사용자 조회',
    message: '',
    show: false,
  });

  const { loading } = useQuery<FindUser, FindUserVariables>(USER_QUERY, {
    variables: { input: { id: +id } },
    onCompleted: ({
      findUser: { error, message, user: userData },
    }: FindUser) => {
      if (error) {
        setToast({
          ...toast,
          title: '사용자 조회',
          message: message || '사용자 조회에 실패했습니다.',
          show: true,
        });
        setTimeout(() => {
          history.goBack();
        }, 2000);
      } else {
        if (userData) {
          setUser(userData);
        }
      }
    },
  });

  return (
    <>
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="flex flex-col px-8 py-4">
        {loading && (
          <div className="flex items-center justify-center">
            <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-500" />
          </div>
        )}
        <div className="mt-2">
          <div className="py-2 px-8 flex flex-col justify-center mx-auto max-w-7xl">
            {(() => {
              switch (user?.role) {
                case UserRole.Parents:
                  return (
                    <div className="flex justify-center w-full">
                      <UserParents user={user} setToast={setToast} />
                    </div>
                  );
                case UserRole.Teacher:
                  return (
                    <>
                      <div className="flex flex-row w-full divide-x">
                        <div className="flex">
                          <UserBasic user={user} setToast={setToast} />
                        </div>
                        <div className="flex">
                          <UserTeacher user={user} setToast={setToast} />
                        </div>
                      </div>
                    </>
                  );
                default:
                  return <></>;
              }
            })()}
          </div>
        </div>
      </div>
    </>
  );
};
