import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { isLoggedInVar } from '../graphql/apollo';
import { AuthRouter } from '../routers/auth-router';
import { UnauthRouter } from '../routers/unauth-router';
import { getToken } from '../utils/storage';
import { SpinnerSvg } from './svg/spinner';

export const App: React.FC<{}> = () => {
  const [loading, setLoading] = useState(true);
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  useEffect(() => {
    if (getToken()) {
      isLoggedInVar(true);
    } else {
      isLoggedInVar(false);
    }
    setLoading(false);
  }, []);

  return loading ? (
    <div className="flex flex-col h-screen min-w-1000">
      <div className="flex justify-center items-center h-screen">
        <SpinnerSvg className="animate-spin -mt-10 h-10 w-10 text-gray-400" />
      </div>
    </div>
  ) : (
    <>
      <div className="flex flex-col h-screen min-w-1000">
        {isLoggedIn ? <AuthRouter /> : <UnauthRouter />}
      </div>
    </>
  );
};
