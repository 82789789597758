import { useLazyQuery, useMutation } from '@apollo/client';
import { CloudUploadIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ToastType } from '../../components/toast';
import { FIND_TEACHER, UPDATE_TEACHER } from '../../graphql/teacher';
import {
  FindTeacher,
  FindTeacherVariables,
  FindTeacher_findTeacher_teacher,
} from '../../graphql/__generated__/FindTeacher';
import { FindUser_findUser_user } from '../../graphql/__generated__/FindUser';
import {
  TeacherRank,
  EnumSex,
  UniversityStatus,
  UniversityType,
} from '../../graphql/__generated__/globalTypes';
import {
  UpdateTeacher,
  UpdateTeacherVariables,
} from '../../graphql/__generated__/UpdateTeacher';
import { uploadTeacherPhoto } from '../../utils/upload-file';
import { TeacherTime } from './teacher-time';
import { ITeacherForm } from './user.interface';

interface IUserTeacher {
  user?: FindUser_findUser_user;
  setToast: React.Dispatch<
    React.SetStateAction<{
      type: ToastType;
      title: string;
      message: string;
      show: boolean;
    }>
  >;
}

const nowYear = new Date().getFullYear();
const birthYear = Array.from(Array(40))
  .map((_, i) => nowYear - (i + 19))
  .reverse();
const birthMonth = Array.from(Array(12)).map((_, i) => ++i);
const birthDate28 = Array.from(Array(28)).map((_, i) => ++i);
const birthDate30 = Array.from(Array(30)).map((_, i) => ++i);
const birthDate31 = Array.from(Array(31)).map((_, i) => ++i);

const tendencies = [
  '사랑',
  '열정',
  '행복',
  '긍정',
  '활발',
  '따뜻',
  '도움',
  '배려',
  '보살핌',
  '꼼꼼',
  '세심',
  '이해',
  '안정',
  '믿음',
  '숙련된',
  '다정',
  '침착',
  '합리적',
  '밝은',
  '재밌는',
  '정확',
  '용감',
  '성실',
  '창의적',
  '상냥',
  '책임감',
  '리더쉽',
  '온화',
  '조심',
  '포용',
  '논리적',
  '평온',
  '차분',
  '분석',
  '주의깊은',
  '신중',
  '즐거움',
];

const defaultAnswer = [
  { question: '아이 돌봄 혹은 미술 교육 경험', answer: '' },
  { question: '한 아이를 지속적으로 만난 기간', answer: '' },
  { question: '현재 자녀가', answer: '' },
  { question: '특이사항 (추가 경력, 특이 경력)', answer: '' },
];

export const UserTeacher: React.FC<IUserTeacher> = ({ user, setToast }) => {
  const history = useHistory();
  const uploadRef = useRef<HTMLInputElement>(null);

  const [uploadUrl, setUploadUrl] = useState('');
  const [teacher, setTeacher] = useState<FindTeacher_findTeacher_teacher>();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<ITeacherForm>({
    mode: 'onChange',
  });

  const [certificate, setCertificate] = useState<string>('');
  const [certificateList, setCertificateList] = useState<string[]>([]);
  useEffect(() => {
    const stringCertificate = certificateList.join(',');
    if (stringCertificate !== getValues('teacher.certificate')) {
      setValue('teacher.certificate', stringCertificate, {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateList]);

  const [tendencyList, setTendencyList] = useState<string[]>([]);
  useEffect(() => {
    const stringTendency = tendencyList.join(',');
    if (stringTendency !== getValues('teacher.tendency')) {
      setValue('teacher.tendency', stringTendency, {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tendencyList]);

  const [answer, setAnswer] = useState([...defaultAnswer]);
  useEffect(() => {
    const stringAnswer = JSON.stringify(answer);
    if (stringAnswer !== getValues('teacher.answer')) {
      setValue('teacher.answer', stringAnswer, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  const [findTeacher, { loading }] = useLazyQuery<
    FindTeacher,
    FindTeacherVariables
  >(FIND_TEACHER, {
    onCompleted: ({
      findTeacher: { error, message, teacher: teacherData },
    }: FindTeacher) => {
      if (error) {
        setToast(prev => ({
          ...prev,
          title: '사용자 조회',
          message: message || '사용자 조회에 실패했습니다.',
          show: true,
        }));
        setTimeout(() => {
          history.goBack();
        }, 2000);
      } else {
        if (teacherData) {
          setTeacher(teacherData);

          if (teacherData && teacherData.id) {
            const teacherValues = {
              id: teacherData.id,
              photoUrl: teacherData.photoUrl,
              rank: teacherData.rank,
              sex: teacherData.sex,
              birthYear: teacherData.birthYear,
              birthMonth: teacherData.birthMonth,
              birthDate: teacherData.birthDate,
              universityType: teacherData.universityType,
              universityStatus: teacherData.universityStatus,
              universityName: teacherData.universityName,
              universityMajor: teacherData.universityMajor,
              answer: teacherData.answer,
              certificate: teacherData.certificate,
              simpleMessage: teacherData.simpleMessage,
              description: teacherData.description,
              introduction: teacherData.introduction,
              activeArea1: teacherData.activeArea1,
              activeArea2: teacherData.activeArea2,
              activeArea3: teacherData.activeArea3,
              tendency: teacherData.tendency,
              note: teacherData.note,
            };
            setValue('teacher', { ...teacherValues }, { shouldValidate: true });
            setCertificateList(
              teacherData.certificate.length > 0
                ? teacherData.certificate.split(',')
                : []
            );
            setTendencyList(
              teacherData.tendency.length > 0
                ? teacherData.tendency.split(',')
                : []
            );
            setAnswer(
              JSON.parse(teacherData.answer || JSON.stringify(defaultAnswer))
            );
            setUploadUrl(teacherData.photoUrl);
          }
        }
      }
    },
  });

  useEffect(() => {
    if (user?.id) {
      findTeacher({ variables: { input: { id: user.id } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateTeacher, { loading: updating }] = useMutation<
    UpdateTeacher,
    UpdateTeacherVariables
  >(UPDATE_TEACHER, {
    onCompleted: ({
      updateTeacher: { error, message, teacher: teacherData },
    }) => {
      if (teacherData) {
        setTeacher(teacherData);
      }

      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: '선생님 수정',
        message: error
          ? message || '선생님 수정에 실패했습니다.'
          : '선생님 수정에 성공했습니다.',
        show: true,
      });
    },
  });

  const onSubmit = () => {
    if (user?.id) {
      const { id, birthYear, birthMonth, birthDate, ...others } = {
        ...getValues().teacher,
      };
      updateTeacher({
        variables: {
          input: {
            ...others,
            birthYear: +birthYear,
            birthMonth: +birthMonth,
            birthDate: +birthDate,
            userId: user.id,
          },
        },
      });
    }
  };

  const [openTime, setOpenTime] = useState(false);

  return (
    <>
      <TeacherTime
        userId={user?.id}
        open={openTime}
        setOpen={setOpenTime}
        setToast={setToast}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-5 divide-y divide-gray-200 flex flex-col px-6 ">
          <div className="space-y-5 flex flex-col">
            <div className="flex items-baseline">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                타입 정보 - 선생님
              </h3>
              <p className="ml-4 max-w-2xl text-sm text-gray-500">
                사용자 타입 정보 관리
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <>
                <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                  <div className="flex  flex-1">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700 ">
                        등급
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <div className="flex items-center">
                        <select
                          className="block w-full shadow-sm text-sm rounded-md
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          {...register('teacher.rank')}
                        >
                          <option value={TeacherRank.Sprout}>새싹</option>
                          <option value={TeacherRank.Tree}>나무</option>
                          <option value={TeacherRank.Fruit}>열매</option>
                          <option value={TeacherRank.Premium}>프리미엄</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1">
                    <div className="flex items-center w-40 pl-4">
                      <label className="block text-sm font-medium text-gray-700">
                        성별
                      </label>
                    </div>
                    <div className="mt-0 flex flex-1">
                      <div className="flex items-center">
                        <select
                          className="block w-full shadow-sm text-sm rounded-md
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          {...register('teacher.sex')}
                        >
                          <option value={EnumSex.Female}>여</option>
                          <option value={EnumSex.Male}>남</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-center border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      생년월일
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="flex items-center">
                      <select
                        className="block w-full shadow-sm text-sm rounded-md mr-1 min-w-max
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        {...register('teacher.birthYear')}
                      >
                        {birthYear.map(v => (
                          <option key={v} value={v}>
                            {v}
                          </option>
                        ))}
                      </select>
                      년
                      <select
                        className="block w-full shadow-sm text-sm rounded-md ml-3 mr-1
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        {...register('teacher.birthMonth')}
                      >
                        {birthMonth.map(v => (
                          <option key={v} value={v}>
                            {v}
                          </option>
                        ))}
                      </select>
                      월
                      <select
                        className="block w-full shadow-sm text-sm rounded-md ml-3 mr-1
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        {...register('teacher.birthDate')}
                      >
                        {(() => {
                          let targetDate = [];
                          if (+watch('teacher.birthMonth') === 2) {
                            targetDate = birthDate28;
                          } else if (
                            [4, 5, 9, 11].includes(+watch('teacher.birthMonth'))
                          ) {
                            targetDate = birthDate30;
                          } else {
                            targetDate = birthDate31;
                          }

                          return targetDate.map(v => (
                            <option key={v} value={v}>
                              {v}
                            </option>
                          ));
                        })()}
                      </select>
                      일
                    </div>
                  </div>
                  <div className="flex items-center justify-end px-4 w-0">
                    <div className="text-sm font-medium text-gray-700"></div>
                  </div>
                </div>

                <div className="flex flex-row items-center border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      사진
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="flex items-center w-full">
                      <input
                        className={`flex-1 block rounded-md text-sm shadow-sm w-full resize-none
                        border px-2 py-2 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500
                        `}
                        type="text"
                        readOnly={true}
                        value={uploadUrl}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-end px-4 w-20">
                    <div className="text-sm font-medium text-gray-700">
                      <input
                        ref={uploadRef}
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        className="hidden"
                        onChange={async ({
                          target: { files },
                        }: ChangeEvent<HTMLInputElement>) => {
                          const file = files?.item(0);
                          if (file && user?.id) {
                            const { url } = await uploadTeacherPhoto({
                              file,
                              userId: +user?.id,
                            });
                            setValue('teacher.photoUrl', url, {
                              shouldValidate: true,
                            });
                            setUploadUrl(url);
                          }
                        }}
                      />
                      <button
                        // disabled={}
                        type="button"
                        className="rounded-md btn btn-blue"
                        onClick={() => uploadRef.current?.click()}
                      >
                        <CloudUploadIcon className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-start border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      학교 / 전공
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="flex flex-col w-full">
                      <div className="flex items-center w-full">
                        <input
                          className="flex-1 block rounded-md text-sm shadow-sm w-full
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          {...register('teacher.universityName')}
                          type="text"
                          placeholder={'학교'}
                        />
                      </div>
                      <div className="flex items-center mt-2 ">
                        <input
                          className="flex-1 block rounded-md text-sm shadow-sm w-full
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          {...register('teacher.universityMajor')}
                          type="text"
                          placeholder={'전공'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end px-4 w-40">
                    <div className="flex flex-col">
                      <div className="text-sm font-medium text-gray-700">
                        <select
                          className="block w-full shadow-sm text-sm rounded-md
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          {...register('teacher.universityType')}
                        >
                          <option value={UniversityType.Domestic}>국내</option>
                          <option value={UniversityType.Overseas}>해외</option>
                        </select>
                      </div>
                      <div className="text-sm font-medium text-gray-700 mt-2">
                        <select
                          className="block w-full shadow-sm text-sm rounded-md
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          {...register('teacher.universityStatus')}
                        >
                          <option value={UniversityStatus.College}>재학</option>
                          <option value={UniversityStatus.Certification}>
                            수료
                          </option>
                          <option value={UniversityStatus.Graduation}>
                            졸업
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-start border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      자격증
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="flex flex-col w-full">
                      <div className="flex flex-row w-full">
                        <div className="mt-0 flex flex-1">
                          <div className="flex items-center w-full">
                            <input
                              className="flex-1 block rounded-md text-sm shadow-sm w-full
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                              value={certificate}
                              onChange={e => setCertificate(e.target.value)}
                              type="text"
                              placeholder={'자격증'}
                            />
                          </div>
                        </div>
                        <div className="flex items-center justify-end px-4 w-20">
                          <div className="text-sm font-medium text-gray-700">
                            <button
                              type="button"
                              className="rounded-md btn btn-blue"
                              onClick={() => {
                                setCertificateList(prev => [
                                  ...prev,
                                  certificate,
                                ]);
                                setCertificate('');
                              }}
                            >
                              <PlusIcon className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {certificateList.map((item, idx) => (
                        <div className="flex flex-row w-full mt-2" key={idx}>
                          <div className="mt-0 flex flex-1">
                            <div className="flex items-center w-full">
                              <input
                                className="flex-1 block rounded-md text-sm shadow-sm w-full
                                border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                value={item}
                                type="text"
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <div className="flex items-center justify-end px-4 w-20">
                            <div className="text-sm font-medium text-gray-700">
                              <button
                                type="button"
                                className="rounded-md btn btn-white"
                                onClick={() => {
                                  const newCertificateList = [
                                    ...certificateList,
                                  ];
                                  const idx = newCertificateList.findIndex(
                                    v => v === item
                                  );
                                  newCertificateList.splice(idx, 1);
                                  setCertificateList(newCertificateList);
                                }}
                              >
                                <TrashIcon className="w-4 h-4" />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-start border-t border-gray-200 pt-5 h-full pb-4">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      목록 설명
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="relative flex flex-col justify-center w-full">
                      <span className="mb-1 text-xs text-red-500">
                        한줄에 16자씩 2줄이 보입니다. 공백, 한글 영어에 따라
                        달라질 수 있습니다.
                      </span>
                      <textarea
                        className="relative flex-1 block rounded-md text-sm w-236px
                      border-0 ring-1 ring-offset-2 focus:ring-indigo-500 focus:ring-offset-2"
                        {...register('teacher.simpleMessage')}
                        rows={2}
                      />

                      <div
                        className="absolute flex flex-col items-center p-1 top-12 left-64
                        justify-between"
                      >
                        <span className="text-sm text-gray-700 font-semibold w-full text-right">
                          {watch('teacher.simpleMessage')?.length || 0} / 32
                        </span>
                      </div>

                      <span className="text-xs text-red-500 mt-1">
                        아래 모습은 예시 입니다. 실제 화면은 다를 수 있습니다.
                      </span>
                      <div className="whitespace-pre-wrap h-24 sm:h-12 overflow-hidden w-236px mt-2 ring">
                        {watch('teacher.simpleMessage')}
                      </div>
                    </div>
                  </div>
                  <div className="hidden items-center justify-end px-4 w-52">
                    <div className="text-sm font-medium text-gray-700"></div>
                  </div>
                </div>

                <div className="flex flex-row items-start border-t border-gray-200 pt-5 h-full pb-4">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      간단 설명
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="relative flex flex-col justify-center w-full">
                      <span className="mb-1 text-xs text-red-500">
                        상세페이지에서 5줄까지 보입니다.
                      </span>
                      <textarea
                        className="relative flex-1 block rounded-md text-sm shadow-sm w-365px
                      border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        {...register('teacher.description')}
                        rows={5}
                      />
                      <span className="text-xs text-red-500">
                        공백, 한글 영어에 따라 달라질 수 있습니다.
                      </span>
                      <div
                        className="absolute flex flex-col items-center p-1 -bottom-3 right-0
                        justify-between"
                      >
                        <span className="text-sm text-gray-700 font-semibold w-full text-right">
                          {watch('teacher.description')?.length || 0} / 165
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="hidden items-center justify-end px-4 w-52">
                    <div className="text-sm font-medium text-gray-700"></div>
                  </div>
                </div>

                <div className="flex flex-row items-start border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      상세 설명(선생님 소개)
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="flex items-center w-full">
                      <textarea
                        className="flex-1 block rounded-md text-sm shadow-sm w-full
                      border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        {...register('teacher.introduction')}
                        rows={5}
                      />
                    </div>
                  </div>
                  <div className="hidden items-center justify-end px-4 w-52">
                    <div className="text-sm font-medium text-gray-700"></div>
                  </div>
                </div>

                <div className="flex flex-row items-start border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      성향
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1 w-full">
                    <div className="flex items-center flex-wrap">
                      {tendencies.map((tendency, idx) => (
                        <button
                          key={idx}
                          type="button"
                          className={`btn mx-2 mb-2 ${
                            tendencyList.findIndex(v => v === tendency) > -1
                              ? 'btn-purple'
                              : 'btn-white'
                          }`}
                          onClick={() => {
                            const newTendencyList = [...tendencyList];
                            const idx = newTendencyList.findIndex(
                              v => v === tendency
                            );
                            if (idx > -1) {
                              newTendencyList.splice(idx, 1);
                            } else {
                              newTendencyList.push(tendency);
                            }
                            setTendencyList(newTendencyList);
                          }}
                        >
                          {tendency}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="hidden items-center justify-end px-4 w-52">
                    <div className="text-sm font-medium text-gray-700"></div>
                  </div>
                </div>

                <div className="flex flex-row items-center border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      활동 지역
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="flex flex-col w-full">
                      <div className="flex flex-row w-full mt-2">
                        <div className="mt-0 flex flex-1">
                          <div className="flex items-center w-full">
                            <input
                              className="flex-1 block rounded-md text-sm shadow-sm w-full
                                border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                              type="text"
                              placeholder={'활동 지역 1'}
                              {...register('teacher.activeArea1')}
                            />
                            <input
                              className="flex-1 block rounded-md text-sm shadow-sm w-full mx-2
                                border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                              type="text"
                              placeholder={'활동 지역 2'}
                              {...register('teacher.activeArea2')}
                            />
                            <input
                              className="flex-1 block rounded-md text-sm shadow-sm w-full
                                border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                              type="text"
                              placeholder={'활동 지역 3'}
                              {...register('teacher.activeArea3')}
                            />
                          </div>
                        </div>
                        <div className="hidden items-center justify-end px-4 w-20">
                          <div className="text-sm font-medium text-gray-700"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-center border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      활동 시간
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="btn btn-blue"
                        onClick={() => {
                          if (teacher?.id) {
                            setOpenTime(true);
                          } else {
                            alert('선생님 정보 저장 후 등록이 가능합니다.');
                          }
                        }}
                      >
                        활동 시간 확인 / 수정
                      </button>
                    </div>
                  </div>
                  <div className="hidden items-center justify-end px-4 w-52">
                    <div className="text-sm font-medium text-gray-700"></div>
                  </div>
                </div>

                <div className="flex flex-row items-start border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      질문지
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="flex flex-col w-full">
                      {/* 1 */}
                      <div className="flex flex-row justify-between">
                        <div className="flex items-center w-full">
                          {answer[0].question}
                        </div>
                        <div className="flex">
                          <select
                            className="block w-full shadow-sm text-sm rounded-md
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            value={answer[0].answer}
                            onChange={e => {
                              const newAnswer = [...answer];
                              newAnswer[0].answer = e.target.value;
                              setAnswer(newAnswer);
                            }}
                          >
                            <option value=""></option>
                            <option value="아이 돌봄 경험">
                              아이 돌봄 경험
                            </option>
                            <option value="미술 교육 경험">
                              미술 교육 경험
                            </option>
                            <option value="아이 돌봄 / 미술 교육 모두 경험">
                              아이 돌봄 / 미술 교육 모두 경험
                            </option>
                            <option value="경험 없음">경험 없음</option>
                          </select>
                        </div>
                      </div>
                      {/* 2 */}
                      <div className="flex flex-row justify-between mt-2">
                        <div className="flex items-center">
                          {answer[1].question}
                        </div>
                        <div className="flex">
                          <select
                            className="block w-full shadow-sm text-sm rounded-md
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            value={answer[1].answer}
                            onChange={e => {
                              const newAnswer = [...answer];
                              newAnswer[1].answer = e.target.value;
                              setAnswer(newAnswer);
                            }}
                          >
                            <option value=""></option>
                            <option value="1년 미만">1년 미만</option>
                            <option value="1년 이상 2년 미만">
                              1년 이상 2년 미만
                            </option>
                            <option value="2년 이상 3년 미만">
                              2년 이상 3년 미만
                            </option>
                            <option value="3년 이상 4년 미만">
                              3년 이상 4년 미만
                            </option>
                            <option value="4년 이상">4년 이상</option>
                            <option value="경험 없음">경험 없음</option>
                          </select>
                        </div>
                      </div>
                      {/* 3 */}
                      <div className="flex flex-row justify-between mt-2">
                        <div className="flex items-center">
                          {answer[2].question}
                        </div>
                        <div className="flex">
                          <select
                            className="block w-full shadow-sm text-sm rounded-md
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            value={answer[2].answer}
                            onChange={e => {
                              const newAnswer = [...answer];
                              newAnswer[2].answer = e.target.value;
                              setAnswer(newAnswer);
                            }}
                          >
                            <option value=""></option>
                            <option value="있어요">있어요</option>
                            <option value="없어요">없어요</option>
                          </select>
                        </div>
                      </div>
                      {/* 4 */}
                      <div className="flex flex-row justify-between mt-2">
                        <div className="flex items-center w-full">
                          <input
                            className={`flex-1 block rounded-md text-sm shadow-sm w-full resize-none
                          border px-2 py-2 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500
                          `}
                            type="text"
                            placeholder="특이사항 (추가 경력, 특이 경력)"
                            value={answer[3].answer}
                            onChange={e => {
                              const newAnswer = [...answer];
                              newAnswer[3].answer = e.target.value;
                              setAnswer(newAnswer);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hidden items-center justify-end px-4 w-52">
                    <div className="text-sm font-medium text-gray-700"></div>
                  </div>
                </div>

                <div className="flex flex-row items-start border-t border-gray-200 pt-5 h-full">
                  <div className="flex items-center w-40 pl-4">
                    <label className="block text-sm font-medium text-gray-700 ">
                      노트
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1">
                    <div className="flex items-center w-full">
                      <textarea
                        className="flex-1 block rounded-md text-sm shadow-sm w-full
                      border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        {...register('teacher.note')}
                        rows={5}
                      />
                    </div>
                  </div>
                  <div className="hidden items-center justify-end px-4 w-52">
                    <div className="text-sm font-medium text-gray-700"></div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>

        <div className="flex border-t mt-5 pt-5 px-6 justify-end">
          <button
            type="button"
            disabled={loading || updating}
            className="btn btn-white shadow-sm text-sm font-medium"
            onClick={() => history.goBack()}
          >
            취소
          </button>
          <button
            type="submit"
            disabled={!user?.id || !isValid || loading || updating}
            className="btn btn-purple ml-3 shadow-sm text-sm font-medium"
          >
            저장
          </button>
        </div>
      </form>
    </>
  );
};
