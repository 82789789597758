import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from '../utils/storage';

const TARGET_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/graphql'
    : `https://api.${process.env.REACT_APP_WEBSITE}/graphql`;

const httpLink = createHttpLink({
  uri: TARGET_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      'x-jwt': token ? token : '',
    },
  };
});

export const isLoggedInVar = makeVar(false);

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedIn: {
            read() {
              return isLoggedInVar();
            },
          },
        },
      },
    },
  }),
});
