import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../components/button';
import { Toast, ToastType } from '../components/toast';
import { EXPORT_PARENTS, EXPORT_TEACHER } from '../graphql/export';
import { ADMIN_MUTATION } from '../graphql/users';
import {
  ChangePassword,
  ChangePasswordVariables,
} from '../graphql/__generated__/ChangePassword';
import { ExportParents } from '../graphql/__generated__/ExportParents';
import { ExportTeacher } from '../graphql/__generated__/ExportTeacher';
import { parentDataToExcel, teacherDataToExcel } from '../utils/export-xlsx';

interface IForm {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export const Home: React.FC<{}> = () => {
  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: '비밀번호 변경',
    message: '',
    show: false,
  });
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { errors, isValid },
  } = useForm<IForm>({ mode: 'onChange' });

  const onCompleted = ({
    changePassword: { error, message },
  }: ChangePassword) => {
    reset();
    setToast({
      type: error ? ToastType.Fail : ToastType.Success,
      title: '비밀번호 변경',
      message: error
        ? message || '비밀번호 변경에 실패했습니다.'
        : '비밀번호 변경에 성공했습니다.',
      show: true,
    });
  };

  const [changePassword, { loading: changing }] = useMutation<
    ChangePassword,
    ChangePasswordVariables
  >(ADMIN_MUTATION, { onCompleted });

  const onSubmit = () => {
    if (!changing) {
      const { password, newPassword, confirmPassword } = getValues();
      if (newPassword !== confirmPassword) {
        setError('confirmPassword', {
          type: 'manual',
          message: 'Not match',
        });
        return;
      }
      changePassword({ variables: { input: { password, newPassword } } });
    }
  };

  const [exportParents, { loading: exporting }] = useLazyQuery<ExportParents>(
    EXPORT_PARENTS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ exportParents: { error, message, users } }) => {
        setToast({
          type: error ? ToastType.Fail : ToastType.Success,
          title: '데이터 다운로드',
          message: error
            ? '데이터 다운로드에 실패했습니다.'
            : '데이터 다운로드에 성공했습니다.',
          show: true,
        });

        if (error) {
          console.log('[ERROR] exportParents ::: ', message);
        } else {
          parentDataToExcel({ users });
        }
      },
    }
  );

  const [exportTeacher, { loading: exporting2 }] = useLazyQuery<ExportTeacher>(
    EXPORT_TEACHER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ exportTeacher: { error, message, users } }) => {
        setToast({
          type: error ? ToastType.Fail : ToastType.Success,
          title: '데이터 다운로드',
          message: error
            ? '데이터 다운로드에 실패했습니다.'
            : '데이터 다운로드에 성공했습니다.',
          show: true,
        });

        if (error) {
          console.log('[ERROR] exportTeacher ::: ', message);
        } else {
          teacherDataToExcel({ users });
        }
      },
    }
  );

  return (
    <>
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="h-screen flex flex-row px-10 py-10">
        <div className="flex flex-row flex-1 justify-center items-center h-full border-r mr-10 pr-10">
          <div className="flex flex-col w-full max-w-md min-w-min justify-center items-center">
            <label className="text-4xl font-semibold mb-5 text-gray-900">
              비밀번호 변경
            </label>

            <form
              className="w-full flex flex-col px-5 py-7 border border-purple-300 rounded-md"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col w-full h-20">
                <span className="text-sm text-gray-600 font-light mb-1">
                  Current Password
                </span>
                <input
                  className={`w-full py-3 px-2 rounded-md
              border-0 focus:border-0 ring-2 hover:ring-2 focus:ring-2
              ${
                Object.keys(errors).includes('password')
                  ? 'ring-red-300 hover:ring-red-500 focus:ring-red-500'
                  : 'ring-purple-300 hover:ring-purple-500 focus:ring-purple-500'
              }
              `}
                  {...register('password', { required: true })}
                  type="password"
                  required
                />
              </div>
              <div className="flex flex-col w-full h-20">
                <span className="text-sm text-gray-600 font-light mb-1">
                  New Password
                </span>
                <input
                  className={`w-full py-3 px-2 rounded-md
              border-0 focus:border-0 ring-2 hover:ring-2 focus:ring-2
              ${
                Object.keys(errors).includes('newPassword')
                  ? 'ring-red-300 hover:ring-red-500 focus:ring-red-500'
                  : 'ring-purple-300 hover:ring-purple-500 focus:ring-purple-500'
              }
              `}
                  {...register('newPassword', { required: true })}
                  type="password"
                  required
                />
              </div>
              <div className="flex flex-col w-full h-20">
                <span className="text-sm text-gray-600 font-light mb-1">
                  Confirm Password
                </span>
                <input
                  className={`w-full py-3 px-2 rounded-md
              border-0 focus:border-0 ring-2 hover:ring-2 focus:ring-2
              ${
                Object.keys(errors).includes('confirmPassword')
                  ? 'ring-red-300 hover:ring-red-500 focus:ring-red-500'
                  : 'ring-purple-300 hover:ring-purple-500 focus:ring-purple-500'
              }
              `}
                  {...register('confirmPassword', {
                    required: true,
                    validate: v => v === getValues('newPassword'),
                  })}
                  type="password"
                  required
                />
              </div>
              <Button
                title="Change Password"
                disabled={!isValid}
                loading={changing}
              />
            </form>
          </div>
        </div>

        <div className="flex flex-col space-y-3">
          <div className="px-6">
            <button
              disabled={changing || exporting || exporting2}
              className="btn btn-blue"
              onClick={() => exportParents()}
            >
              부모님(아이포함) 데이터 다운로드
            </button>
          </div>
          <div className="px-6">
            <button
              disabled={changing || exporting || exporting2}
              className="btn btn-blue"
              onClick={() => exportTeacher()}
            >
              선생님 데이터 다운로드
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
