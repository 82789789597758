import gql from 'graphql-tag';
import { QNA_INFO, REVIEW_INFO } from './fragments';

export const POLICY_QUERY = gql`
  query GetPolicy($input: GetPolicyInput!) {
    getPolicy(input: $input) {
      error
      message
      policy {
        id
        type
        title
        content
      }
    }
  }
`;

export const POLICY_MUTATION = gql`
  mutation UpsertPolicy($input: UpsertPolicyInput!) {
    upsertPolicy(input: $input) {
      error
      message
    }
  }
`;

export const QNA_QUERY = gql`
  query SearchQnas($input: SearchQnasInput!) {
    searchQnas(input: $input) {
      error
      message
      qnas {
        ...QnaInfo
      }
    }
  }
  ${QNA_INFO}
`;

export const QNA_UPDATE_MUTATION = gql`
  mutation UpdateQna($input: UpdateQnaInput!) {
    updateQna(input: $input) {
      error
      message
      qna {
        ...QnaInfo
      }
    }
  }
  ${QNA_INFO}
`;

export const QNA_CREATE_MUTATION = gql`
  mutation CreateQna($input: CreateQnaInput!) {
    createQna(input: $input) {
      error
      message
      qna {
        ...QnaInfo
      }
    }
  }
  ${QNA_INFO}
`;

export const QNA_DELETE_MUTATION = gql`
  mutation DeleteQna($input: DeleteQnaInput!) {
    deleteQna(input: $input) {
      error
      message
    }
  }
`;

export const REVIEW_QUERY = gql`
  query SearchReviews($input: SearchReviewsInput!) {
    searchReviews(input: $input) {
      error
      message
      reviews {
        ...ReviewInfo
      }
    }
  }
  ${REVIEW_INFO}
`;

export const REVIEW_CREATE_MUTATION = gql`
  mutation CreateReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      error
      message
    }
  }
`;

export const REVIEW_UPDATE_MUTATION = gql`
  mutation UpdateReview($input: UpdateReviewInput!) {
    updateReview(input: $input) {
      error
      message
      review {
        ...ReviewInfo
      }
    }
  }
  ${REVIEW_INFO}
`;

export const REVIEW_DELETE_MUTATION = gql`
  mutation DeleteReview($input: DeleteReviewInput!) {
    deleteReview(input: $input) {
      error
      message
    }
  }
`;
