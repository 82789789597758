import { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';

export enum ToastType {
  Success = 'Success',
  Fail = 'Fail',
}

type ToastTypes = keyof typeof ToastType;

export interface IToast {
  type: ToastTypes;
  title: string;
  message: string;
  show: boolean;
  hide: Function;
}

export const Toast: React.FC<IToast> = ({
  type,
  title,
  message,
  show,
  hide,
}: IToast) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        if (show) {
          hide();
        }
      }, 2 * 1000);
    }
  });

  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-start p-6 pointer-events-none z-50"
      >
        <div className="w-full flex flex-col space-y-4 items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="opacity-0 translate-y-0 translate-x-2"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`max-w-sm w-full shadow-lg rounded-lg pointer-events-auto 
                         ring-1 ring-green-300 ring-opacity-5 overflow-hidden
                         ${
                           type === ToastType.Success
                             ? 'bg-green-200'
                             : 'bg-red-300'
                         }
                        `}
            >
              <div className="p-4">
                <div className="flex items-start ">
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-black whitespace-pre-wrap">
                      {message}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-transparent rounded-md inline-flex text-gray-500 
                                hover:text-gray-600 focus:outline-none"
                      onClick={() => hide()}
                    >
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};
