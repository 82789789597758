import gql from 'graphql-tag';

export const GET_CLASSLOGS = gql`
  query GetClasslogs($input: GetClasslogsInput!) {
    getClasslogs(input: $input) {
      error
      message
      classlogs {
        id
        photo1
        photo2
        photo3
        photo4
        photo5
        content
        classDate
        teacherId
      }
    }
  }
`;

export const DELETE_CLASSLOG = gql`
  mutation DeleteClasslog($input: DeleteClasslogInput!) {
    deleteClasslog(input: $input) {
      error
      message
      id
    }
  }
`;

export const UPDATE_CLASSLOG = gql`
  mutation UpdateClasslog($input: UpdateClasslogInput!) {
    updateClasslog(input: $input) {
      error
      message
    }
  }
`;

export const GET_TEACHER_CLASSLOG = gql`
  query GetTeacherClasslog($input: GetTeacherClasslogInput!) {
    getTeacherClasslog(input: $input) {
      error
      message
      classlogs {
        id
        classDate
        teacherId
      }
      teachers {
        id
        email
        name
        phoneNumber
      }
    }
  }
`;
