import gql from 'graphql-tag';

export const USER_INFO = gql`
  fragment UserInfo on User {
    id
    email
    role
    name
    phoneNumber
    isVerified
    isDeleted
    createdAt
    deletedAt
  }
`;

export const TEACHER_INFO = gql`
  fragment TeacherInfo on Teacher {
    id
    photoUrl
    rank
    sex
    birthYear
    birthMonth
    birthDate
    universityType
    universityStatus
    universityName
    universityMajor
    answer
    certificate
    simpleMessage
    description
    introduction
    activeArea1
    activeArea2
    activeArea3
    tendency
    note
    times {
      id
      day
      startTime
      endTime
    }
  }
`;

export const QNA_INFO = gql`
  fragment QnaInfo on Qna {
    id
    order
    question
    answer
    isHidden
    type
  }
`;

export const REVIEW_INFO = gql`
  fragment ReviewInfo on Review {
    id
    order
    isHidden
    title
    contents
  }
`;

export const APPLICATION_INFO = gql`
  fragment ApplicationInfo on Application {
    id
    classType
    status
    parentsId
    babyIds
    babyInfo
    teacherId
    startDay
    startTime
    startAt
    address
    addressDetail
    zoneNo
    description
    report
  }
`;

export const PAYMENT_INFO = gql`
  fragment PaymentInfo on Payment {
    id
    merchantUid
    impUid
    status
    payMethod
    embPgProvider
    pgProvider
    paidAmount
    cancelAmount
    receiptUrl
    paidAt
  }
`;

export const CLASSLOG_INFO = gql`
  fragment ClasslogInfo on ClassLog {
    id
    photo1
    photo2
    photo3
    photo4
    photo5
    content
    classDate
    teacherId
  }
`;
