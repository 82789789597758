import { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import logo from '../images/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { removeToken } from '../utils/storage';
import { isLoggedInVar } from '../graphql/apollo';

export const Header = () => {
  const location = useLocation();

  const [pos, setPos] = useState('');

  useEffect(() => {
    setPos(location.pathname.split('/')[1]);
  }, [location]);

  return (
    <Disclosure as="nav" className="bg-white shadow z-40">
      <div className="mx-auto px-8">
        <div className="flex justify-between h-16">
          <div className="flex px-0">
            <div className="flex-shrink-0 flex items-center justify-center">
              <Link to="/">
                <img className="block h-8 w-auto" src={logo} alt="" />
              </Link>
            </div>
          </div>

          <div className="flex px-0">
            <div className="ml-6 flex space-x-8">
              <div
                className={`inline-flex items-center px-1 pt-1 border-b-2 cursor-pointer
              ${pos === 'users' ? 'border-indigo-500' : 'border-transparent'}`}
              >
                <Menu as="div" className="relative flex-shrink-0">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="text-sm font-medium text-gray-900">
                          사용자 관리
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg py-1 
                          bg-white ring-1 ring-black ring-opacity-5 "
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/users/parents">
                                <div
                                  className={`
                                        ${active ? 'bg-gray-100' : ''}
                                        block m-2 px-4 py-2 text-sm text-gray-700 text-center
                                      `}
                                >
                                  일반 사용자
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/users/teacher">
                                <div
                                  className={`
                                      ${active ? 'bg-gray-100' : ''}
                                      block m-2 px-4 py-2 text-sm text-gray-700 text-center
                                    `}
                                >
                                  선생님
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/users/admin">
                                <div
                                  className={`
                                    ${active ? 'bg-gray-100' : ''}
                                    block m-2 px-4 py-2 text-sm text-gray-700 text-center
                                  `}
                                >
                                  관리자
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>

              <div
                className={`inline-flex items-center px-1 pt-1 border-b-2 cursor-pointer
                ${
                  pos === 'classes' ? 'border-indigo-500' : 'border-transparent'
                }`}
              >
                <Menu as="div" className="relative flex-shrink-0">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="text-sm font-medium text-gray-900">
                          수업 관리
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg py-1 
                          bg-white ring-1 ring-black ring-opacity-5 "
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/classes/report">
                                <div
                                  className={`
                                  ${active ? 'bg-gray-100' : ''}
                                  block m-2 px-4 py-2 text-sm text-gray-700 text-center
                                `}
                                >
                                  발달보고서
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                          {/* <Menu.Item>
                            {({ active }) => (
                              <Link to="/classes/classlog">
                                <div
                                  className={`
                                ${active ? 'bg-gray-100' : ''}
                                block m-2 px-4 py-2 text-sm text-gray-700 text-center
                              `}
                                >
                                  수업일지
                                </div>
                              </Link>
                            )}
                          </Menu.Item> */}
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/classes/application">
                                <div
                                  className={`
                              ${active ? 'bg-gray-100' : ''}
                              block m-2 px-4 py-2 text-sm text-gray-700 text-center
                            `}
                                >
                                  수업 (신청서)
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/classes/delivery">
                                <div
                                  className={`
                              ${active ? 'bg-gray-100' : ''}
                              block m-2 px-4 py-2 text-sm text-gray-700 text-center
                            `}
                                >
                                  택배 관리
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>

              <div
                className={`inline-flex items-center px-1 pt-1 border-b-2 cursor-pointer
                ${
                  pos === 'payments'
                    ? 'border-indigo-500'
                    : 'border-transparent'
                }`}
              >
                <Menu as="div" className="relative flex-shrink-0">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="text-sm font-medium text-gray-900">
                          결제 관리
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg py-1 
                          bg-white ring-1 ring-black ring-opacity-5 "
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/payments">
                                <div
                                  className={`
                                  ${active ? 'bg-gray-100' : ''}
                                  block m-2 px-4 py-2 text-sm text-gray-700 text-center
                                `}
                                >
                                  결제 내역
                                </div>
                              </Link>
                            )}
                          </Menu.Item>

                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/payments/teacher">
                                <div
                                  className={`
                              ${active ? 'bg-gray-100' : ''}
                              block m-2 px-4 py-2 text-sm text-gray-700 text-center
                            `}
                                >
                                  선생님 활동
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>

              <div
                className={`inline-flex items-center px-1 pt-1 border-b-2 cursor-pointer
              ${pos === 'info' ? 'border-indigo-500' : 'border-transparent'}`}
              >
                <Menu as="div" className="relative flex-shrink-0">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="text-sm font-medium text-gray-900">
                          안내
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg py-1 
                          bg-white ring-1 ring-black ring-opacity-5 "
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/info/qna">
                                <div
                                  className={`
                                  ${active ? 'bg-gray-100' : ''}
                                  block m-2 px-4 py-2 text-sm text-gray-700 text-center
                                  `}
                                >
                                  이용안내
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/info/review">
                                <div
                                  className={`
                                          ${active ? 'bg-gray-100' : ''}
                                          block m-2 px-4 py-2 text-sm text-gray-700 text-center
                                        `}
                                >
                                  후기관리
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/info/terms">
                                <div
                                  className={`
                                ${active ? 'bg-gray-100' : ''}
                                block m-2 px-4 py-2 text-sm text-gray-700 text-center
                              `}
                                >
                                  이용약관
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/info/privacy">
                                <div
                                  className={`
                                ${active ? 'bg-gray-100' : ''}
                                block m-2 px-4 py-2 text-sm text-gray-700 text-center
                              `}
                                >
                                  개인정보
                                  <br />
                                  취급방침
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>

          <div className="ml-4 flex items-center">
            <button
              className="btn btn-red inline-flex text-sm font-medium shadow-sm"
              onClick={() => {
                removeToken();
                isLoggedInVar(false);
              }}
            >
              로그 아웃
            </button>
          </div>
        </div>
      </div>
    </Disclosure>
  );
};
