import gql from 'graphql-tag';
import { PAYMENT_INFO } from './fragments';

export const SEARCH_PAYMENTS = gql`
  query SearchPayments($input: SearchPaymentsInput!) {
    searchPayments(input: $input) {
      error
      message
      payments {
        ...PaymentInfo
      }
    }
  }
  ${PAYMENT_INFO}
`;

export const CANCEL_PAYMENT = gql`
  mutation CancelPayment($input: CancelPaymentInput!) {
    cancelPayment(input: $input) {
      error
      message
    }
  }
`;
