import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { IUserForm } from './user.interface';
import { EnumSex, UserRole } from '../../graphql/__generated__/globalTypes';
import { useForm } from 'react-hook-form';
import { SearchAddress } from '../../components/address';
import { FindUser_findUser_user } from '../../graphql/__generated__/FindUser';
import { useHistory } from 'react-router-dom';
import { USER_MUTATION } from '../../graphql/users';
import {
  UpdateUser,
  UpdateUserVariables,
} from '../../graphql/__generated__/UpdateUser';
import { useMutation } from '@apollo/client';
import { ToastType } from '../../components/toast';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

interface IUserBasic {
  user?: FindUser_findUser_user;
  setToast: React.Dispatch<
    React.SetStateAction<{
      type: ToastType;
      title: string;
      message: string;
      show: boolean;
    }>
  >;
}

interface IAnswer {
  type: string;
  question: string;
  answers: {
    [index: string]: { text: string; checked: boolean };
  };
}

interface IAddressInfo {
  address: string;
  zoneNo: string;
}

export const UserParents: React.FC<IUserBasic> = ({ user, setToast }) => {
  const history = useHistory();

  const [isIncluded, setIsIncluded] = useState(false);

  const [openAddr, setOpenAddr] = useState(false);
  const setAddressInfo = ({ address, zoneNo }: IAddressInfo) => {
    setValue('user.address', address, { shouldValidate: true });
    setValue('user.zoneNo', zoneNo, { shouldValidate: true });
  };

  const [updateUser, { loading: updating }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(USER_MUTATION, {
    onCompleted: ({ updateUser: { error, message } }: UpdateUser) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: '사용자 수정',
        message: error
          ? message || '사용자 수정에 실패했습니다.'
          : '사용자 수정에 성공했습니다.',
        show: true,
      });
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<IUserForm>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (user && user.email) {
      const userValues = {
        id: user.id,
        role: user.role,
        name: user.name,
        phoneNumber: user.phoneNumber,
        kakaoId: user.kakaoId,
        isVerified: user.isVerified,
        isDeleted: user.isDeleted,
        reportSubscription: user.reportSubscription,
        address: user.address,
        addressDetail: user.addressDetail,
        zoneNo: user.zoneNo,
      };
      setValue('user', { ...userValues }, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = () => {
    if (user && user.id) {
      updateUser({
        variables: { input: { ...getValues().user, id: user.id } },
      });
    }
  };

  return (
    <>
      <SearchAddress
        open={openAddr}
        setOpen={setOpenAddr}
        action={setAddressInfo}
      />
      <form
        className="space-y-5 divide-y divide-gray-200 flex flex-col "
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-5 divide-y divide-gray-200 flex flex-col px-6 ">
          <div className="flex flex-col">
            <div className="flex items-baseline">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                사용자 정보
              </h3>
              <p className="ml-4 text-sm text-gray-500">
                사용자 기본 정보 관리
              </p>
            </div>

            <div className="mt-5 space-y-5 flex flex-col">
              <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                <div className="flex items-center w-40 pl-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                </div>
                <div className="mt-0 flex flex-1">
                  <div
                    className="flex-1 block rounded-md text-sm shadow-sm w-full
                        border-gray-100 bg-gray-200 text-gray-900 px-3 py-2"
                  >
                    {user?.email}
                  </div>
                </div>
                <div className="flex items-center justify-end px-4 w-52">
                  <div className="text-sm text-gray-700 font-light">
                    {user?.createdAt
                      ? dayjs(+user?.createdAt).format('DD/MM/YYYY HH:mm:ss')
                      : '-'}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                <div className="flex items-center w-40 pl-4">
                  <label className="block text-sm font-medium text-gray-700">
                    이름
                  </label>
                </div>
                <div className="mt-0 flex flex-1">
                  <input
                    className={`flex-1 block rounded-md text-sm shadow-sm w-full
                        ${
                          Object.keys(errors).includes('user.name')
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                    {...register('user.name', { maxLength: 6 })}
                    type="text"
                    maxLength={6}
                    required
                  />
                </div>
                <div className="flex items-center justify-end px-4 w-52"></div>
              </div>

              <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                <div className="flex items-center w-40 pl-4">
                  <label className="block text-sm font-medium text-gray-700">
                    휴대폰 번호
                  </label>
                </div>
                <div className="mt-0 flex flex-1">
                  <input
                    className={`flex-1 block rounded-md text-sm shadow-sm w-full
                        ${
                          Object.keys(errors).includes('user.phoneNumber')
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                    {...register('user.phoneNumber', {
                      required: true,
                      pattern: /^[0-9]*$/,
                    })}
                    type="text"
                    required
                  />
                </div>
                <div className="flex items-center justify-end px-4 w-52">
                  <div className="text-sm font-medium text-gray-700">
                    <select
                      className="block w-full shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      {...register('user.isVerified', {
                        setValueAs: v => {
                          if (typeof v === 'boolean') {
                            return v;
                          } else {
                            return v === 'true';
                          }
                        },
                      })}
                    >
                      <option value="true">인증</option>
                      <option value="false">미인증</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                <div className="flex items-center w-40 pl-4">
                  <label className="block text-sm font-medium text-gray-700">
                    상태
                  </label>
                </div>
                <div className="mt-0 flex flex-1">
                  <div className="text-sm font-medium text-gray-700">
                    <select
                      className="block w-full shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      {...register('user.isDeleted', {
                        setValueAs: v => {
                          if (typeof v === 'boolean') {
                            return v;
                          } else {
                            return v === 'true';
                          }
                        },
                      })}
                    >
                      <option value="true">삭제됨</option>
                      <option value="false">활동중</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center justify-end px-4 w-52">
                  <div className="text-sm text-gray-700 font-light">
                    {user?.isDeleted
                      ? dayjs(+user?.deletedAt).format('DD/MM/YYYY HH:mm:ss')
                      : '-'}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                <div className="flex items-center w-40 pl-4">
                  <label className="block text-sm font-medium text-gray-700">
                    카카오톡 ID
                  </label>
                </div>
                <div className="mt-0 flex flex-1">
                  <input
                    className="flex-1 block rounded-md text-sm shadow-sm w-full
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                    {...register('user.kakaoId')}
                    type="text"
                  />
                </div>
                <div className="flex items-center justify-end px-4 w-52">
                  <div className="text-sm font-medium text-gray-700"></div>
                </div>
              </div>

              <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                <div className="flex items-center w-40 pl-4">
                  <label className="block text-sm font-medium text-gray-700">
                    주소
                  </label>
                </div>
                <div className="mt-0 flex flex-1">
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row w-full items-center">
                      <div className="mt-0 flex flex-1">
                        <div className="flex items-center h-5 w-full">
                          <input
                            className={`flex-1 block rounded-md text-sm shadow-sm w-full
                                      ${
                                        Object.keys(errors).includes(
                                          'user.address'
                                        )
                                          ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                          : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                                      }
                                      `}
                            {...register('user.address')}
                            type="text"
                            onClick={() => setOpenAddr(true)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-end pl-4 w-52">
                        <div className="text-sm font-medium text-gray-700">
                          <input
                            className={`flex-1 block rounded-md text-sm shadow-sm w-full
                                      ${
                                        Object.keys(errors).includes(
                                          'user.address'
                                        )
                                          ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                          : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                                      }
                                      `}
                            {...register('user.zoneNo')}
                            type="text"
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row w-full items-center mt-2">
                      <div className="flex flex-1">
                        <div className="flex items-center w-full">
                          <input
                            className={`flex-1 block rounded-md text-sm shadow-sm w-full
                                      ${
                                        Object.keys(errors).includes(
                                          'user.addressDetail'
                                        )
                                          ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                          : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                                      }
                                      `}
                            {...register('user.addressDetail')}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                <div className="flex items-center w-40 pl-4">
                  <label className="block text-sm font-medium text-gray-700">
                    발달보고서
                  </label>
                </div>
                <div className="mt-0 flex flex-1">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 
                      text-indigo-600 hover:ring-indigo-500"
                      {...register('user.reportSubscription')}
                    />
                    <div className="ml-4 text-sm">구독</div>
                  </div>
                </div>
                <div className="flex items-center justify-end px-4 w-52">
                  <div className="text-sm font-medium text-gray-700"></div>
                </div>
              </div>

              <div className="flex flex-row items-center border-t border-gray-200 pt-5">
                <div className="flex items-center w-40 pl-4">
                  <label className="block text-sm font-medium text-gray-700">
                    타입
                  </label>
                </div>
                <div className="text-sm font-medium text-gray-700">
                  <select
                    className="block w-full shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                    {...register('user.role')}
                  >
                    <option value={UserRole.Parents}>부모님</option>
                    <option value={UserRole.Teacher}>선생님</option>
                    <option value={UserRole.Admin}>관리자</option>
                  </select>
                </div>
              </div>

              <div className="flex flex-row items-start border-t border-gray-200 pt-5 h-full">
                <div className="flex items-center w-40 pl-4 mt-2">
                  <label className="block text-sm font-medium text-gray-700 ">
                    아이 목록
                  </label>
                </div>
                <div className="mt-0 flex flex-1">
                  <div className="flex flex-col w-full pr-4">
                    {user?.babies
                      .filter(baby => {
                        if (isIncluded) return baby;
                        else return !baby.isDeleted;
                      })
                      .map(baby => (
                        <>
                          <div
                            className="flex flex-row items-center justify-start py-2 text-sm"
                            key={baby.id}
                          >
                            <div className="flex flex-col items-center w-full">
                              <Disclosure>
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className="flex justify-between w-full px-4 py-2 
                                                  text-sm font-medium text-left rounded-lg hover:bg-purple-50 
                                                  focus:outline-none "
                                    >
                                      <div className="flex flex-row justify-start">
                                        <label className="mr-4">
                                          {baby.name}
                                        </label>
                                        <label className="mr-4">
                                          {baby.birthYear}년 {baby.birthMonth}월{' '}
                                          {baby.birthDate}일
                                        </label>
                                        <label
                                          className={`px-2 mr-2 text-xs flex items-center rounded-full ${
                                            baby.sex === EnumSex.Male
                                              ? 'bg-blue-400 text-blue-50'
                                              : 'bg-pink-400 text-pink-50'
                                          }`}
                                        >
                                          {baby.sex === EnumSex.Male
                                            ? '남아'
                                            : '여아'}
                                        </label>
                                        {baby.isDeleted && (
                                          <label
                                            className="px-2 mr-4 text-xs flex items-center 
                                                          rounded-full bg-gray-400 text-gray-50"
                                          >
                                            삭제
                                          </label>
                                        )}
                                      </div>
                                      {open ? (
                                        <ChevronUpIcon className="w-5 h-5 text-purple-500" />
                                      ) : (
                                        <ChevronDownIcon className="w-5 h-5 text-purple-500" />
                                      )}
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                      className="flex flex-col items-start w-full px-4 pt-1 pb-2 
                                                  text-sm text-gray-700"
                                    >
                                      <>
                                        {(() => {
                                          const answerList = JSON.parse(
                                            baby.answer || '[]'
                                          );
                                          return answerList.map(
                                            (item: IAnswer, key: number) => (
                                              <div
                                                className="flex flex-col py-1"
                                                key={key}
                                              >
                                                <span>{item.question}</span>
                                                <span>
                                                  {(() =>
                                                    Object.values(item.answers)
                                                      .filter(
                                                        (answer: {
                                                          checked: boolean;
                                                          text: string;
                                                        }) => {
                                                          return (
                                                            answer.checked ===
                                                            true
                                                          );
                                                        }
                                                      )
                                                      .map(item => {
                                                        return item.text;
                                                      })
                                                      .join(', '))()}
                                                </span>
                                              </div>
                                            )
                                          );
                                        })()}
                                        {(() => {
                                          const infosList = JSON.parse(
                                            baby.infos || '[]'
                                          );
                                          return infosList.map(
                                            (item: IAnswer, key: number) => (
                                              <div
                                                className="flex flex-col py-1"
                                                key={key}
                                              >
                                                <span>{item.question}</span>
                                                <span>
                                                  {(() =>
                                                    Object.values(item.answers)
                                                      .filter(
                                                        (answer: {
                                                          checked: boolean;
                                                          text: string;
                                                        }) => {
                                                          return (
                                                            answer.checked ===
                                                            true
                                                          );
                                                        }
                                                      )
                                                      .map(item => {
                                                        return item.text;
                                                      })
                                                      .join(', '))()}
                                                </span>
                                              </div>
                                            )
                                          );
                                        })()}
                                      </>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                <div className="flex items-start items-top px-4 w-32 mt-2">
                  <div className="text-sm font-medium text-gray-700">
                    <button
                      type="button"
                      className="btn btn-white"
                      onClick={() => setIsIncluded(prev => !prev)}
                    >
                      {isIncluded ? '삭제 제외' : '삭제 포함'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex border-t mt-5 pt-5 px-6 justify-end">
          <button
            type="button"
            className="btn btn-white shadow-sm text-sm font-medium"
            onClick={() => history.goBack()}
          >
            취소
          </button>
          <button
            type="submit"
            disabled={!user?.id || !isValid || updating}
            className="btn btn-purple ml-3 shadow-sm text-sm font-medium"
          >
            저장
          </button>
        </div>
      </form>
    </>
  );
};
