import {gql} from '@apollo/client';
import {TEACHER_INFO} from './fragments';

export const GET_TEACHERS = gql`
    query GetTeachers($input: GetTeachersInput!) {
        getTeachers(input: $input) {
            error
            message
            total
            hasNext
            like {
                teacherId
            }
            users {
                id
                email
                name
                phoneNumber
                kakaoId
                teacher {
                    ...TeacherInfo
                }
            }
            classlogs {
                id
                teacherId
            }
        }
    }
    ${TEACHER_INFO}
`;

export const FIND_TEACHER = gql`
    query FindTeacher($input: FindTeacherInput!) {
        findTeacher(input: $input) {
            error
            message
            teacher {
                ...TeacherInfo
            }
        }
    }
    ${TEACHER_INFO}
`;

export const UPDATE_TEACHER = gql`
    mutation UpdateTeacher($input: UpdateTeacherInput!) {
        updateTeacher(input: $input) {
            error
            message
            teacher {
                ...TeacherInfo
            }
        }
    }
    ${TEACHER_INFO}
`;
