import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast, ToastType } from '../../components/toast';
import {
  SearchIcon,
  PencilIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import { SpinnerSvg } from '../../components/svg/spinner';
import { phoneNumberFormat } from '../../utils/phone-format';
import { useQuery } from '@apollo/client';
import { SEARCH_REPORT } from '../../graphql/report';
import {
  SearchReports,
  SearchReportsVariables,
  SearchReports_searchReports_reports,
} from '../../graphql/__generated__/SearchReports';
import { MonthPicker } from '../../components/date-picker/month-picker';
import { YearPicker } from '../../components/date-picker/year-picker';
import { useScroll } from '../../hooks/useScroll';

type searchType = 'email' | 'reportDate';
interface IForm {
  type: searchType;
  email: string;
  reportYear: number;
  reportMonth: number;
}
interface IWhere {
  page: number;
  email?: string;
  reportYear?: number;
  reportMonth?: number;
}

const TITLE = '발달보고서';
export const Report: React.FC<{}> = () => {
  const history = useHistory();

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const [reports, setReports] = useState<SearchReports_searchReports_reports[]>(
    []
  );
  const [where, setWhere] = useState<IWhere>({ page: 1 });
  const [next, setNext] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const { loading } = useQuery<SearchReports, SearchReportsVariables>(
    SEARCH_REPORT,
    {
      fetchPolicy: 'no-cache',
      variables: { input: { ...where } },
      onCompleted: ({
        searchReports: { error, message, reports: list, hasNext, total },
      }: SearchReports) => {
        setNext(Boolean(hasNext));
        setTotalCount(total || 0);

        switch (true) {
          case where.page === 1:
            setToast({
              type: error ? ToastType.Fail : ToastType.Success,
              title: TITLE,
              message: error
                ? message || `${TITLE} 조회에 실패했습니다.`
                : `${TITLE} 조회에 성공했습니다.`,
              show: true,
            });
            setReports(list);
            break;
          default:
            setReports(prev => [...prev, ...list]);
            break;
        }
      },
    }
  );

  const initSearchForm: IForm = {
    type: 'email',
    email: '',
    reportYear: new Date().getFullYear(),
    reportMonth: new Date().getMonth() + 1,
  };
  const [searchForm, setSearchForm] = useState<IForm>(initSearchForm);

  const { isScroll, remainToBottom } = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !loading && next && isScroll) {
      setWhere(prev => {
        return { ...prev, page: prev.page + 1 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainToBottom]);

  const onSearch = () => {
    const where: IWhere = { page: 1 };
    const { type, email, reportYear, reportMonth } = searchForm;
    if (type === 'email') {
      where[type] = email;
    } else {
      where['reportYear'] = reportYear;
      where['reportMonth'] = reportMonth;
    }
    setWhere(where);
  };

  return (
    <>
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <div className="layer items-center">
        <div className="layer-title flex-row justify-between w-1024px">
          <div className="flex">
            <span className="layer-title-text">발달보고서</span>
            <span className="text-sm">({totalCount})</span>
          </div>
          <div className="flex flex-row justify-center">
            <form className="flex flex-row ">
              <div className="flex flex-row col-span-1">
                <select
                  className="block shadow-sm text-sm rounded-md
                          border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={e => {
                    const type = e.target.value as searchType;
                    setSearchForm({ ...initSearchForm, type });
                  }}
                >
                  <option value="email">Email</option>
                  <option value="reportDate">날짜</option>
                </select>
              </div>
              <div className="flex ml-1 w-72">
                {searchForm.type === 'email' ? (
                  <input
                    className="block rounded-md text-sm shadow-sm w-full
                            border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                    type="text"
                    onChange={e => {
                      const email = e.target.value;
                      setSearchForm(prev => ({ ...prev, email }));
                    }}
                  />
                ) : (
                  <div className="flex flex-row -mt-1 w-full">
                    <YearPicker
                      value={searchForm.reportYear}
                      onChangeValue={(value: number) => {
                        setSearchForm(prev => ({ ...prev, reportYear: value }));
                      }}
                    />
                    <MonthPicker
                      value={searchForm.reportMonth}
                      onChangeValue={(value: number) => {
                        setSearchForm(prev => ({
                          ...prev,
                          reportMonth: value,
                        }));
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="flex">
                <button
                  className="btn btn-blue ml-2"
                  type="button"
                  onClick={onSearch}
                >
                  <SearchIcon className="h-4 w-4 text-gray-100" />
                </button>
              </div>
            </form>
          </div>
          {/* <div className="flex">
            <button
              className="btn btn-purple"
              onClick={() => history.push('/classes/report/create')}
            >
              <PlusIcon className="w-4 h-4" />
            </button>
          </div> */}
        </div>

        {/* Contents */}
        <div className="pb-4 w-1024px">
          <div className="py-2 align-middle w-full">
            <div className="shadow border-b border-gray-200 rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 
                                uppercase tracking-wider"
                    >
                      Email(이름)
                    </th>

                    <th
                      scope="col"
                      className="relative px-6 py-3 text-left text-xs font-medium text-gray-500 
                                uppercase tracking-wider"
                    >
                      <span className="sr-only">Action</span>
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 
                                uppercase tracking-wider"
                    >
                      휴대폰번호
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 
                                uppercase tracking-wider"
                    >
                      날짜
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {reports.map(report => (
                    <tr key={report.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-4 flex flex-row items-center">
                            <div className="flex text-sm font-medium text-gray-900">
                              {report.user.email}
                            </div>
                            <div className="flex text-sm items-center font-light ml-1">
                              <label className="rounded-full bg-blue-100 text-blue-800 py-px px-3 text-sm ml-1">
                                {report.user.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="flex flex-row py-4 text-right text-sm font-medium">
                        <button
                          className="btn btn-gray flex-row p-2 font-medium "
                          onClick={() => window.open(`${report.url}`, '_blank')}
                        >
                          <DocumentTextIcon className="h-5 w-5" />
                        </button>
                        <button
                          className="btn btn-blue flex-row p-2 font-medium ml-2"
                          onClick={() =>
                            history.push(`/classes/report/${report.id}`)
                          }
                        >
                          <PencilIcon className="h-5 w-5" />
                        </button>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {phoneNumberFormat(report.user.phoneNumber)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 tracking-tight">
                          {report.reportYear} - {report.reportMonth}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {loading && next && (
                <div className="flex items-center justify-center py-6 px-10 border-t border-gray-100">
                  <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-500" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
