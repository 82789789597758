import axios from 'axios';

interface ISearchUsingKakao {
  query: string;
  page?: number;
}

export const searchUsingKakao = async ({
  query,
  page = 1,
}: ISearchUsingKakao) => {
  const {
    data: {
      documents,
      meta: { is_end, total_count },
    },
  } = await axios.get('https://dapi.kakao.com/v2/local/search/address.json', {
    params: {
      query,
      size: 4,
      page,
    },
    headers: {
      Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_KEY}`,
    },
  });

  const totalPage = Math.ceil(total_count / 4);

  return { list: documents, isEnd: is_end, totalCount: total_count, totalPage };
};
