import { gql } from '@apollo/client';

export const UPSERT_TIME = gql`
  mutation UpsertTime(
    $input: [UpsertTimeInput!]!
    $userId: UpsertTimeTargetInput!
  ) {
    upsertTime(input: $input, userId: $userId) {
      error
      message
    }
  }
`;

export const GET_TIMES = gql`
  query GetTimes($input: GetTimesInput!) {
    getTimes(input: $input) {
      error
      message
      times {
        id
        day
        startTime
        endTime
      }
    }
  }
`;
