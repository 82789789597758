import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { USER_LIST_QUERY } from '../../graphql/users';
import {
  SearchUsers,
  SearchUsersVariables,
} from '../../graphql/__generated__/SearchUsers';
import { useScroll } from '../../hooks/useScroll';
import { SearchIcon } from '@heroicons/react/outline';
import { phoneNumberFormat } from '../../utils/phone-format';
import { UserRole } from '../../graphql/__generated__/globalTypes';

interface IWhere {
  page: number;
  role: UserRole;
  email?: string;
  name?: string;
  phoneNumber?: string;
  isDeleted: boolean;
}

type searchType = 'email' | 'name' | 'phoneNumber';
interface IForm {
  type: searchType;
  search?: string;
}

interface IUser {
  id: number;
  email: string;
  name: string;
  phoneNumber: string;
  kakaoId: string | null;
}

interface ISearchParentsComponent {
  handleClickUser: Function;
  handleError: Function;
  searchRole?: UserRole;
}

export const SearchParentsComponent: React.FC<ISearchParentsComponent> = ({
  handleClickUser,
  handleError,
  searchRole,
}: ISearchParentsComponent) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [where, setWhere] = useState<IWhere>({
    page: 1,
    isDeleted: false,
    role: searchRole ? searchRole : UserRole.Parents,
  });
  const [next, setNext] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const { loading: listing } = useQuery<SearchUsers, SearchUsersVariables>(
    USER_LIST_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { input: { ...where } },
      onCompleted: ({
        searchUsers: { error, message, hasNext, total, users: searchedUsers },
      }: SearchUsers) => {
        setNext(Boolean(hasNext));
        setTotalCount(total || 0);

        if (error) {
          handleError(message);
        }

        switch (!error) {
          case where.page === 1:
            setUsers(searchedUsers);
            break;
          default:
            setUsers(prev => [...prev, ...searchedUsers]);
            break;
        }
      },
    }
  );

  const { isScroll, remainToBottom } = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !listing && next && isScroll) {
      setWhere(prev => {
        return { ...prev, page: prev.page + 1 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainToBottom]);

  const { register, getValues, handleSubmit } = useForm<IForm>();
  const onSearch = () => {
    const { type, search } = getValues();
    const where: IWhere = { page: 1, isDeleted: false, role: UserRole.Parents };
    if (search) where[type] = search;
    setWhere(where);
  };

  return (
    <>
      <div className="flex flex-col justify-between w-full border-b border-gray-300 pb-2">
        <div className="mb-2">
          <span className="text-xl font-semibold">사용자 목록</span>
          <span className="text-sm font-light"> ({totalCount})</span>
        </div>
        <div className="flex w-full">
          <form
            className="flex flex-row w-full"
            onSubmit={handleSubmit(onSearch)}
          >
            <div className="flex ">
              <select
                className="block w-full shadow-sm text-sm rounded-md
              border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                {...register('type')}
              >
                <option value="email">Email</option>
                <option value="name">이름</option>
                <option value="phoneNumber">휴대폰번호</option>
                <option value="kakaoId">카카오톡 ID</option>
              </select>
            </div>
            <div className="flex ml-1 flex-1 w-full">
              <input
                className="flex-1 block rounded-md text-sm shadow-sm 
                    border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                type="text"
                {...register('search')}
              />
            </div>
            <div className="flex">
              <button className="btn btn-blue ml-2">
                <SearchIcon className="h-4 w-4 text-gray-100" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col">
        {users.map((user, idx) => (
          <div
            key={idx}
            className="border my-2 mx-2 cursor-pointer rounded-md p-2
                            hover:ring-2 hover:ring-purple-500 hover:ring-offset-2"
            onClick={() => handleClickUser(user)}
          >
            <div className="w-full flex flex-row justify-start items-center">
              {user.email}{' '}
              <span className="rounded-full bg-blue-100 text-blue-800 py-px px-3 text-sm ml-1">
                {user.name}
              </span>
            </div>
            <div className="w-full flex flex-row justify-start items-center mt-1">
              {phoneNumberFormat(user.phoneNumber)}{' '}
              {user.kakaoId && (
                <span className="rounded-full bg-green-100 text-green-800 py-px px-3 text-sm ml-1">
                  {user.kakaoId}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
