import { gql } from '@apollo/client';

export const EXPORT_PARENTS = gql`
  query ExportParents {
    exportParents {
      error
      message
      users {
        name
        email
        phoneNumber
        kakaoId
        address
        addressDetail
        zoneNo
        babies {
          name
          birthYear
          birthMonth
          birthDate
          sex
          isDeleted
        }
      }
    }
  }
`;

export const EXPORT_TEACHER = gql`
  query ExportTeacher {
    exportTeacher {
      error
      message
      users {
        name
        email
        phoneNumber
        kakaoId
        address
        addressDetail
        zoneNo
        teacher {
          photoUrl
          rank
          sex
          birthYear
          birthMonth
          birthDate
          universityType
          universityStatus
          universityName
          universityMajor
          answer
          certificate
          simpleMessage
          description
          introduction
          activeArea1
          activeArea2
          activeArea3
          tendency
          note
          createdAt
          times {
            day
            startTime
            endTime
          }
        }
      }
    }
  }
`;
