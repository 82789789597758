import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Toast, ToastType } from '../../components/toast';
import { phoneNumberFormat } from '../../utils/phone-format';
import { MonthPicker } from '../../components/date-picker/month-picker';
import { YearPicker } from '../../components/date-picker/year-picker';
import { CloudUploadIcon } from '@heroicons/react/outline';
import { uploadReportFile } from '../../utils/upload-file';
import {
  DELETE_REPORT,
  SEARCH_REPORT,
  UPDATE_REPORT,
} from '../../graphql/report';
import { useHistory, useParams } from 'react-router-dom';
import {
  SearchReports,
  SearchReportsVariables,
  SearchReports_searchReports_reports,
} from '../../graphql/__generated__/SearchReports';
import { Modal } from '../../components/modal';
import {
  UpdateReport,
  UpdateReportVariables,
} from '../../graphql/__generated__/UpdateReport';
import {
  DeleteReport,
  DeleteReportVariables,
} from '../../graphql/__generated__/DeleteReport';

interface IReportDetailParams {
  id: string;
}

interface IUser {
  id: number;
  email: string;
  name: string;
  phoneNumber: string;
  kakaoId: string | null;
}
interface IBaby {
  name: string;
  birthYear: number;
}

const TITLE = '발달보고서';
export const ReportDetail: React.FC<{}> = () => {
  const history = useHistory();
  const { id } = useParams<IReportDetailParams>();

  const [toast, setToast] = useState({
    type: ToastType.Fail,
    title: TITLE,
    message: '',
    show: false,
  });

  const [targetUser, setTargetUser] = useState<IUser>({
    id: 0,
    name: '',
    email: '',
    phoneNumber: '',
    kakaoId: '',
  });
  const [targetBaby, setTargetBaby] = useState<IBaby>({
    name: '',
    birthYear: 0,
  });

  const initReport = {
    __typename: 'Report',
    id: 0,
    url: '',
    reportYear: new Date().getFullYear(),
    reportMonth: new Date().getMonth() + 1,
    user: {
      __typename: 'User',
      id: 0,
      email: '',
      name: '',
      phoneNumber: '',
      kakaoId: '',
    },
  } as SearchReports_searchReports_reports;
  const [report, setReport] =
    useState<SearchReports_searchReports_reports>(initReport);
  const [origin, setOrigin] =
    useState<SearchReports_searchReports_reports>(initReport);
  const [isDirty, setIsDirty] = useState(false);

  const [errors, setErrors] = useState({
    userId: false,
    url: false,
  });

  useEffect(() => {
    setIsDirty(
      origin.user.id !== targetUser.id ||
        origin.url !== report.url ||
        origin.reportYear !== report.reportYear ||
        origin.reportMonth !== report.reportMonth
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetUser, report]);

  useEffect(() => {
    if (report) {
      if (report.url.length < 1) {
        setErrors(prev => ({ ...prev, url: true }));
      } else {
        setErrors(prev => ({ ...prev, url: false }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const { loading } = useQuery<SearchReports, SearchReportsVariables>(
    SEARCH_REPORT,
    {
      fetchPolicy: 'no-cache',
      variables: { input: { id: +id } },
      onCompleted: ({
        searchReports: { error, message, reports, total },
      }: SearchReports) => {
        const failed = error || total !== 1;
        setToast({
          type: failed ? ToastType.Fail : ToastType.Success,
          title: TITLE,
          message: failed
            ? message || `${TITLE} 조회에 실패했습니다.`
            : `${TITLE} 조회에 성공했습니다.`,
          show: true,
        });

        if (failed) {
          setTimeout(() => {
            history.push('/classes/report');
          }, 500);
        } else {
          const report = reports[0];
          const { id, name, email, phoneNumber, kakaoId } = report.user;
          setTargetUser({
            id,
            name,
            email,
            phoneNumber,
            kakaoId,
          });
          setOrigin(report);
          setReport(report);

          const baby = JSON.parse(report.babyInfo || '{}');
          setTargetBaby(baby);
        }
      },
    }
  );

  const [updateReport, { loading: updating }] = useMutation<
    UpdateReport,
    UpdateReportVariables
  >(UPDATE_REPORT, {
    onCompleted: ({ updateReport: { error, message } }: UpdateReport) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 수정에 실패했습니다.`
          : `${TITLE} 수정에 성공했습니다.`,
        show: true,
      });

      if (!error) {
        setTimeout(() => {
          history.push('/classes/report');
        }, 700);
      }
    },
  });

  const [deleteReport, { loading: deleting }] = useMutation<
    DeleteReport,
    DeleteReportVariables
  >(DELETE_REPORT, {
    onCompleted: ({ deleteReport: { error, message } }: DeleteReport) => {
      setToast({
        type: error ? ToastType.Fail : ToastType.Success,
        title: TITLE,
        message: error
          ? message || `${TITLE} 삭제에 실패했습니다.`
          : `${TITLE} 삭제에 성공했습니다.`,
        show: true,
      });

      if (!error) {
        setTimeout(() => {
          history.push('/classes/report');
        }, 700);
      }
    },
  });

  const onSubmit = () => {
    if (
      isDirty &&
      !Object.values(errors).includes(true) &&
      !loading &&
      !updating &&
      !deleting
    ) {
      const { id, url, reportYear, reportMonth } = report;
      updateReport({
        variables: {
          input: { id, url, reportYear, reportMonth, userId: targetUser.id },
        },
      });
    }
  };

  const uploadRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Toast {...toast} hide={() => setToast({ ...toast, show: false })} />

      <Modal
        open={open}
        setOpen={setOpen}
        title="Delete Report"
        content="Do you want to delete the Report?"
        buttonTitle="Delete"
        buttonAction={() => {
          if (!deleting) {
            deleteReport({ variables: { input: { id: +id } } });
          }
        }}
      />

      <div className="layer">
        <div className="layer-title flex-row justify-between">
          <span className="layer-title-text">Report</span>
        </div>

        <div className="py-4 border-t border-gray-300 flex flex-row justify-center divide-x">
          <div className="p-4 flex flex-col flex-1 w-full max-w-3xl">
            <div className="w-full border-b border-gray-300 pb-2">
              <span className="text-xl font-semibold">발달보고서</span>
            </div>
            <div className="flex flex-col py-3 divide-y gap-3">
              {/* user info */}
              <div className="flex flex-col">
                <div className="flex py-1">
                  <span className="text-lg font-semibold">사용자 정보</span>
                </div>
                <div className="flex py-1 justify-between">
                  <div className="flex flex-row justify-start items-center">
                    {targetUser.email}
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    {targetUser.name}
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    {phoneNumberFormat(targetUser.phoneNumber)}
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    {targetUser.kakaoId}
                  </div>
                </div>
              </div>

              {/* baby */}
              <div className="flex flex-col">
                <div className="flex py-1">
                  <span className="text-lg font-semibold">아이 정보</span>
                </div>
                <div className="flex py-1">
                  <div className="flex flex-row justify-start items-center mr-10">
                    {targetBaby.name}
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    {(() => {
                      const nowYear = new Date().getFullYear();
                      return nowYear - targetBaby.birthYear + 1;
                    })()}{' '}
                    세
                  </div>
                </div>
              </div>

              {/* report info */}
              <div className="flex flex-col w-full">
                <div className="flex py-1">
                  <span className="text-lg font-semibold">발달보고서 정보</span>
                </div>

                <div className="grid grid-cols-5 items-center mb-2 w-full">
                  <div className="flex items-center pl-4 col-span-2">
                    <label className="block text-sm font-medium text-gray-700">
                      PDF 업로드 URL
                    </label>
                  </div>
                  <div className="mt-0 flex flex-1 col-span-3 items-center">
                    <input
                      className={`flex-1 block rounded-md text-sm shadow-sm w-full resize-none
                        border px-2 py-2
                        ${
                          errors.url
                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                        }
                        `}
                      type="text"
                      readOnly={true}
                      value={report.url}
                    />
                    <div className="flex ml-2">
                      <input
                        ref={uploadRef}
                        type="file"
                        className="hidden"
                        onChange={async ({
                          target: { files },
                        }: ChangeEvent<HTMLInputElement>) => {
                          const file = files?.item(0);
                          if (file) {
                            const { url } = await uploadReportFile({
                              file,
                              userId: targetUser.id,
                            });
                            setReport(prev => ({ ...prev, url }));
                          }
                        }}
                      />
                      <button
                        disabled={!Boolean(targetUser.id)}
                        className="rounded-md btn btn-blue"
                        onClick={() => uploadRef.current?.click()}
                      >
                        <CloudUploadIcon className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-5 items-center mb-2 w-full">
                  <div className="flex items-center pl-4 col-span-2">
                    <label className="block text-sm font-medium text-gray-700">
                      발달보고서 연도
                    </label>
                  </div>
                  <div className="relative mt-0 flex flex-col flex-1 col-span-3 z-10">
                    <YearPicker
                      value={report.reportYear}
                      onChangeValue={(value: number) => {
                        setReport(prev => ({ ...prev, reportYear: value }));
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-5 items-center mb-2 w-full">
                  <div className="flex items-center pl-4 col-span-2">
                    <label className="block text-sm font-medium text-gray-700">
                      달발보고서 월
                    </label>
                  </div>
                  <div className="relative mt-0 flex flex-col flex-1 col-span-3">
                    <MonthPicker
                      value={report.reportMonth}
                      onChangeValue={(value: number) => {
                        setReport(prev => ({ ...prev, reportMonth: value }));
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* buttons */}
              <div className="flex mt-5 pt-5 justify-between">
                <div>
                  {id && (
                    <button
                      type="button"
                      disabled={loading || updating || deleting}
                      className="py-2 px-4 border rounded-md shadow-sm text-sm font-medium
                                bg-red-500 hover:bg-red-700 text-red-50
                                hover:ring-2 hover:ring-offset-2 hover:ring-red-500
                                disabled:ring-0 disabled:bg-red-300 disabled:cursor-default"
                      onClick={() => setOpen(true)}
                    >
                      삭제
                    </button>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    disabled={loading || updating || deleting}
                    className="py-2 px-4 border rounded-md shadow-sm text-sm font-medium
                            bg-white border-gray-300 text-gray-700
                            hover:bg-gray-300 hover:ring-2 hover:ring-offset-2 hover:ring-gray-300"
                    onClick={() => history.push('/classes/report')}
                  >
                    취소
                  </button>
                  <button
                    type="button"
                    disabled={
                      loading ||
                      updating ||
                      deleting ||
                      Object.values(errors).includes(true) ||
                      targetUser.id === 0
                    }
                    className="ml-3 py-2 px-4 rounded-md shadow-sm text-sm font-medium 
                              bg-purple-500 hover:bg-purple-700 text-purple-50
                              hover:ring-2 hover:ring-offset-2 hover:ring-purple-500
                              disabled:ring-0 disabled:bg-purple-300 disabled:cursor-default"
                    onClick={onSubmit}
                  >
                    저장
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
